import { Button, Collapse, CollapseProps, Radio, RadioChangeEvent, Slider, Space, Tooltip } from 'antd';
import { fabric } from 'fabric';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DragOutlined, HighlightOutlined, InfoCircleOutlined
} from '@ant-design/icons';

import reset from '../../../assets/icons/reset.svg'

import { useDebouncedCallback } from '../../../redux/hooks/useDebouncedCallback';
import {
    getImageSize, scaleImg
} from '../../../utils/function';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Sider from 'antd/es/layout/Sider';
import { toolEditorImage } from '../../../redux/app/toolEditor';
import { TypeEditor } from '../../../utils/enum';
import { useAppSelector } from '../../../redux/hooks/useAppSelector';
import NotifyController from '../../../utils/toast';
import Heading from '../DetailsTools/Heading';

type ColorProps = {
    typeInput: TypeEditor,
    onConfirmEditor: Function
    // isOpen: boolean;
    // setIsOpen: (o: boolean) => void;
    url: string,
    onClickSave: () => void;
    onClickBackToHome: () => void;
    // listGenerating: any,
    // setListGenerating: any,
    // imageId: string,
    // setIsGenerating: Function,
    // setPreviewModal: Function
}


function Color({ url, typeInput, onConfirmEditor, onClickSave, onClickBackToHome }: ColorProps) {
    let imageCropData = useAppSelector((store) => store.EditorImage)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [ready, setReady] = useState(false);
    const [ratio, setRatio] = useState<number>(1)
    const [brightness, setBrightness] = useState<number>(0)
    const [contrast, setContrast] = useState<number>(0)
    const [saturation, setSaturation] = useState<number>(0)
    const [hue, setHue] = useState<number>(0)
    const [blend, setBlend] = useState<number>(0)
    const [sharpen, setSharpen] = useState<number>(0)
    const [warm, setWarm] = useState<number>(0)
    const [opacity, setOpacity] = useState<number>(1)
    const [RGB, setRGB] = useState<any>({
        red: 1,
        green: 1,
        blue: 1
    })
    const [valueZoom, setValueZoom] = useState<any>(100)
    const [isDragging, setDragging] = useState(false);
    const [multiplier, setMultiplier] = useState(1);
    const [modeMove, setModeMove] = useState('brush');
    const [canvasSizeData, setCanvasSizeData] = useState<
        | {
            scaleX: number;
            scaleY: number;
            widthCanvas: number;
            heightCanvas: number;
        }
        | undefined
    >();

    const dispatch = useDispatch()

    const onCancel = () => {
        // setIsOpen(false)
    }

    let isRedoing = false;
    let h: any = [];

    const handleUndo = () => {
        if (fabricRef.current && fabricRef.current._objects.length > 0) {
            h.push(fabricRef.current && fabricRef.current._objects.pop());
            fabricRef.current && fabricRef.current.renderAll();
        }
    }

    const handleRedo = () => {
        if (h.length > 0) {
            isRedoing = true;
            fabricRef.current && fabricRef.current.add(h.pop());
        }
    }

    const handleReset = () => {
        isRedoing = true
        h = [];
        if (fabricRef.current) {
            fabricRef.current._objects = []
            fabricRef.current && fabricRef.current.renderAll();
            setValueZoom(100)
            fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        }
    }

    const setBg = async (canvasSizeData: any, contrast: number, brightness: number, saturation: number, RGB: any, hue: number, blend: number, opacity: number, sharpen: number, warm: number) => {
        return new Promise<void>((resolve) => {
            const i = fabric.Image.fromURL(
                url || "",
                function (img: any) {
                    fabricRef.current &&
                        fabricRef.current.setBackgroundImage(
                            img,
                            fabricRef.current.renderAll.bind(fabricRef.current),
                            {
                                scaleX: canvasSizeData.scaleX,
                                scaleY: canvasSizeData.scaleY,
                                opacity: opacity
                            }
                        );
                    // img.set({
                    //     opacity
                    // })
                    ratio > 0 &&
                        fabricRef &&
                        fabricRef.current?.setHeight(
                            canvasSizeData.widthCanvas / ratio || 300
                        );
                    fabricRef &&
                        fabricRef.current?.setWidth(canvasSizeData.widthCanvas || 400);

                    const filterContrast = new fabric.Image.filters.Contrast({
                        contrast: contrast,
                    });
                    const filterBrightness = new fabric.Image.filters.Brightness({
                        brightness: brightness,
                    });
                    const filterSaturation = new fabric.Image.filters.Saturation({
                        saturation: saturation,
                    });
                    const filterHue = new fabric.Image.filters.HueRotation({
                        rotation: hue,
                    });
                    const filterBlend = new fabric.Image.filters.BlendColor({
                        color: 'white',
                        mode: 'add',
                        alpha: blend
                    });
                    // applyFilter(12, this.checked && new f.Convolute({
                    //     matrix: [  0, -1,  0,
                    //               -1,  5, -1,
                    //                0, -1,  0 ]
                    //   }));
                    const filterSharpen = new fabric.Image.filters.Convolute({
                        matrix: [0, -sharpen, 0,
                            -sharpen, (1 + 4 * sharpen), -sharpen,
                            0, -sharpen, 0]
                    });
                    const filterWarm = new fabric.Image.filters.BlendColor({
                        color: warm > 0 ? '#ff0000' : '#0000FF', // Màu đỏ (có thể điều chỉnh)
                        mode: 'add', // Chế độ blend, có thể thay đổi để thử nghiệm
                        alpha: Math.abs(warm)  // Độ mờ của hiệu ứng, giá trị nằm trong khoảng từ 0 đến 1
                    });
                    const filter3 = new (fabric.Image.filters as any).Gamma({
                        gamma: [RGB.red, RGB.green, RGB.blue],
                    }) as any;
                    // const filterHighlight = new (fabric.Image.filters as any).Gamma({
                    //     gamma: [255 / highlights, 255 / highlights, 255 / highlights],
                    // }) as any;

                    if (img) {
                        img.filters && img.filters.push(filterContrast, filterBrightness, filterSaturation, filter3, filterHue, filterBlend, filterSharpen, filterWarm);
                        img.applyFilters()
                    }
                    resolve();

                },
                { crossOrigin: "*" }
            );

        })
    }

    const canvas_ZoomPlus = (scaleZoom: number) => {
        if (fabricRef.current) {
            const zoomCenter = new fabric.Point(200.0, 200.0);
            fabricRef.current.zoomToPoint(zoomCenter, scaleZoom);
        }
    };

    function disablePanning() {
        if (fabricRef.current) {
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.off('mouse:move');
        }
    }


    const initialSetStateBG = useCallback(async () => {
        const { width, height } = await getImageSize(url);
        width && height && setRatio(width / height);
        const imgScale = scaleImg(width && width, height && height);
        if (imgScale) {

            setCanvasSizeData({
                widthCanvas: imgScale.w,
                heightCanvas: imgScale.h,
                scaleX: imgScale.sX,
                scaleY: imgScale.sY,
            });
            setMultiplier(imgScale && imgScale.multiplier);
        }
    }, [url]);

    const handleResize = useCallback(async () => {
        const { width, height } = await getImageSize(url);
        width && height && setRatio(width / height);
        const imgScale = scaleImg(width && width, height && height);
        if (imgScale) {
            setCanvasSizeData({
                widthCanvas: imgScale.w,
                heightCanvas: imgScale.h,
                scaleX: imgScale.sX,
                scaleY: imgScale.sY,
            });
            setMultiplier(imgScale && imgScale.multiplier);
        }

    }, [url])

    const initFabric = useCallback(() => {
        if (canvasRef.current) {
            fabricRef.current = new fabric.Canvas(canvasRef.current);
        }
    }, [canvasRef, fabricRef, canvasSizeData]);
    useEffect(() => {
        (async () => {

            if (canvasSizeData) {
                await setBg(canvasSizeData, contrast, brightness, saturation, RGB, hue, blend, opacity, sharpen, warm);
            }
        })();

    }, [canvasSizeData, contrast, brightness, saturation, RGB, hue, blend, opacity, sharpen, warm])
    useEffect(() => {
        if (ready) {
            (async () => {
                if (!fabricRef.current) {
                    initFabric();
                }

            })();
        }
    }, [ready, initFabric, canvasSizeData]);

    const resizeHandler = useDebouncedCallback(async () => {
        handleReset();
        await handleResize();
    }, 50);
    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: t('Gamma'),

            children:
                <>
                    <div>
                        {t('red')}:
                        <Slider tooltipVisible={false} min={0.2} max={2.2} value={RGB.red} step={0.003921} onChange={(value) => {
                            setRGB((prev: any) => {
                                return { ...prev, red: value };
                            });
                        }} />
                    </div>
                    <div>
                        {t('green')}:
                        <Slider tooltipVisible={false} min={0.2} max={2.2} value={RGB.green} step={0.003921} onChange={(value) => {
                            setRGB((prev: any) => {
                                return { ...prev, green: value };
                            });
                        }} />
                    </div>
                    <div>
                        {t('blue')}:
                        <Slider tooltipVisible={false} min={0.2} max={2.2} value={RGB.blue} step={0.003921} onChange={(value) => {

                            setRGB((prev: any) => {
                                return { ...prev, blue: value };
                            });
                        }} />
                    </div>

                </>,
        }
    ];


    useEffect(() => {
        if (!ready) {
            (async () => {
                await initialSetStateBG();
                await handleResize();
                setReady(true);
            })();
        }
    }, [ready, initialSetStateBG, handleResize]);

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [resizeHandler]);


    const onChangeZoomPlus = (e: number) => {
        canvas_ZoomPlus(e / 100)
        setValueZoom(e)
    }
    const handelApply = () => {
        const img = fabricRef.current && fabricRef.current.toDataURL(
            {
                format: 'png',
                multiplier: multiplier
            }
        ) as any;
        dispatch(toolEditorImage.setImageInput(img))
        NotifyController.success(t('apply_success'))
    }
    const resetFilters = () => {
        setBrightness(0)
        setContrast(0)
        setSaturation(0)
        setRGB({
            red: 1,
            green: 1,
            blue: 1
        })
        setBlend(0)
        setOpacity(1)
        setHue(0)
        setSharpen(0)
        setWarm(0)
    }
    const onConfirmEditorInComponent = () => {
        const img = fabricRef.current && fabricRef.current.toDataURL(
            {
                format: 'png',
                multiplier: multiplier
            }
        ) as any;
        dispatch(toolEditorImage.setImageInput(img))
        const payload = {
            width: imageCropData.width,
            height: imageCropData.height,
            imageUrl: img
        }
        onConfirmEditor(payload)
    }
    return (
        <div className='flex flex-col w-full gap-4'>

            <Heading title={t('color')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
            <div className='modal-section flex !flex-row gap-4 justify-between  items-start md:items-center lg:items-center  w-full sm:!flex-col md:!flex-col lg:!flex-col'>
                <div className='flex flex-col gap-2  text-left w-[400px] sm:w-full max-h-fit min-h-fit ml-10'>
                    <hr className="bg-[#F5F5F5] h-[1px] border-none mt-2 " />
                    <div className='flex gap-2 items-center justify-end ' >
                        <Tooltip title={t('Reset')} placement={'bottom'}>
                            <img className='hover:cursor-pointer' src={reset} alt="" width={20} height={20} onClick={resetFilters} />
                        </Tooltip>
                        <p className='text-[#667085] text-[16px] hover:cursor-pointer' onClick={resetFilters}>{t('Reset')}</p>
                    </div>
                    <div className='w-full'>
                        <div>
                            {t('brightness')} :
                            <Slider min={-0.5} max={0.5} value={brightness} step={0.0001} onChange={(value) => { setBrightness(value) }} />
                        </div>
                        <div>
                            {t('contrast')}:
                            <Slider min={-0.5} max={0.5} value={contrast} step={0.0001} onChange={(value) => { setContrast(value) }} />
                        </div>
                        <div>
                            {t('saturation')}:
                            <Slider min={-1} max={1} value={saturation} step={0.0001} onChange={(value) => { setSaturation(value) }} />
                        </div>
                        <div>
                            {t('sharpen')}:
                            <Slider min={-1} max={1} value={sharpen} step={0.01} onChange={(value) => { setSharpen(value) }} />
                        </div>
                        <div>
                            {t('hue')}:
                            <Slider min={-2} max={2} value={hue} step={0.002} onChange={(value) => { setHue(value) }} />
                        </div>
                        <div>
                            {t('warm')}:
                            <Slider min={-0.2} max={0.2} value={warm} step={0.001} onChange={(value) => { setWarm(value) }} />
                        </div>
                        <div>
                            {t('blend')}:
                            <Slider min={0} max={1} value={blend} step={0.01} onChange={(value) => { setBlend(value) }} />
                        </div>
                        <div>
                            {t('opacity')}:
                            <Slider min={0} max={1} value={opacity} step={0.01} onChange={(value) => { setOpacity(value) }} />
                        </div>
                        <Collapse
                            items={items}
                            ghost
                        />
                    </div>
                    <div className={`btn-confirm !mt-0 flex items-center justify-end w-full gap-4 pb-2`}>
                        <Button type='default' className='w-full' onClick={handelApply} style={{
                            background: "white",
                            borderColor: "#0047FF ",
                            color: "#0047FF ",
                        }}>{t('apply_and_continues')}</Button>
                        {typeInput === TypeEditor.PRE && <Button onClick={onConfirmEditorInComponent} type='primary' className='w-full ' >{t('done')}</Button>}
                    </div>
                </div>
                <div className='left relative pre-processing-left  box-canvas-pre  bg-[#CCD9FF] !w-[65vw]  min-h-3/4 flex justify-center items-center min-h-[75vh]' >
                    <div className="hidden">
                        <canvas id="layerTop"></canvas>
                    </div>
                    <canvas id="mycanvas" ref={canvasRef}></canvas>
                </div>

            </div>
            {/* </Modal> */}
        </div>
    )
}

export default Color