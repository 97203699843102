import { Form, Input, Button, Checkbox, Select } from 'antd';
import './styles.css'
import { MailOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import AccountService from '../../services/account.service';
import { deleteToken, persistToken } from '../../services/local-storage.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTERS } from '../../utils/constant';
import logo from '../../assets/images/logo.png'
import enFlag from '../../assets/images/us.svg';
import vnFlag from '../../assets/images/vn.svg';
import { useTranslation } from 'react-i18next';
import { logEvent } from '@firebase/analytics';
import analytics from '../../firebase';
import { customizeKey } from '../../utils/function';
import { Option } from 'antd/es/mentions';
import moment from 'moment';
enum FormEnum {
    signIn = 'SIGN IN',
    signUp = 'SIGN UP',
}

enum UserStatusEnum {
    DELETED = 'DELETED',
    DISABLED = 'DISABLED',
    NOT_VERIFIED = 'NOT_VERIFIED',
    ACTIVE = 'ACTIVE',
    BAN = 'BAN',
}

interface iSubmitData {
    email: string,
    password: string,
    invitationCode: string,
    store_size_other: string,
    store_size: string,
    phoneNumber: string,
    type_of_product_other: string,
    type_of_product: string,
    shopInfo: string,
    brandName: string,
    roleInOrganization: string,
    yearInOperation: string,
    role_other: string
}

const Login = () => {
    const navigate = useNavigate();

    const [form, setForm] = useState('SIGN IN');
    const [checked, setChecked] = useState(true);
    const [emailInput, setEmailInput] = useState('');
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [token, setToken] = useState('');
    const [timeSendMail, setTimeSendMail] = useState(0);
    const [verifyCode, setVerifyCode] = useState('');
    const [isVerify, setIsVerify] = useState(false);
    const [isHiddenSize, setIsHiddenSize] = useState(false);
    const [isHiddenType, setIsHiddenType] = useState(false);
    const [isHiddenRole, setIsHiddenRole] = useState(false);
    const [isFillInfo, setIsFillInfo] = useState(undefined);
    const [source, setSource] = useState('direct');
    const [submitData, setSubmitData] = useState<iSubmitData>({
        email: '',
        password: '',
        invitationCode: '',
        store_size_other: "",
        store_size: "",
        phoneNumber: "",
        type_of_product_other: "",
        type_of_product: "",
        shopInfo: "",
        brandName: "",
        yearInOperation: "",
        roleInOrganization: "",
        role_other: ""
    });
    const [refCode, setRefCode] = useState('');
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const onSendCode = async (email?: string) => {
        if (timeSendMail <= 0) {
            const data = await AccountService.getInstance().sendCode(email ?? submitData.email);
            setTimeSendMail(data.seconds);
        }
    };

    const onSubmitVerify = async () => {
        if (!verifyCode) {
            swal(t('Verify code is not allowed to be empty'), 'warning');
            return;
        }
        if (submitData.email) {
            setLoadingLogin(true);
            const data = await AccountService.getInstance().verifyEmail(submitData.email, verifyCode);
            if (!data) setLoadingLogin(false);
            else {
                if (data.success) {
                    persistToken(token);
                    navigate(ROUTERS.HOME)
                }
            }
            setLoadingLogin(false);
        }
    };

    const onFinish = async (values: iSubmitData) => {
        if (form === FormEnum.signUp) {
            if (!checked) {
                swal(t('Please tick Terms of Service and Privacy Policy'), '', 'warning')
                return;
            }
        }
        setSubmitData(values);
        if (form === FormEnum.signIn) {
            setLoadingLogin(true);
            try {
                const { data, status } = await AccountService.getInstance().login(values.email, values.password, i18n.language);
                setToken(data.data.token);
                if (status === 202) {
                    swal(data.message, '', 'warning');
                } else if (!data.data.status || !data) {
                    swal(t('something_wrong'), '', 'warning');
                } else if (data.data.status === UserStatusEnum.NOT_VERIFIED) {
                    swal(t('Please verify your email'), '', 'warning');
                    const data = await AccountService.getInstance().sendCode(values.email);
                    setTimeSendMail(data.seconds);
                    setIsVerify(true);
                } else if (data.data.status === UserStatusEnum.ACTIVE) {
                    persistToken(data.data.token);
                    swal(t('Login Successfully'), '', 'success');
                    const isFirst = localStorage.getItem('FirstUser')
                    if (isFirst)
                        localStorage.setItem('FirstUser', 'false')
                    else {
                        localStorage.setItem('FirstUser', 'true')
                    }
                    localStorage.setItem('FirstSegmentAndPose', 'true')
                    logEvent(analytics, 'login_success');
                    setTimeout(() => {
                        navigate(ROUTERS.HOME);
                    }, 1000);
                }
            } catch (error: any) {
                swal(error.message, '', 'error');
                logEvent(analytics, `server_error_${customizeKey(error.message)}`)
            } finally {
                setLoadingLogin(false);
            }
        } else if (form === FormEnum.signUp) {
            setLoadingLogin(true);
            try {
                // const { country } = await AccountService.getInstance().checkIpUserSignUp() || { country: 'undefine' }
                const { data, status } = await AccountService.getInstance().signUp(
                    values.email,
                    values.password,
                    refCode ? refCode : values.invitationCode,
                    i18n.language,
                    isHiddenSize ? values.store_size_other : values.store_size,
                    isHiddenType ? values.type_of_product_other : values.type_of_product,
                    values.shopInfo,
                    values.phoneNumber,
                    values.brandName,
                    values.yearInOperation,
                    isHiddenRole ? values.role_other : values.roleInOrganization,
                    source,
                    // country
                );
                if (data.success) {
                    setToken(data.data);
                    setIsVerify(true);
                    await onSendCode(values.email);
                    setForm(FormEnum.signIn)
                    swal(t('signup_success'), t('contact_zalo'), 'success')
                    logEvent(analytics, 'signup_success');
                } if (status === 202) {
                    swal(data.message, '', 'warning');
                }
            } catch (error: any) {
                swal(error.message, '', 'error');
                console.error('error:', error);
                logEvent(analytics, `server_error_${customizeKey(error.message)}`)
            } finally {
                setLoadingLogin(false);
            }
        };
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onSetForm = () => {
        if (form === FormEnum.signIn) {
            setForm(FormEnum.signUp);
        } else {
            setForm(FormEnum.signIn);
        }
    }

    const onSignInGoogle = () => {
        const windowFeatures = 'left=100,top=100,width=375,height=700';
        const windowPopup = window.open(`${process.env.REACT_APP_BE}/account/fashion/google?r_code=${refCode}&lang=${i18n.language}`, 'Popup', windowFeatures);
        windowPopup?.focus();
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const LanguageSwitchLogin = () => {
        return (
            <Select
                onChange={changeLanguage}
                defaultValue={i18n.language}
                style={{ width: 150 }}
                options={[
                    { value: 'en', label: <div className='flex gap-2 items-center justify-start'><img height={20} width={20} src={enFlag} />English</div> },
                    { value: 'vi', label: <div className='flex gap-2 items-center justify-start'><img height={20} width={20} src={vnFlag} />Tiếng Việt</div> }]}
            />
        )
    }

    const onChangeSize = (value: string) => {
        if (value === 'other') {
            setIsHiddenSize(true)
        } else {
            setIsHiddenSize(false)
        }
    }

    const onChangeItem = (value: string) => {
        if (value === 'other') {
            setIsHiddenType(true)
        } else {
            setIsHiddenType(false)
        }
    }

    const onChangeRole = (value: string) => {
        value === 'other' ? setIsHiddenRole(true) : setIsHiddenRole(false)
    }

    const onFinishFillInfo = async (values: iSubmitData) => {
        persistToken(token);
        try {
            // const { country } = await AccountService.getInstance().checkIpUserSignUp() || { country: 'undefine' }
            const { data, status } = await AccountService.getInstance().fillIno(
                isHiddenSize ? values.store_size_other : values.store_size,
                isHiddenType ? values.type_of_product_other : values.type_of_product,
                values.phoneNumber,
                values.shopInfo,
                values.brandName,
                values.yearInOperation,
                isHiddenRole ? values.role_other : values.roleInOrganization,
                i18n.language,
                source,
                // country
            )
            if (status === 202) {
                swal(data.message, '', 'warning');
                deleteToken();
            } else if (data) {
                navigate(ROUTERS.HOME);
            } else {
                deleteToken();
            }
        } catch (error: any) {
            deleteToken();
            swal(error.message, '', 'error');
        }
    }
    const onChangeItemEmail = (e: any) => {
        const value = e.target.value;
        setEmailInput(value)
    }

    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.origin.startsWith(process.env.REACT_APP_BE!) && event.data) {
                const data = JSON.parse(event.data);
                if (data.error) {
                    swal(data.error, '', 'warning')
                } else if (data.token) {
                    persistToken(data.token);
                    navigate(ROUTERS.HOME);
                }
            }
        }, false);
        return () => {
            window.removeEventListener('message', () => { });
        };
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const rCode = urlParams.get('r_code');
        const source = searchParams.get('src');
        if (source) {
            setSource(source)
        }
        if (rCode) {
            setRefCode(rCode);
            setSubmitData({ ...submitData, invitationCode: rCode });
            setForm(FormEnum.signUp);
        }
    }, []);

    useEffect(() => {
        if (timeSendMail > 0) {
            const timer = setInterval(() => {
                setTimeSendMail((prevTime) => prevTime - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [timeSendMail]);

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper sm:hidden">
                    <img src={logo} alt="Login" />
                </div>
                {isFillInfo === false ?
                    <Form
                        name="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinishFillInfo}
                        onFinishFailed={onFinishFailed}
                    >
                        <p className="form-title max-w-full w-[100vw]" >{t('Outfit Virtual Try On & AI Fashion Model')}</p>
                        <p className='text-center'>{t('description_brand')}</p>
                        <Form.Item
                            rules={[
                                { required: true, message: t('phone_number_required') },
                                { pattern: /^[0-9]{10}$/, message: t('phone_number_invalid') }
                            ]}
                            name="phoneNumber"
                            label={<span className='label-input'>{t('phone_number')}</span>}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                        >
                            <Input type='tel' className='custom-input' placeholder={t('enter_your_phone')} />
                        </Form.Item>
                        <Form.Item
                            name="brandName"
                            label={<span className='label-input'>{t('brand_name')}</span>}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required
                        >
                            <Input className='custom-input' placeholder={t('enter_brand_name')} />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                { required: true, message: t('shopInfo_not_invalid') }
                            ]}
                            name="shopInfo"
                            label={<span className='label-input'>{t('enter_shopInfo')}</span>}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input type='text' className='custom-input' placeholder={t('shopInfo')} />
                        </Form.Item>
                        <Form.Item
                            rules={[{ required: true, message: t('store_size_empty') }]}
                            name="store_size"
                            label={<span className='label-input'>{t('store_size')}</span>}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required={true}
                        >
                            <Select
                                placeholder={t('please_select')}
                                onChange={onChangeSize}
                                allowClear
                            >
                                <Option value="small-online-shop">{t('small_online_shop')}</Option>
                                <Option value="chain-of-online-shops">{t('chain_of_online_shops')}</Option>
                                <Option value="shop-with-offline-store">{t('shop_with_offline_store')}</Option>
                                <Option value="other">{t('other')}</Option>
                            </Select>
                        </Form.Item>
                        {isHiddenSize &&
                            <Form.Item
                                rules={[{ required: true, message: t('store_size_empty') }]}
                                name="store_size_other"
                                label={<span className='label-input'>{t('other_size')}</span>}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 24 }}
                                className='ml-10'
                                required={true}
                            >
                                <Input type='text' className='custom-input' placeholder={t('enter_your_company_size')} />
                            </Form.Item>
                        }
                        <Form.Item
                            rules={[{ required: true, message: t('type_of_product_empty') }]}
                            name="type_of_product"
                            label={<span className='label-input'>{t('type_of_product')}</span>}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required={true}
                        >
                            <Select
                                placeholder={t('please_select')}
                                onChange={onChangeItem}
                                allowClear
                            >
                                <Option value="designed-goods">{t('designed_goods')}</Option>
                                <Option value="imported-goods">{t('imported_goods')}</Option>
                                <Option value="other">{t('other')}</Option>
                            </Select>
                        </Form.Item>
                        {isHiddenType &&
                            <Form.Item
                                rules={[{ required: true, message: t('type_of_product_other_empty') }]}
                                name="type_of_product_other"
                                label={<span className='label-input'>{t('other_type_of_products')}</span>}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 24 }}
                                className='ml-10'
                                required={true}
                            >
                                <Input type='text' className='custom-input' placeholder={t('enter_your_type_of_product')} />
                            </Form.Item>
                        }
                        <Form.Item
                            rules={[{ required: true, message: t('year_in_operation_empty') }]}
                            name="yearInOperation"
                            label={<span className='label-input'>{t('year_in_operation')}</span>}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                placeholder={t('please_select')}
                                allowClear
                            >
                                <Option value="new_opening">{t('new_opening')}</Option>
                                <Option value="<1">{t('less_than_1_year')}</Option>
                                <Option value="1-2">{t('1_to_2_years')}</Option>
                                <Option value="2-5">{t('2_to_5_years')}</Option>
                                <Option value=">5">{t('more_than_5_years')}</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="roleInOrganization"
                            label={<span className='label-input'>{t('role_in_organization')}</span>}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required={true}
                        >
                            <Select
                                placeholder={t('please_select')}
                                onChange={onChangeRole}
                                allowClear
                            >
                                <Option value="store_owner">{t('store_owner')}</Option>
                                <Option value="marketing">{t('marketing')}</Option>
                                <Option value="designer">{t('designer')}</Option>
                                <Option value="photographer">{t('photographer')}</Option>
                                <Option value="employee_sale">{t('employee_sale')}</Option>
                                <Option value="other">{t('other')}</Option>
                            </Select>
                        </Form.Item>
                        {isHiddenRole &&
                            <Form.Item
                                rules={[{ required: true, message: t('role_in_organization_valid') }]}
                                name="role_other"
                                label={<span className='label-input'>{t('other_role')}</span>}
                                labelCol={{ span: 28 }}
                                wrapperCol={{ span: 24 }}
                                className='ml-10'
                                required={true}

                            >
                                <Input type='text' className='custom-input' placeholder={t('enter_role_in_organization')} />
                            </Form.Item>
                        }
                        <Form.Item
                            name="invitationCode"
                            label={<span className='label-input'>{t('invitation_code')}</span>}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input className='custom-input' defaultValue={refCode} disabled={refCode ? true : false} placeholder={t('enter_code')} />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={loadingLogin} htmlType="submit" className="login-form-button">
                                <span>{t(form)}</span>
                            </Button>
                        </Form.Item>
                    </Form>
                    : isVerify
                        ? <div className='verify-box'>
                            <p className="form-title max-w-full" >{t('Outfit Virtual Try On & AI Fashion Model')}</p>
                            <p className='text-center'>{t('description_brand')}</p>
                            <div>
                                <p className='verify-title mt-3 mb-2'>
                                    {t('sent_mail')} <span style={{ color: '#868C9B' }}>{submitData.email}</span>, {t('Please go to verify')}
                                </p>
                                <span className="mt-2">
                                    <Input className='custom-input' prefix={<MailOutlined />} type="text" placeholder={t('Verify Code')} onChange={(e: any) => setVerifyCode(e.target.value)} />
                                </span>
                                <div className="mt-2 mb-2">
                                    <Button className='login-form-button' loading={loadingLogin} onClick={onSubmitVerify} >{t('Verify')}</Button>
                                </div>
                            </div>
                            {timeSendMail === 0 ? (
                                <div>
                                    <a className='verify-link' onClick={() => onSendCode()}>
                                        {t("resent_mail")}
                                    </a>
                                </div>
                            ) : (
                                <div>
                                    <a className='verify-link'>
                                        {t('Wait for')} {Math.round(timeSendMail)} {t('seconds to send next mail')}
                                    </a>
                                </div>
                            )}
                        </div>
                        : <Form
                            name="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            requiredMark={true}
                        >
                            <p className="form-title max-w-full w-[100vw]" >{t('Outfit Virtual Try On & AI Fashion Model')}</p>
                            <p className='text-center'>{t('description_brand')}</p>
                            <Form.Item
                                name="email"
                                label={<span className='label-input'>Email</span>}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    { required: true, message: t('error_empty_email') },
                                    { type: 'email', message: t('error_valid_email') }
                                ]}
                                required
                            >
                                <Input className="custom-input" placeholder={t("enter_mail")} onChange={onChangeItemEmail} />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label={
                                    <div className='w-full flex justify-between items-center'>
                                        <div>
                                            <span className='label-input'>{t('password')}</span>
                                        </div>
                                        {form === 'SIGN IN' && <div className='text-left font-medium text-[#0047FF] text-sm hover:underline hover:cursor-pointer' onClick={e => navigate(ROUTERS.FORGOT_PASSWORD, {
                                            state: {
                                                email: emailInput
                                            }
                                        })}>{t('forgot_pass')}?</div>}
                                    </div>}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={
                                    form === 'SIGN UP' ?
                                        [
                                            { required: true, message: t("error_empty_password") },
                                            {
                                                pattern: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\$%\^&\*])(.{8,})"),
                                                message: t('pw_wrong_format')
                                            },
                                        ]
                                        :
                                        [
                                            { required: true, message: t("error_empty_password") }
                                        ]
                                }
                            >
                                <Input className='custom-input' type='password' placeholder={t("enter_password")} />
                            </Form.Item>
                            {form === 'SIGN UP' &&
                                <>
                                    {/* <Form.Item
                                        rules={[
                                            { required: true, message: t('phone_number_required') },
                                            { pattern: /^[0-9]{10}$/, message: t('phone_number_invalid') }
                                        ]}
                                        name="phoneNumber"
                                        label={<span className='label-input'>{t('phone_number')}</span>}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        required
                                    >
                                        <Input type='tel' className='custom-input' placeholder={t('enter_your_phone')} />
                                    </Form.Item>
                                    <Form.Item
                                        name="brandName"
                                        label={<span className='label-input'>{t('brand_name')}</span>}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        required
                                    >
                                        <Input className='custom-input' placeholder={t('enter_brand_name')} />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: t('shopInfo_not_invalid') }
                                        ]}
                                        name="shopInfo"
                                        label={<span className='label-input'>{t('enter_shopInfo')}</span>}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Input type='text' className='custom-input' placeholder={t('shopInfo')} />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{ required: true, message: t('store_size_empty') }]}
                                        name="store_size"
                                        label={<span className='label-input'>{t('store_size')}</span>}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        required={true}
                                    >
                                        <Select
                                            placeholder={t('please_select')}
                                            onChange={onChangeSize}
                                            allowClear
                                        >
                                            <Option value="small-online-shop">{t('small_online_shop')}</Option>
                                            <Option value="chain-of-online-shops">{t('chain_of_online_shops')}</Option>
                                            <Option value="shop-with-offline-store">{t('shop_with_offline_store')}</Option>
                                            <Option value="other">{t('other')}</Option>
                                        </Select>
                                    </Form.Item>
                                    {isHiddenSize && <Form.Item
                                        rules={[{ required: true, message: t('store_size_empty') }]}
                                        name="store_size_other"
                                        label={<span className='label-input'>{t('other_size')}</span>}
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 24 }}
                                        className='ml-10'
                                        required={true}
                                    >
                                        <Input type='text' className='custom-input' placeholder={t('enter_your_company_size')} />
                                    </Form.Item>}
                                    <Form.Item
                                        rules={[{ required: true, message: t('type_of_product_empty') }]}
                                        name="type_of_product"
                                        label={<span className='label-input'>{t('type_of_product')}</span>}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        required={true}
                                    >
                                        <Select
                                            placeholder={t('please_select')}
                                            onChange={onChangeItem}
                                            allowClear
                                        >
                                            <Option value="designed-goods">{t('designed_goods')}</Option>
                                            <Option value="imported-goods">{t('imported_goods')}</Option>
                                            <Option value="other">{t('other')}</Option>
                                        </Select>
                                    </Form.Item>
                                    {isHiddenType && <Form.Item
                                        rules={[{ required: true, message: t('type_of_product_other_empty') }]}
                                        name="type_of_product_other"
                                        label={<span className='label-input'>{t('other_type_of_products')}</span>}
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 24 }}
                                        className='ml-10'
                                        required={true}
                                    >
                                        <Input type='text' className='custom-input' placeholder={t('enter_your_type_of_product')} />
                                    </Form.Item>}
                                    <Form.Item
                                        rules={[{ required: true, message: t('year_in_operation_empty') }]}
                                        name="yearInOperation"
                                        label={<span className='label-input'>{t('year_in_operation')}</span>}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Select
                                            placeholder={t('please_select')}
                                            allowClear
                                        >
                                            <Option value="new_opening">{t('new_opening')}</Option>
                                            <Option value="<1">{t('less_than_1_year')}</Option>
                                            <Option value="1-2">{t('1_to_2_years')}</Option>
                                            <Option value="2-5">{t('2_to_5_years')}</Option>
                                            <Option value=">5">{t('more_than_5_years')}</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="roleInOrganization"
                                        label={<span className='label-input'>{t('role_in_organization')}</span>}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        required={true}
                                    >
                                        <Select
                                            placeholder={t('please_select')}
                                            onChange={onChangeRole}
                                            allowClear
                                        >
                                            <Option value="store_owner">{t('store_owner')}</Option>
                                            <Option value="marketing">{t('marketing')}</Option>
                                            <Option value="designer">{t('designer')}</Option>
                                            <Option value="photographer">{t('photographer')}</Option>
                                            <Option value="employee_sale">{t('employee_sale')}</Option>
                                            <Option value="other">{t('other')}</Option>
                                        </Select>
                                    </Form.Item>
                                    {isHiddenRole && <Form.Item
                                        rules={[{ required: true, message: t('role_in_organization_valid') }]}
                                        name="role_other"
                                        label={<span className='label-input'>{t('other_role')}</span>}
                                        labelCol={{ span: 28 }}
                                        wrapperCol={{ span: 24 }}
                                        className='ml-10'
                                        required={true}

                                    >
                                        <Input type='text' className='custom-input' placeholder={t('enter_role_in_organization')} />
                                    </Form.Item>} */}
                                    <Form.Item
                                        name="invitationCode"
                                        label={<span className='label-input'>{t('invitation_code')}</span>}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Input className='custom-input' defaultValue={refCode} disabled={refCode ? true : false} placeholder={t('enter_code')} />
                                    </Form.Item>
                                    <Form.Item name="remember" valuePropName="checked">
                                        <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
                                            <span className='checkbox-text'>
                                                {t('I agree to')}{' '}
                                                <a className="text-term" href="/term-of-use">
                                                    {t('Term of use')}
                                                </a>{' '}
                                                {t('and')}{' '}
                                                <a className="text-term" href="/privacy-policy">
                                                    {t('Privacy policy')}
                                                </a>
                                                {' '}{t('this_site')}
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                </>
                            }
                            <Form.Item>
                                <Button loading={loadingLogin} htmlType="submit" className="login-form-button">
                                    <span>{t(form)}</span>
                                </Button>
                            </Form.Item>
                            <div className='text-sign-up'>
                                {form === FormEnum.signIn
                                    ? <div>{t('dont_have_account')} <a onClick={() => onSetForm()}>{t('sign_up')}</a></div>
                                    : <div>{t('already_account')} <a onClick={() => onSetForm()}>{t('sign_in')}</a></div>
                                }

                            </div>
                            <div className="divider">
                                <div className="divider-text">{t('or')}</div>
                            </div>
                            <div className="social">
                                <button type='button' onClick={() => onSignInGoogle()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5.8756 14.2944L5.1098 17.1533L2.31083 17.2125C1.47434 15.661 0.999878 13.8859 0.999878 11.9995C0.999878 10.1754 1.44349 8.4553 2.22983 6.94067H2.23043L4.7223 7.39752L5.8139 9.87444C5.58543 10.5405 5.4609 11.2555 5.4609 11.9995C5.46099 12.807 5.60726 13.5807 5.8756 14.2944Z" fill="#FBBB00" />
                                        <path d="M22.8077 9.94446C22.934 10.6099 22.9999 11.2971 22.9999 11.9994C22.9999 12.787 22.9171 13.5552 22.7593 14.2962C22.2239 16.8177 20.8246 19.0196 18.8863 20.5777L18.8857 20.5771L15.7471 20.417L15.3029 17.6439C16.589 16.8897 17.5942 15.7093 18.1236 14.2962H12.2416V9.94446H18.2094H22.8077Z" fill="#518EF8" />
                                        <path d="M18.8864 20.5794L18.887 20.58C17.0019 22.0953 14.6072 23.0019 12.0005 23.0019C7.81134 23.0019 4.16923 20.6604 2.31128 17.2147L5.87605 14.2967C6.805 16.7759 9.19663 18.5408 12.0005 18.5408C13.2056 18.5408 14.3347 18.215 15.3035 17.6463L18.8864 20.5794Z" fill="#28B446" />
                                        <path d="M19.0214 3.53225L15.4578 6.44969C14.4551 5.82294 13.2699 5.46089 12 5.46089C9.13274 5.46089 6.69638 7.30672 5.81397 9.87486L2.23046 6.94109H2.22986C4.06061 3.41138 7.74866 0.999817 12 0.999817C14.6691 0.999817 17.1163 1.95055 19.0214 3.53225Z" fill="#F14336" />
                                    </svg>
                                    <span className='social-text'>{t('login_google')}</span>
                                </button>
                                <LanguageSwitchLogin />
                            </div>
                        </Form>
                }

            </div>
        </div >
    );
};

export default Login;