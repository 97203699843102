import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import NavigateSetter from './utils/history';
import { ConfigProvider } from 'antd';
import './i18n/i18n';
// REDUX
import { Provider } from 'react-redux';
import { store } from './redux/rootStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
if (process.env.NODE_ENV === "production" || process.env.NODE_ENV !== 'development') {
  console.log = function () { };
}
root.render(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#0047FF',
          colorIcon: '#000000',
          borderRadius: 4,
          fontFamily: 'Inter'
        },
        components: {
          Slider: {
            colorPrimary: '#0047FF',
            algorithm: true, // Enable algorithm
            trackBg: '#0047FF',
            railBg: '#B5C5FD'
          }
        }
      }}
    >
      {/* <React.StrictMode> */}
        <ToastContainer stacked={true} />
        <Provider store={store}>
          <BrowserRouter>
            <NavigateSetter />
            <App />
          </BrowserRouter>
        </Provider>
      {/* </React.StrictMode> */}
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
