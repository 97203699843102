import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FashionService from '../../services/fashion.service'
import G from 'glob'
type GuideTrainLoraProps = {
    isOpen: boolean,
    setIsOpen: Function
}
interface IImagesExample {
    url: string,
    caption: string
}
interface IExamples {
    images: IImagesExample[],
    title: string
}
interface IDataGuide {
    title: string,
    sub_title: string,
    example: IExamples,
    requirements: string[]

}
const initialState: IDataGuide = {
    title: "",
    sub_title: "",
    example: {
        images: [
            {
                url: "",
                caption: ""
            },
        ],
        title: ""
    }
    ,
    requirements: [""]
};
function GuideTrainLora({ isOpen, setIsOpen }: GuideTrainLoraProps) {
    const { t, i18n } = useTranslation()
    const [dataGuide, setDataGuide] = useState<IDataGuide>(initialState)
    const getDataGuide = async () => {
        const dataGuide = await FashionService.getInstance().getConfigGuide()
        const dataGuideObject = dataGuide.data.find((item: any) => item.config_key === `vto.lora.guide.${i18n.language}`);
        const parseJson = JSON.parse(dataGuideObject.config_value)
        setDataGuide(parseJson)
    }

    useEffect(() => {
        isOpen && getDataGuide()
    }, [isOpen])

    return (
        <Modal width={'fit-content'}  title={<div className='text-center font-semibold text-[32px] text-black'>{dataGuide.title}</div>} open={isOpen} onCancel={() => setIsOpen(false)} centered
            footer={
                <Button type='primary' autoFocus onClick={() => setIsOpen(false)}>{t('close')}</Button>
            }>
            <div className='max-w-[678px]'>
                <div className='text-[16px] text-center text-black max-w-[650px]'>
                    {dataGuide.sub_title}
                </div>
                <ul className='flex justify-start p-4  flex-col max-w-[650px]'>
                    {dataGuide?.requirements.map((req: string, index: number) => (
                        <li className='text-gray text-[16px] max-w-[650px]'>
                            {req}
                        </li>
                    )
                    )}
                </ul>
                <div className='list-image-guide-train mt-2 flex justify-start flex-col'>
                    <div className='title-blue text-left   text-primary text-[16px] font-medium'>
                        {dataGuide.example.title}
                    </div>
                    <div className='flex justify-center items-center'>
                        <div className='list-images-train mt-2 flex justify-start items-start flex-wrap gap-4 sm:justify-center '>
                            {dataGuide.example.images.map((img: IImagesExample, index: number) => (
                                <div className='w-fit flex flex-col justify-start items-start'>
                                    <img src={img.url} alt="" width={"200px"} height={'250px'} className='object-cover  rounded-lg' />
                                    <p className='text-gray font-[16px] pb-1'>{img.caption}</p>
                                </div>)
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default GuideTrainLora