import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
//LayOut
import { ROUTERS } from './utils/constant';
import Home from './pages/Home';
import { useEffect, useState } from 'react';
import { readToken } from './services/local-storage.service';
import MyWork from './pages/MyWork/List';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import MyWorkDetail from './pages/MyWork/Detail';
import { useDispatch } from 'react-redux';
import AccountService from './services/account.service';
import { userSaga } from './redux/app/authSaga';
import StripePaymentResult from './pages/PaymentResult/StripePaymentResult';
import VNPayPaymentResult from './pages/PaymentResult/VNPayPaymentResult';
import Profile from './pages/Profile/Profile';
import SubscriptionOrderPage from './pages/SubscriptionOrderPage';
import Loading from './Loading';
import RealtimeSegmentProvider from './realtimeSegment/components/hooks/context';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import ToolEditor from './pages/Editor/ToolEditor';
import Tutor from './pages/Tutor';
import TutorDetail from './pages/Tutor/TutorDetail';
import NewHeader from './components/Header/NewHeader';
import VTO from './pages/VTO';
import VTOSetting from './pages/VTOSetting';
import { TryNow } from './pages/TryNow';
import ReportShop from './pages/VTOSetting/VTOReport/ReportShop';
//REACT REDUX
type BrowserRouterProps = {
    openPaymentModal: boolean;
    setOpenPaymentModal: Function
}

function BrowserRouter({ openPaymentModal, setOpenPaymentModal }: BrowserRouterProps) {
    const navigate = useNavigate();
    const token = readToken();
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(true);

    const getUserInfo = async () => {
        try {
            const dataUser = await AccountService.getInstance().getUserInfo();
            dispatch(userSaga.setUser(dataUser));
        } catch (error: any) {
            swal(t('something_wrong'), "", "error");
            navigate(ROUTERS.LOGIN);
        } finally {
            setIsLoading(false);
        }
    }

    const location = useLocation();
    const shouldShowHeader = location.pathname !== ROUTERS.TRY_NOW;

    useEffect(() => {
        if (!token) {
            navigate(ROUTERS.LOGIN);
        } else {
            getUserInfo();
        }
    }, [token, navigate]);

    if (!token) {
        navigate(ROUTERS.LOGIN)
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div id='app-body'>
            {shouldShowHeader && (
                <NewHeader openPaymentModal={openPaymentModal} setOpenPaymentModal={setOpenPaymentModal} />
            )}
            <Routes>
                <Route path={ROUTERS.HOME} element={
                    <RealtimeSegmentProvider>
                        <Home openPaymentModal={openPaymentModal} setOpenPaymentModal={setOpenPaymentModal} />
                    </RealtimeSegmentProvider>
                }
                />
                <Route path={ROUTERS.MY_WORK} element={<MyWork />} />
                <Route path={ROUTERS.TUTOR} element={<Tutor />} />
                <Route path={ROUTERS.TUTOR_DETAIL} element={<TutorDetail />} />
                <Route path={ROUTERS.MY_WORK_DETAIL} element={<MyWorkDetail />} />
                <Route path={ROUTERS.STRIPE_CONFIRM} element={<StripePaymentResult setOpenPaymentModal={setOpenPaymentModal} />} />
                <Route path={ROUTERS.VNPAY_CONFIRM} element={<VNPayPaymentResult setOpenPaymentModal={setOpenPaymentModal} />} />
                <Route path={ROUTERS.PROFILE} element={<Profile setOpenPaymentModal={setOpenPaymentModal} />} />
                <Route path={ROUTERS.TOOL_EDITOR} element={<ToolEditor isToolModal={false} setIsOpenModal={() => { }} setIsGenerating={() => { }} />} />
                <Route path={ROUTERS.UPLOAD} element={<ToolEditor isToolModal={false} setIsOpenModal={() => { }} setIsGenerating={() => { }} />} />
                <Route path={ROUTERS.SUBSCRIPTION_ORDER} element={<SubscriptionOrderPage setOpenPaymentModal={setOpenPaymentModal} />} />
                <Route path={ROUTERS.VTO} element={<VTO />} />
                <Route path={ROUTERS.VTO_SETTING} element={<VTOSetting />} />
                <Route path={ROUTERS.TRY_NOW} element={<TryNow />} />
                <Route path={ROUTERS.VTO_REPORT} element={<ReportShop />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
    )
}

export default BrowserRouter