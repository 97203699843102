import { Empty, Pagination, Skeleton, Spin } from "antd";
import 'moment/locale/vi';
import { lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import FashionService from "../../../services/fashion.service";
import './styles.css';

const ItemImage = lazy(() => import('./ItemImage'));

const MyWork = () => {
    const [listImages, setImages] = useState<any>();
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState<number>(+(searchParams.get('page') ?? '1'));
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const fetchData = async (page: number, pageSize: number) => {
        setLoading(true)
        try {
            const data = await FashionService.getInstance().getImages(page, pageSize, i18n.language);
            setImages(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        navigate(`?page=${page}`)
    };
    useEffect(() => {
        fetchData(currentPage, 8)
    }, [i18n.language, currentPage]);

    useEffect(() => {
        //handle user using button back in browser
        const page = +(searchParams.get('page') ?? "1")
        if (currentPage !== page) {
            setCurrentPage(page);
        }
    }, [searchParams])

    return (
        <Spin spinning={loading}>
            <div className="w-full flex justify-center items-center pb-4">
                {listImages && listImages.images.length > 0 ? (
                    <div>
                        <Pagination
                            current={currentPage}
                            total={listImages.pagination.totalImages}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                            responsive
                        />
                        <div className="p-6 grid grid-cols-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8 m-0 m-auto pb-6">
                            {listImages.images.map((val: any, index: number) => (
                                <>
                                    {val?.url_v2[0] ?
                                        <ItemImage key={`item_img_${index}`} image={val?.url_v2[0]} data={val} />
                                        :
                                        <Skeleton.Image key={`sk_img_${index}`} active={true} style={{
                                            minHeight: '400px',
                                            minWidth: '300px'
                                        }} />
                                    }

                                </>
                            ))}
                        </div>
                        <Pagination
                            current={currentPage}
                            total={listImages.pagination.totalImages}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                            responsive
                        />
                    </div>
                ) : (
                    <Empty className="min-h-[50vh]" description={
                        <span>
                            {t('your_work_empty')}
                        </span>
                    } />
                )}
            </div>
        </Spin>
    );
};

export default MyWork;
