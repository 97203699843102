import FashionService from "../services/fashion.service";

export const UploadFileToS3 = async (
  file: any,
  fileName: string,
  fileType: string
) => {
  const urlInS3 = (await FashionService.getInstance().uploadImage(
    fileName,
    fileType
  )) as any;
  if (urlInS3) {
    const resultURL = (await FashionService.getInstance().uploadFileS3(
      file,
      urlInS3.data.signedRequest,
      urlInS3.data.url.trim()
    )) as any;
    return resultURL;
  }
};
