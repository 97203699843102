import { Button, Radio, Slider, Spin, Switch, Tooltip } from 'antd';
import { fabric } from 'fabric';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    DragOutlined, HighlightOutlined, MinusOutlined, PlusOutlined, ThunderboltOutlined
} from '@ant-design/icons';

import redo from '../../assets/icons/redo.svg';
import reset from '../../assets/icons/reset.svg';
import undo from '../../assets/icons/undo.svg';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import { editorImage } from '../../redux/app/appImagePreprocessing';
import { userSaga } from '../../redux/app/authSaga';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import FashionService from '../../services/fashion.service';
import { TypeEditor } from '../../utils/enum';
import {
    convertBase64Image, convertBase64ImageBlueToWhite, getDrawCursor, resizeCanvasFitScreen, urlToBase64
} from '../../utils/function';
import NotifyController from '../../utils/toast';
import ModalRequiredSub from '../ModalRequiredSub/ModalRequiredSub';
import { resizeImage } from './function';
import { toolEditorImage } from '../../redux/app/toolEditor';
import { setLoadingApp } from '../../redux/app/loadingApp';
import Heading from '../../pages/Editor/DetailsTools/Heading';
import bling from '../../assets/icons/bling.svg';
import NewModalRequiredSub from '../ModalRequiredSub/NewModalRequiredSub';
import { ModalSub } from '../../redux/app/appModalSub';
import AccountService from '../../services/account.service';
import swal from 'sweetalert';
type RemoveWatermarkProps = {
    setLoading: Function;
    isLoading: boolean;
    onConfirmEditor: any;
    tabActive: string;
    typeInput: TypeEditor;
    imageId: string;
    onClickSave: () => void,
    onClickBackToHome: () => void,
}


function RemoveWatermark({ setLoading, isLoading, onConfirmEditor, tabActive, typeInput, imageId, onClickSave, onClickBackToHome }: RemoveWatermarkProps) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    let imageDataStore = useAppSelector(store => store.EditorImage)
    const imageTool = useAppSelector(store => store.toolEditor)
    const dataCost = useAppSelector(store => store.costFeature)
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const prevImageRef = useRef<fabric.Canvas | null>(null);
    const [valueZoom, setValueZoom] = useState<any>(100);
    const [widthPoint, setWidthPoint] = useState<any>(10);
    const [widthPointByZoom, setWidthPointByZoom] = useState<any>(10);
    const [ready, setReady] = useState(false);
    const [mode, setMode] = useState("manual");
    const [imageData, setImageData] = useState<any>(imageDataStore);
    const [isDragging, setDragging] = useState(false);
    const [removeWaterID, setRemoveWaterID] = useState('');
    const [modeMove, setModeMove] = useState("brush");
    const currentCoins = useAppSelector(store => store.user.coins)
    const [ratio, setRatio] = useState<number>(1);
    const [isRightMouseDown, setRightMouseDown] = useState(false);
    const [markAuto, setMarkAuto] = useState('');
    const coins = useAppSelector((store) => store.user.coins);
    const [canvasSizeData, setCanvasSizeData] = useState<
        | {
            scaleX: number;
            scaleY: number;
            widthCanvas: number;
            heightCanvas: number;
        }
        | undefined
    >();
    const [imageAutoLayer, setImageAutoLayer] = useState<string>('');
    const [isDraw, setIsDraw] = useState<boolean>(true);


    let isRedoing = false;
    let h: any = [];
    let panning = false;
    let interval: any;
    const handleUndo = () => {
        if (fabricRef.current && fabricRef.current._objects.length > 0) {
            h.push(fabricRef.current && fabricRef.current._objects.pop());
            fabricRef.current && fabricRef.current.renderAll();
        }
    };

    const handleRedo = () => {
        if (h.length > 0) {
            isRedoing = true;
            fabricRef.current && fabricRef.current.add(h.pop());
        }
    };

    const handleReset = () => {
        isRedoing = true;
        h = [];
        if (fabricRef.current) {
            fabricRef.current._objects = [];
            fabricRef.current && fabricRef.current.renderAll();
            setValueZoom(100);
            setWidthPoint(10);
            fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        }
    };

    const onChangeMode = (e: any) => {
        setMode(e.target.value);
    };

    function disablePanning() {
        if (fabricRef.current) {
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.off("mouse:move");
        }
    }

    const canvas_ZoomPlus = (scaleZoom: number) => {
        if (fabricRef.current) {
            const zoomCenter = new fabric.Point(200.0, 200.0);
            fabricRef.current.forEachObject(function (object) {
                object.selectable = true;
            });
            fabricRef.current.on("mouse:up", function (e) {
                panning = false;
            });

            fabricRef.current.on("mouse:down", function (e) {
                panning = false;
            });
            fabricRef.current.zoomToPoint(zoomCenter, scaleZoom);
        }
    };

    const onChangeZoomPlus = (e: number) => {
        canvas_ZoomPlus(e / 100);
        setValueZoom(e);
    };

    const inputOpacityPoint = (e: number) => {
        setWidthPoint(e);
    };

    const handleMinus = (e: any) => {
        const valueInput = widthPoint - 1;
        setWidthPoint(valueInput);
    };

    const handlePlus = (e: any) => {
        const valueInput = widthPoint + 1;
        setWidthPoint(valueInput);
    };


    const drawLineManual = (
        arrayPath: any,
        x: number,
        y: number
    ) => {
        function convertArrayToPathString(array: any) {
            let pathString = "";
            array.forEach((segment: any) => {
                pathString += segment.shift() + " " + segment.join(" ") + " ";
            });
            return pathString.trim();
        }
        const stringPath = convertArrayToPathString(arrayPath);
        const path = new fabric.Path(stringPath, {
            stroke: "white",
            strokeWidth: widthPoint,
            fill: "transparent",
            left: x,
            top: y,
            selectable: false,
            backgroundColor: "transparent",
            strokeLineCap: "round",
        });
        return path;
    };

    const fillAll = async () => {
        try {
            // @ts-ignore
            fabricRef.current?.setBackgroundImage(null)
            const imgScale = resizeImage(imageData.width, imageData.height);
            fabricRef.current?.setBackgroundColor("black", () => { });
            fabricRef.current?.renderAll();
            const maskBase64 =
                fabricRef.current &&
                (fabricRef.current.toDataURL({
                    format: "png",
                    multiplier: imgScale.multiplier
                }) as any);
            const finalMaskBase64 = await convertBase64ImageBlueToWhite(maskBase64, 0.5);

            const removeWaterManualID = await FashionService.getInstance().removeWaterMarkManual(
                {
                    initImage: imageData.imageUrl,
                    mask: finalMaskBase64,
                    id: imageId
                }
            );
            if (removeWaterManualID) {
                dispatch(userSaga.setCredits(currentCoins - dataCost.removeWatermark));
                dispatch(setLoadingApp.setLoading(false))
            }
            setRemoveWaterID(removeWaterManualID)

        } catch (error: any) {
            dispatch(setLoadingApp.setLoading(false))
            swal(t(error.message), '', 'error')
        }

    };

    const onOke = async () => {
        if (coins < dataCost.removeWatermark) {
            dispatch(ModalSub.setIsOpenModalSub(true))
            NotifyController.warning(t("Not_enough_credit"));
        } else {
            dispatch(setLoadingApp.setLoading(true))
            setLoading(true)
            await fillAll();
        }
    };

    const handleContextMenu = (event: any) => {
        if (event.button === 2) {
            disablePanning();
            setRightMouseDown(true);
            event.preventDefault();
        }
    };

    const handleMouseDown = (event: any) => {
        if (+event.button !== 2) return;
        if (isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = "grab";
            setDragging(true);
        }
    };

    const handleMouseMove = (event: any) => {
        if (isDragging && isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = `grab`;
            const delta = new fabric.Point(event.movementX, event.movementY);
            fabricRef.current.relativePan(delta);
        }
    };

    const handleMouseUp = () => {
        if (isDragging && fabricRef.current) {
            setDragging(false);
            modeMove === "move" && setModeMove("brush");
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(
                widthPoint
            )}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`;
        }
        setRightMouseDown(false);
    };

    const onChangeType = (value: string) => {
        const checked = value === "move";
        setModeMove(value);
        if (fabricRef.current && checked) {
            fabricRef.current.selection = false;
            fabricRef.current.isDrawingMode = false;
            fabricRef.current.renderAll();
            fabricRef.current.forEachObject(function (object) {
                object.selectable = false;
            });
            fabricRef.current.on("mouse:up", function (e) {
                panning = false;
            });

            fabricRef.current.on("mouse:down", function (e) {
                panning = true;
            });

            let previousTouch: any;
            checked &&
                fabricRef.current.on("mouse:move", function (e: any) {
                    if (checked && panning && e && e.e && fabricRef.current) {
                        if (e.e.type === "mousemove") {
                            const delta = new fabric.Point(e.e.movementX, e.e.movementY);
                            fabricRef.current.relativePan(delta);
                        }
                        if (e.e.type === "touchmove" && panning) {
                            const touch = e.e?.touches[0];
                            if (previousTouch) {
                                const mX = touch.pageX - previousTouch?.pageX;
                                const mY = touch.pageY - previousTouch?.pageY;
                                const delta = new fabric.Point(mX, mY);
                                fabricRef.current.relativePan(delta);
                            }
                            previousTouch = touch;
                        }
                    }
                });
        }
        if (!checked && fabricRef.current) {
            disablePanning();
        }
    };

    const setBg = (imageData: any) => {
        const imgScale = resizeImage(imageData.width, imageData.height);
        return new Promise<void>((resolve) => {
            fabric.Image.fromURL(
                imageData.imageUrl,
                function (img: any) {
                    img.set({ erasable: false });
                    fabricRef.current &&
                        fabricRef.current.setBackgroundImage(
                            img,
                            fabricRef.current.renderAll.bind(fabricRef.current),
                            {
                                scaleX: imgScale.sX,
                                scaleY: imgScale.sY,
                            }
                        );
                    ratio > 0 &&
                        fabricRef &&
                        fabricRef.current?.setHeight(imgScale.h);
                    fabricRef &&
                        fabricRef.current?.setWidth(imgScale.w);
                    resolve();
                },
                { crossOrigin: "*" }
            );
        })
    };

    const applyMaskToBG = (base64: string) => {
        const imgScale = resizeImage(imageData.width, imageData.height);
        if (base64 !== "") {
            if (prevImageRef.current) {
                fabricRef.current && fabricRef.current.remove(prevImageRef.current as any);
            }
            fabric.Image.fromURL(
                base64,
                function (img: any) {
                    img.set({
                        scaleX: imgScale.sX,
                        scaleY: imgScale.sY,
                        selectable: false,
                        opacity: 0.5
                    });
                    prevImageRef.current = img;
                    fabricRef.current && fabricRef.current.add(img);
                    fabricRef.current && fabricRef.current.renderAll();
                },
                { crossOrigin: "*" }
            );
        }
    };

    const initFabric = useCallback(() => {
        // Check if the canvas element is available
        if (canvasRef.current) {
            // Initialize a new Fabric.js canvas with drawing mode enabled
            fabricRef.current = new fabric.Canvas(canvasRef.current, {
                isDrawingMode: true,
            });
            // Set up drawing cursor with a custom cursor image and crosshair fallback
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) 5 5, crosshair`;

            // Configure brush settings for free drawing
            fabricRef.current.freeDrawingBrush.width = 10;
            fabricRef.current.freeDrawingBrush.color = "rgba(0, 0, 255, 0.5)";

            // Add event listener for the "object:added" event
            fabricRef.current.on("object:added", function () {
                // Clear history if not redoing
                // if (!isRedoing) {
                //     h = [];
                // }
                isRedoing = false;
            });

            // Add event listener for the "mouse:wheel" event (zooming)
            fabricRef.current.on("mouse:wheel", function (opt: any) {
                // Handle zooming based on mouse wheel movement
                if (fabricRef.current) {
                    const delta = opt.e.deltaY;
                    let zoom = fabricRef.current.getZoom();
                    zoom *= 0.999 ** delta;

                    // Set zoom constraints
                    if (zoom > 20) zoom = 20;
                    if (zoom < 0.01) zoom = 0.01;

                    // Get zoom center point and zoom to that point
                    const zoomCenter = new fabric.Point(opt.pointer.x, opt.pointer.y);
                    fabricRef.current.zoomToPoint(zoomCenter, zoom);
                }
            });
        }
    }, [canvasRef, fabricRef, canvasSizeData, imageAutoLayer]);

    const handleResize = useCallback(async () => {
        if (!imageData.imageUrl) {
            return;
        }
        const elementCanvas = document.getElementsByClassName("border-smooth-left");
        if (!elementCanvas) return;
        const imgScale2 = resizeCanvasFitScreen(
            elementCanvas[0]?.clientWidth,
            elementCanvas[0]?.clientHeight,
            imageData.width,
            imageData.height
        );
        if (imgScale2) {
            setCanvasSizeData({
                widthCanvas: elementCanvas[0]?.clientWidth,
                heightCanvas: elementCanvas[0]?.clientHeight,
                scaleX: imgScale2.sX,
                scaleY: imgScale2.sY,
            });
        }

    }, [imageData.imageUrl])

    const getDataAuto = useCallback(async () => {
        try {
            dispatch(setLoadingApp.setLoading(true))
            const dataAutoMark = await FashionService.getInstance().getRemoveAuto({ "initImage": imageData.imageUrl });
            if (dataAutoMark) {
                dispatch(setLoadingApp.setLoading(false))
                return dataAutoMark;
            }
        } catch (error) {
            setMode("manual");
            dispatch(setLoadingApp.setLoading(false))
        }
    }, [imageData]);

    const initialSetStateBG = useCallback(async () => {
        imageData && setRatio(imageData.width / imageData.height);
        const imgScale = resizeImage(imageData.width, imageData.height);
        if (imgScale) {
            setCanvasSizeData({
                widthCanvas: imgScale.w,
                heightCanvas: imgScale.h,
                scaleX: imgScale.sX,
                scaleY: imgScale.sY,
            });
        }
    }, [imageData.imageUrl]);

    const resizeHandler = useDebouncedCallback(async () => {
        handleReset();
        await handleResize();
    }, 50);

    const updateUserCredits = async () => {
        const { coins } = await AccountService.getInstance().getUserInfo();
        dispatch(userSaga.setCredits(coins));
    }
    const handleAutoMode = async () => {
        if (!imageAutoLayer) {
            const imagAuto = await getDataAuto();
            const processedImageBase64 = await convertBase64Image(
                "data:image/png;base64," + imagAuto
            );
            setImageAutoLayer(processedImageBase64);
            applyMaskToBG(processedImageBase64);
        }

        if (canvasSizeData && imageAutoLayer) {
            applyMaskToBG(imageAutoLayer);
        }
    };
    useEffect(() => {
        (async () => {
            if (mode === 'auto' && !markAuto && isLoading === false) {
                const imagAuto = await getDataAuto();
                const processedImageBase64 = await convertBase64Image(
                    "data:image/png;base64," + imagAuto
                );
                setMarkAuto("data:image/png;base64," + imagAuto)
            }
        })()
    }, [mode])

    useEffect(() => {
        if (typeInput === TypeEditor.POST || typeInput === TypeEditor.UPLOAD || typeInput === TypeEditor.PRE) {
            const imageCropData2 = {
                ...imageDataStore,
                imageUrl: imageTool.inputImage,
                height: imageTool.heightImage,
                width: imageTool.widthImage,
            }
            console.log('imageCropData2', imageCropData2);

            setImageData(imageCropData2)
        }
    }, [imageTool])

    useEffect(() => {
        if (removeWaterID) {

            interval = setInterval(async () => {
                const listenImageRemove = await FashionService.getInstance().listenImageRemove(removeWaterID);
                if (listenImageRemove.status === 'FINISHED') {
                    updateUserCredits()
                    if (interval) {
                        clearInterval(interval);
                    }
                    setLoading(false)
                    dispatch(setLoadingApp.setLoading(false))
                    const urlBase64 = await urlToBase64(listenImageRemove.url_arr[0] + '?not-from-cache-please');
                    const payload = {
                        imageUrl: 'data:image/png;base64,' + urlBase64,
                        width: imageData.width,
                        height: imageData.height
                    }
                    setImageData(payload)
                    dispatch(toolEditorImage.setImageInput(payload.imageUrl))
                    dispatch(toolEditorImage.setSizeImage({
                        width: payload.width,
                        height: payload.height
                    }))
                    dispatch(editorImage.setImageEditor(payload))
                    setMarkAuto('');
                }
                if (listenImageRemove.status === "FAILED") {
                    updateUserCredits()
                    dispatch(setLoadingApp.setLoading(false))
                }
            }, 2000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [removeWaterID, imageDataStore])

    useEffect(() => {
        if (!ready) {
            (async () => {
                await initialSetStateBG();
                await handleResize();
                setReady(true);
            })();
        }
    }, [ready, initialSetStateBG, handleResize]);

    useEffect(() => {
        setWidthPointByZoom(Math.round(widthPoint / (valueZoom / 100)))
    }, [valueZoom, widthPoint])

    useEffect(() => {
        if (ready) {
            (async () => {
                if (!fabricRef.current) {
                    initFabric();
                }
                if (fabricRef.current) {
                    if (mode !== "auto") {
                        handleReset();
                        fabricRef.current.isDrawingMode = true;
                        fabricRef.current.on("mouse:wheel", function (opt: any) {
                            if (fabricRef.current) {
                                const delta = opt.e.deltaY;
                                let zoom = fabricRef.current.getZoom();
                                zoom *= 0.999 ** delta;
                                if (zoom > 3) zoom = 3;
                                if (zoom < 1) zoom = 1;
                                if (zoom == 1) {
                                    setWidthPoint(10)
                                }
                                setValueZoom(zoom * 100)
                                const zoomCenter = new fabric.Point(
                                    opt.pointer.x,
                                    opt.pointer.y
                                );
                                fabricRef.current.zoomToPoint(zoomCenter, zoom);
                            }
                        });
                    } else {
                        handleReset();
                        fabricRef.current.isDrawingMode = false;
                        fabricRef.current.selection = false;
                        fabricRef.current.off('mouse:move');
                        fabricRef.current.off('mouse:wheel')
                    }
                }
                if (typeInput === TypeEditor.POST || typeInput === TypeEditor.UPLOAD) {
                    const imageCropData2 = {
                        ...imageDataStore,
                        imageUrl: imageTool.inputImage,
                        height: imageTool.heightImage,
                        width: imageTool.widthImage,
                    }
                    await setBg(imageCropData2);
                } else {
                    await setBg(imageDataStore);
                }
            })();
        }
    }, [ready, mode, imageData, imageDataStore, typeInput, imageTool]);

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [resizeHandler]);

    useEffect(() => {
        if (fabricRef.current) {
            if (isDraw) {
                fabricRef.current.freeDrawingBrush = new fabric.PencilBrush(fabricRef.current);
                fabricRef.current.freeDrawingBrush.width = widthPoint;
                fabricRef.current.freeDrawingBrush.color = "rgba(0, 0, 255, 0.5)";
            } else {
                // @ts-ignore
                fabricRef.current.freeDrawingBrush = new fabric.EraserBrush(fabricRef.current);
                fabricRef.current.freeDrawingBrush.width = widthPoint;
            }
        }
    }, [isDraw])

    useEffect(() => {
        if (fabricRef.current) {
            fabricRef.current.freeDrawingBrush.width = widthPointByZoom;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`
        }
    }, [widthPointByZoom])

    return (
        <div className='flex flex-col gap-2'>
            <div>
                <Heading title={t('remove_watermark')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
            </div>
            <div className="flex flex-row gap-4 justify-between  sm:items-center md:items-center lg:items-center min-w-[80vw]  w-full sm:flex-col md:flex-col lg:flex-col  ">
                <div className="flex gap-2 justify-between  box-button-pre flex-col min-w-[350px]  min-h-[300px] sm:w-full md:w-full lg:full xl:flex-col ml-10">
                    <div>
                        <div className='sub-title text-base  font-semibold text-left'>{t('action')}</div>
                        <div
                            className={`container-button flex items-center justify-between ${mode === "auto" ? "hidden" : ""
                                }`}
                        >
                            <div className="box-canvas-pre flex gap-2">
                                <div className="undo hover:cursor-pointer" onClick={handleUndo}>
                                    <img src={undo} alt="" />
                                </div>
                                <div className="redo hover:cursor-pointer" onClick={handleRedo}>
                                    <img src={redo} alt="" />
                                </div>
                            </div>
                            <div className="reset flex gap-2">
                                <div className="hover:cursor-pointer" onClick={handleReset}>
                                    <img src={reset} alt="" />
                                </div>
                                <div>{t("Reset")}</div>
                            </div>
                            <div></div>
                        </div>
                        <div className="flex justify-center items-center flex-col">
                            {mode !== "auto" && (
                                <div className="flex flex-col gap-2 max-w-[400px]">
                                    <div className="flex flex-col gap-4">
                                        <div className='flex flex-col gap-2 mt-2'>
                                            <div>
                                                <div className='text-left'>
                                                    {t('size_brush')}:
                                                </div>
                                            </div>
                                            <div className="flex gap-2">
                                                <Button
                                                    shape="circle"
                                                    type="default"
                                                    icon={<MinusOutlined />}
                                                    onClick={(e) => handleMinus(e)}
                                                />
                                                <Slider
                                                    min={1}
                                                    max={100}
                                                    value={widthPoint}
                                                    onChange={(e) => inputOpacityPoint(e)}
                                                    className="w-full min-w-[200px] max-w-[350px]"
                                                />
                                                <Button
                                                    shape="circle"
                                                    type="default"
                                                    icon={<PlusOutlined />}
                                                    onClick={(e) => handlePlus(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col flex-2'>
                                            <div>
                                                <div className='text-left'>
                                                    {t('zoom_image')}:
                                                </div>
                                            </div>
                                            <div className="flex gap-2 justify-center items-center">
                                                <p >100%</p>
                                                <Slider
                                                    disabled={mode === "auto"}
                                                    min={100}
                                                    max={300}
                                                    defaultValue={100}
                                                    value={valueZoom}
                                                    onChange={onChangeZoomPlus}
                                                    className="w-full min-w-[200px] max-w-[350px]"
                                                />
                                                <p>300%</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )}
                            <hr className="bg-[#F5F5F5] h-[1px] border-none mt-3" />
                        </div>
                        <div className='flex flex-col gap-2 ' >
                            <div className='font-semibold text-left'>
                                {t('get_mark_with_ai')}
                            </div>
                            <Tooltip title={t('auto_mask_remove_watermark_tooltip')}>
                                <Button onClick={handleAutoMode} type="default" style={{
                                    borderColor: '#0047FF',
                                    color: '#0047FF'
                                }} className='flex justify-center items-center gap-3'>{t('auto_mark')} <img src={bling} alt="" /></Button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='sub-title !mt-0 text-base font-semibold text-left'>{t('guide')}</div>
                    <div className=" text-left text-gray text-guide box-guide-tool">
                        <ul className='text-[#667085] ml-3'>
                            <li>{t('text_guide_remove_watermark')}</li>
                            <li>{t('text_guide_remove_watermark_2')}</li>
                        </ul>

                    </div>
                    <div>
                        <Button type="default" className='w-full' style={{ background: "white", borderColor: "#0047FF ", color: '#0047FF ' }} onClick={onOke} disabled={isLoading}>
                            <span>{t('apply')} </span><span className='mx-1' > (-{dataCost.removeWatermark}</span> Credits<span className='!ml-0'>)</span>
                        </Button>
                        <div className={`btn-confirm w-full flex justify-center items-center ${typeInput === TypeEditor.PRE ? '' : ' !hidden'} `}>
                            <Button onClick={onConfirmEditor} type='primary' disabled={isLoading} className='w-full' >{t('done')}</Button>
                        </div>
                    </div>
                </div>
                <div className="pre-processing-left  relative box-canvas-pre  bg-[#CCD9FF] !w-[65vw] w-full min-h-3/4 flex justify-center items-center min-h-[75vh]  ">
                    <Spin spinning={isLoading} >
                        <div
                            className="border-smooth-left relative"
                            onContextMenu={(e) => handleContextMenu(e)}
                            onMouseDown={(e) => handleMouseDown(e)}
                            onMouseMove={(e) => handleMouseMove(e)}
                            onMouseUp={handleMouseUp}
                        >
                            <canvas
                                id="mycanvas2"
                                ref={canvasRef}
                            // className="max-w-[600px] max-h-[600px]"
                            ></canvas>

                            <div className={"absolute bottom-0 right-0 opacity-50"}>
                                <Radio.Group
                                    style={{ width: "100%" }}
                                    buttonStyle="solid"
                                    value={modeMove}
                                    onChange={(e) => onChangeType(e.target.value)}
                                >
                                    <Radio.Button value="brush">
                                        <HighlightOutlined />{" "}
                                    </Radio.Button>
                                    <Radio.Button value="move">
                                        <DragOutlined />
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                            <div className={"absolute mx-1 bottom-0 left-0 opacity-50"}>
                                <Switch onChange={(value: any) => setIsDraw(value)} checkedChildren="Draw" unCheckedChildren="Erase" defaultChecked />
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    );
}

export default RemoveWatermark;
