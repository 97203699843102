import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Table, } from 'antd';
import { Space, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import FashionService from '../../../../services/fashion.service';
import { useTranslation } from 'react-i18next';
import NotifyController from '../../../../utils/toast';
import { useAppSelector } from '../../../../redux/hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { setStateVTO } from '../../../../redux/app/appVTO';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import swal from 'sweetalert';

type ModalManagementCategoryProps = {
    isOpen: boolean,
    setIsOpen: Function,
    setOpenRight: Function,
}
interface DataType {
    key: string;
    name: string;
}
const ModalManagementCategory = ({ isOpen, setIsOpen, setOpenRight }: ModalManagementCategoryProps) => {
    const isReloadCate = useAppSelector(store => store.VTOState.isReloadCate)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { i18n } = useTranslation();

    const [listDataCate, setListDataCate] = useState([]);
    const [inputValueCate, setInputValueCate] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editingCategory, setEditingCategory] = useState('');
    const [oldCategory, setOldCategory] = useState('');

    const onEditCate = (category: string) => {
        // Enter edit mode and set the category being edited
        setIsEditing(true);
        setEditingCategory(category);
        setOldCategory(category);
    }

    const onSaveEdit = async () => {
        if (!editingCategory || editingCategory === '') {
            NotifyController.error(t('please_input'));
            return;
        }
        // Call the edit category API
        const edit = await FashionService.getInstance().VTOEditCategory(oldCategory, editingCategory, i18n.language);
        if (edit && edit.success) {
            setIsEditing(false); // Exit edit mode
            NotifyController.success(t('edit_successfully'));
            dispatch(setStateVTO.setReloadCategory(!isReloadCate));
        }
    }

    const onCancelEdit = () => {
        // Cancel editing and reset state
        setIsEditing(false);
        setEditingCategory('');
    }

    const onDeleteCate = async (cateName: string) => {
        if (!cateName) {
            NotifyController.error(t('something_wrong'));
            return
        }
        swal({
            title: t('be_careful'),
            text: t('want_to_delete'),
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: {
                    text: t('cancel'),
                    value: null,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: t('delete'),
                    value: true,
                    visible: true,
                    closeModal: true,
                },
            },
        }).then(async (result) => {
            if (result) {
                const deleteCate = await FashionService.getInstance().VTODeleteCategory(cateName)
                if (deleteCate.success) {
                    dispatch(setStateVTO.setReloadCategory(!isReloadCate))
                    setIsOpen(false)
                    setOpenRight(false)
                    swal(t('delete_successfully'), '', 'success');
                }

            }
        });

    }

    const columns: ColumnsType<DataType> = [
        {
            title: t('category'),
            dataIndex: 'name',
            key: 'name',
            render: text => <a>{text}</a>,
        },
        {
            title: t('action'),
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button ghost onClick={() => onEditCate(record.name)}><EditOutlined className='text-[#0047FF] text-[20px]' /></Button>
                    <Button ghost onClick={() => onDeleteCate(record.key)}><DeleteOutlined className='text-[#D80027] text-[20px]' /></Button>
                </Space>
            ),
        },
    ];

    const data = listDataCate.map((element: any) => {
        return { key: element._id, name: element.category_name };
    });

    const getListData = async () => {
        const { data } = await FashionService.getInstance().VTOGetCategory();
        if (data) {
            setListDataCate(data)
        }

    }

    const onChangeValueInput = (e: any) => {
        const value = e.target.value
        setInputValueCate(value)
    }

    const onCreateCate = async () => {
        if (!inputValueCate || inputValueCate === '') {
            NotifyController.error(t('please_input'));
            return
        }
        const create = await FashionService.getInstance().VTOCreateCategory(inputValueCate, i18n.language);
        if (create && create.success) {
            setInputValueCate('')
            NotifyController.success(t('create_successfully'));
            dispatch(setStateVTO.setReloadCategory(!isReloadCate))
        }
    }

    useEffect(() => {
        getListData()
    }, [isReloadCate])

    return (
        <Modal footer={null} width={'fit-content'} open={isOpen} onCancel={() => setIsOpen(false)} title={t('management_category')} onOk={() => setIsOpen(false)}>
            <div className='flex flex-col gap-4'>
                <div className='flex gap-2'>
                    {!isEditing ? (
                        <>
                            <Input maxLength={30} placeholder={t('input_name_category')} required value={inputValueCate} onChange={onChangeValueInput} />
                            <Button className='text-[#0047FF] border-[#0047FF]' onClick={onCreateCate} icon={<PlusCircleOutlined className='text-[16px]' />}>{t('create')}</Button>
                        </>
                    ) : (
                        <>
                            <Input maxLength={30} value={editingCategory} onChange={(e) => setEditingCategory(e.target.value)} />
                            <Button onClick={onSaveEdit}>{t('save')}</Button>
                            <Button onClick={onCancelEdit}>{t('cancel')}</Button>
                        </>
                    )}
                </div>
                <div>
                    <Table style={{ width: '500px' }} columns={columns} dataSource={data} pagination={false} scroll={{ y: 320 }} />
                </div>
            </div>
        </Modal>
    );
};

export default ModalManagementCategory;
