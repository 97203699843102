import {
  ExclamationCircleOutlined,
  PlusOutlined,
  RollbackOutlined,
  UndoOutlined,
  MinusOutlined,
  LoadingOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { Button, Modal, Skeleton, Spin, Tabs, TabsProps, Tooltip } from "antd";
import Konva from "konva";
import { Profiler, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "underscore";
import Canvas from "../../realtimeSegment/components/Canvas";
import {
  AnnotationProps,
  modelInputProps,
} from "../../realtimeSegment/components/helpers/Interface";
import { createCutouts } from "../../realtimeSegment/components/helpers/createCutouts";
import RealtimeSegmentContext from "../../realtimeSegment/components/hooks/createContext";
import { addIdxToList, changeToBlueImage, checkCoordBelong, createMaskImageData, createMaskedImageData, deleteIdxToList, mergeMasks, rle2mask, rleToImageData } from "./function";
import NotifyController from "../../utils/toast";
import { setIsAuto } from "../../redux/app/appAutoSegment";
import { useDispatch } from "react-redux";
import './styles.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
type Points = { sx: number; sy: number; x: number; y: number };

interface ModalEditRealtimeSegmentProps {

  urlSegment: any;
  setIsOpenModalEdit: Function;
  modelScale: any;
  urlSegmentManual: any;
  hasClicked: boolean;
  setHasClicked: Function;
  handleResetState: Function;
  handleEditByRealtimeSegment: Function;
  dataAnnotations: any;
  setSegmentImagesWithBody: Function,
  onSetSegment: Function,
  widthSegmentRealtime: number,
  heightSegmentRealtime: number,
  showRealTime: boolean,
  setIsShowRealTime: Function
}
function ModalEditRealtimeSegment({

  setIsOpenModalEdit,
  urlSegment,
  modelScale,
  hasClicked,
  setHasClicked,
  handleResetState,
  handleEditByRealtimeSegment,
  urlSegmentManual,
  dataAnnotations,
  setSegmentImagesWithBody,
  onSetSegment,
  showRealTime,
  setIsShowRealTime
}: ModalEditRealtimeSegmentProps) {
  const {
    click: [, setClick],
    clicks: [clicks, setClicks],
    clicksHistory: [clicksHistory, setClicksHistory],
    svg: [svg, setSVG],
    allsvg: [allsvg],
    stickers: [stickers, setStickers],
    segmentTypes: [segmentTypes],
    isErased: [, setIsErased],
    maskImg: [, setMaskImg],
    userNegClickBool: [userNegClickBool, setUserNegClickBool],
    activeSticker: [, setActiveSticker],
    isLoading: [, setIsLoading],
    hasNegClicked: [, setHasNegClicked],
    stickerTabBool: [stickerTabBool],
    isMultiMaskMode: [isMultiMaskMode, setIsMultiMaskMode],
    svgs: [, setSVGs],
    isHovering: [, setIsHovering],
    showLoadingModal: [showLoadingModal],
    predMask: [, setPredMask],
    predMasks: [predMasks, setPredMasks],
    predMasksHistory: [predMasksHistory, setPredMasksHistory],
  } = useContext(RealtimeSegmentContext)!;
  const [annotations, setAnnotations] = useState<Array<AnnotationProps>>([]);
  const [newAnnotation, setNewAnnotation] = useState<Array<AnnotationProps>>(
    []
  );
  const [prevAnnotaiton, setPrevAnnotation] = useState<Array<AnnotationProps>>(
    []
  );
  const [isHoverToolTip, setIsHoverToolTip] = useState<boolean>(false);
  const [textButtonAdvanced, setTextButtonAdvanced] = useState<string>('advanced');
  const [numOfDragEvents, setNumOfDragEvents] = useState<number>(0);
  const [shouldUpdateOnDrag, setShouldUpdateOnDrag] = useState<boolean>(true);
  const [masks, setMasks] = useState<Array<any>>([]);
  const [masksIdx, setMasksIdx] = useState<any[]>([]);
  const [points, setPoints] = useState<Points>();
  const [canvasScale, setCanvasScale] = useState<number>(1);
  const DRAG_THRESHOLD = 4;
  const konvaRef = useRef<Konva.Stage>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [allText, setAllText] = useState<
    string | React.DOMElement<React.DOMAttributes<HTMLElement>, HTMLElement>
  >("");
  const [isDisableOk, setIsDisableOk] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [scaleZoom, setScaleZoom] = useState(1);
  const [isMinus, setIsMinus] = useState(false);
  const [masksInfo, setMasksInfo] = useState<Array<any>>([]);
  const [maskImages, setMaskImages] = useState<Array<any>>([]);
  const [hoverImage, setHoverImage] = useState<any>();
  const [keyActive, setKeyActive] = useState('manual');
  const { t } = useTranslation();
  const [textOk, setTextOk] = useState(<div>{t('apply_segment')}</div>);
  const textInfo = t("guide_segment");
  let timeoutId: any;
  const widthWindow = window.innerWidth;
  const dispatch = useDispatch();

  const superDefer = (cb: Function) => {
    setTimeout(
      () =>
        window.requestAnimationFrame(() => {
          setTimeout(() => {
            cb();
          }, 0);
        }),
      0
    );
  };

  const handleCreateSticker = () => {
    setIsLoading(true);
    superDefer(() =>
      superDefer(() =>
        superDefer(() =>
          superDefer(() => {
            createCutouts(
              urlSegmentManual!,
              svg!,
              allsvg!,
              stickers,
              setStickers,
              segmentTypes,
              setActiveSticker,
              setIsLoading,
              modelScale!,
              handleResetInteraction
            );
          })
        )
      )
    );
  };

  const handleMouseDown = (e: any) => {

    if (e.evt.which !== 2) {
      if (stickerTabBool) return;
      if (clicksHistory) setClicksHistory(null);
      if (predMasksHistory) setPredMasksHistory(null);
      if (segmentTypes !== "Box") return;
      const { x, y } = e.target.getStage().getPointerPosition();
      setNumOfDragEvents(0);
      if (newAnnotation.length === 0) {
        setNewAnnotation([{ x, y, width: 0, height: 0, clickType: -1 }]);
      }
    }
  };

  const handleMoveToMask = _.throttle((e: any, x: number, y: number) => {
    const click = getClick(e, x, y);
    if (!click) return;
    setClicks([click]);
  }, 15);

  const handleMouseMove = (e: any) => {
    if (stickerTabBool) return;
    const { x, y } = e.target.getStage().getPointerPosition();
    if (segmentTypes === "Click" && shouldUpdateOnDrag && !hasClicked) {
      handleMoveToMask(e, x, y);
    } else if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      setNewAnnotation([getAnnotation({ sx, sy, x, y })]);
      setAnnotations([]);
      if (shouldUpdateOnDrag) {
        setPoints({ sx, sy, x, y });
        setNumOfDragEvents((prevValue) => prevValue + 1);
      }
    }
  };

  useEffect(() => {
    if (shouldUpdateOnDrag) {
      if (numOfDragEvents === DRAG_THRESHOLD && points) {
        setNumOfDragEvents(0);
        handleSegmentByBox(points);
      }
    }
  }, [numOfDragEvents, points]);
  useEffect(() => {
    initAnotation();

  }, [dataAnnotations])
  const initAnotation = async () => {
    if (dataAnnotations) {
      if (dataAnnotations.processed) {
        setMasks(dataAnnotations._masks)
        setMasksInfo(dataAnnotations._masksInfo)
        setMaskImages(dataAnnotations._maskImages)
      } else {
        let _masks: any[] = [];
        let _masksInfo: any[] = [];
        let _maskImages: any[] = [];
        dataAnnotations?.forEach((segment: any) => {
          const size = segment.segmentation.size;
          const mask = rle2mask(segment.segmentation.counts, size[1], size[0])
          const maskImage = null // calculate when selecting mask
          _masks.push(mask)
          _masksInfo.push({
            size: segment.segmentation.size,
            area: segment.area,
            bbox: segment.bbox,
            point_coords: segment.point_coords,
            stability_score: segment.stability_score,
            crop_box: segment.crop_box,
          })
          _maskImages.push(maskImage)
        });
        dataAnnotations.processed = true
        dataAnnotations._masks = _masks
        dataAnnotations._masksInfo = _masksInfo
        dataAnnotations._maskImages = _maskImages
        setMasks(_masks)
        setMasksInfo(_masksInfo)
        setMaskImages(_maskImages)
      }
    }
  };
  const handleMouseUp = (e: any, shouldSetClick?: boolean) => {
    if (stickerTabBool) return;
    setIsLoading(true);
    const hasClicked = true;
    setHasClicked(hasClicked);
    const { x, y } = e.target.getStage().getPointerPosition();
    switch (segmentTypes) {
      case "Click":
        if (hasClicked || shouldSetClick) {
          if (shouldSetClick) {
            const newClick = getClick(e, x, y) || null;
            if (newClick?.clickType === 0) {
              setHasNegClicked(true);
            }
            setClick(newClick);
          } else {
            handleSegmentByClick(e, x, y);
          }
        }
        break;
      case "Box":
        const sx = newAnnotation[0].x;
        const sy = newAnnotation[0].y;
        const width = x - sx;
        const height = y - sy;
        const isClick = width === 0 && height === 0;
        setNewAnnotation([]);
        if (isClick) {
          // A box must exist before a click is accepted
          if (clicks?.length && clicks[0].width && clicks[0].height) {
            const newClick = getClick(e, x, y);
            const boxPoints = {
              sx: (clicks[0].x * canvasScale) / modelScale!.scale,
              sy: (clicks[0].y * canvasScale) / modelScale!.scale,
              x: (clicks[0].width * canvasScale) / modelScale!.scale,
              y: (clicks[0].height * canvasScale) / modelScale!.scale,
            };
            adjustPointsToRange(boxPoints, newClick);
            setAnnotations([getAnnotation(boxPoints)]);
            handleSegmentByBox(boxPoints, newClick);
          } else {
            setHasClicked(false);
            setIsLoading(false);
          }
        } else {
          const points = { sx, sy, x, y };
          setPoints(points);
          adjustPointsToRange(points);
          setAnnotations([getAnnotation(points)]);
          handleSegmentByBox(points);
        }
        break;
      default:
        /* eslint-disable */
        null;
        /* eslint-disable */
    }
  };

  const handleMouseOut = () => {
    if (stickerTabBool) return;
    if (clicks?.length === 1 && !hasClicked) {
      _.defer(handleResetInteraction);
      setTimeout(handleResetInteraction, 25);
    }
  };

  const getClick = (
    e: any,
    x: number,
    y: number
  ): modelInputProps | undefined => {
    let clickType;
    if (e.evt.button === 0 || !e.evt.button) {
      clickType = 1;
    } else if (e.evt.button === 2) {
      clickType = 0;
    }
    if (clickType === undefined) return;
    if (userNegClickBool) clickType = 0;
    x *= modelScale!.scale / canvasScale;
    y *= modelScale!.scale / canvasScale;
    return { x, y, width: null, height: null, clickType };
  };

  const handleSegmentByClick = (e: any, x: number, y: number) => {
    const click = getClick(e, x, y);
    if (!click) return;
    setClicks([...(clicks || []), click]);
  };

  const handleSegmentByBox = (
    { sx, sy, x, y }: Points,
    extraClick?: modelInputProps,
    newerClicks?: modelInputProps[]
  ) => {
    sx *= modelScale!.scale / canvasScale;
    sy *= modelScale!.scale / canvasScale;
    x *= modelScale!.scale / canvasScale;
    y *= modelScale!.scale / canvasScale;
    const newClick = {
      x: Math.min(sx, x),
      y: Math.min(sy, y),
      width: Math.max(sx, x),
      height: Math.max(sy, y),
      clickType: 2,
    };
    const newClicks = newerClicks || [...(clicks || [])];
    if (extraClick) {
      newClicks.push(extraClick);
    }
    if (newClicks[0] && !newClicks[0].width) {
      newClicks.unshift(newClick);
    } else {
      newClicks[0] = newClick;
    }
    setClicks(newClicks);
  };

  const getAnnotation = ({ sx, sy, x, y }: Points): AnnotationProps => {
    return {
      x: sx,
      y: sy,
      width: x - sx,
      height: y - sy,
      clickType: 2,
    };
  };

  const adjustPointsToRange = (
    points: Points,
    extraClick?: modelInputProps,
    newClicks?: modelInputProps[]
  ) => {
    const range = findClickRange(extraClick, newClicks);
    if (!range || !range.xMin || !range.yMin || !range.xMax || !range.yMax)
      return;
    let { sx, sy, x, y } = points;
    const xMin = Math.min(sx, x);
    const yMin = Math.min(sy, y);
    const xMax = Math.max(sx, x);
    const yMax = Math.max(sy, y);
    if (range.xMin < xMin) {
      if (sx < x) {
        sx = range.xMin;
      } else {
        x = range.xMin;
      }
    }
    if (range.yMin < yMin) {
      if (sy < y) {
        sy = range.yMin;
      } else {
        y = range.yMin;
      }
    }
    if (range.xMax > xMax) {
      if (sx > x) {
        sx = range.xMax;
      } else {
        x = range.xMax;
      }
    }
    if (range.yMax > yMax) {
      if (sy > y) {
        sy = range.yMax;
      } else {
        y = range.yMax;
      }
    }
    points.sx = sx;
    points.sy = sy;
    points.x = x;
    points.y = y;
  };

  const findClickRange = (
    extraClick?: modelInputProps,
    newClicks?: modelInputProps[]
  ) => {
    let xMin;
    let yMin;
    let xMax;
    let yMax;
    const allClicks = newClicks ? newClicks : clicks ? [...clicks!] : null;
    if (!allClicks) return;
    if (extraClick) {
      allClicks.push(extraClick);
    }
    for (let click of allClicks) {
      if (click.width) continue;
      if (click.clickType === 0) continue;
      if (!xMin || click.x < xMin) {
        xMin = click.x;
      }
      if (!yMin || click.y < yMin) {
        yMin = click.y;
      }
      if (!xMax || click.x > xMax) {
        xMax = click.x;
      }
      if (!yMax || click.y > yMax) {
        yMax = click.y;
      }
    }
    xMin = xMin ? (xMin * canvasScale) / modelScale!.scale : xMin;
    yMin = yMin ? (yMin * canvasScale) / modelScale!.scale : yMin;
    xMax = xMax ? (xMax * canvasScale) / modelScale!.scale : xMax;
    yMax = yMax ? (yMax * canvasScale) / modelScale!.scale : yMax;
    return { xMin, yMin, xMax, yMax };
  };

  const handleResetInteraction = (forceFullReset?: boolean) => {
    setSVG(null);
    setSVGs(null);
    setClick(null);
    setClicks(null);
    setAnnotations([]);
    setNewAnnotation([]);
    setClicksHistory(null);
    setMaskImg(null);
    setUserNegClickBool(false);
    setIsHovering(null);
    setPredMask(null);
    setPredMasks(null);
    setPredMasksHistory(null);
    setIsLoading(false);
    setPoints(undefined);
    if (!forceFullReset) {
      if (!isMultiMaskMode) {
        setHasClicked(false);
      }
    }
  };

  // const handleMultiMaskMode = () => {
  //   if (!isMultiMaskMode) {
  //     if (clicks && clicks.length > 1) {
  //       handleResetInteraction();
  //     }
  //     setHasClicked(true);
  //   } else if (!clicks) {
  //     setHasClicked(false);
  //   }
  //   setIsMultiMaskMode(!isMultiMaskMode);
  // };

  const handleUndoInteraction = () => {
    if (predMasks?.length && clicks?.length) {
      const newPredMasks = [...predMasks];
      const oldPredMask = newPredMasks.pop();
      const newPredMasksHistory = [...(predMasksHistory || [])];
      setPredMasks(newPredMasks);
      if (oldPredMask) {
        newPredMasksHistory.push(oldPredMask);
      }
      setPredMasksHistory(newPredMasksHistory);
      const newClicks = [...clicks];
      const oldClick = newClicks.pop();
      const newClicksHistory = [...(clicksHistory || [])];
      if (oldClick) {
        newClicksHistory.push(oldClick);
      }
      setClicksHistory(newClicksHistory);
      if (clicks.length === 1) {
        setPredMask(null);
        setHasClicked(false);
        setClicks(null);
        setSVG(null);
        setIsErased(false);
        setMaskImg(null);
      } else {
        setIsLoading(true);
        setPredMask(newPredMasks[newPredMasks.length - 1]);
        if (points) {
          const pointsClone = { ...points };
          adjustPointsToRange(pointsClone, undefined, newClicks);
          setAnnotations([getAnnotation(pointsClone)]);
          handleSegmentByBox(pointsClone, undefined, newClicks);
        } else {
          setClicks(newClicks);
        }
      }
    }
  };

  const onOke = async () => {
    // is manual segment 
    if (!showRealTime) {
      if (!masksIdx || !masksIdx.length) {
        NotifyController.warning(t('please_select_area'))
        return
      }
      setTextOk(<LoadingOutlined />)
      setIsDisableOk(true)
      const selected_masks = masks.filter((mask, idx) => masksIdx.indexOf(idx) >= 0)
      const selected_mask = mergeMasks(selected_masks, urlSegmentManual.width * urlSegmentManual.height)
      const maskBase64 = await createMaskImageData(selected_mask, urlSegmentManual.width, urlSegmentManual.height)
      const maskedBase64 = await createMaskedImageData(urlSegmentManual.image, selected_mask, urlSegmentManual.width, urlSegmentManual.height)
      if (selected_masks && selected_mask && maskBase64 && maskedBase64) {
        setIsOpenModalEdit(false)
        setIsDisableOk(false)
        setTextOk(<div>{t('apply_segment')}</div>)
      }
      const blueImage = await changeToBlueImage('data:image/png;base64,' + maskedBase64)
      setSegmentImagesWithBody(blueImage)
      onSetSegment(maskBase64, maskedBase64)
      if (maskedBase64) {
        dispatch(setIsAuto.setIsAuto(false))
      }
    } else {
      handleEditByRealtimeSegment();
    }
  };

  // const handleRedoInteraction = () => {
  //   if (
  //     clicksHistory?.length &&
  //     prevAnnotaiton?.length &&
  //     segmentTypes === "Box"
  //   ) {
  //     setAnnotations(prevAnnotaiton);
  //     setNewAnnotation([]);
  //     setPrevAnnotation([]);
  //   }
  //   if (predMasksHistory?.length && clicksHistory?.length) {
  //     setIsLoading(true);
  //     setHasClicked(true);
  //     const newPredMasks = [...(predMasks || [])];
  //     const newPredMasksHistory = [...(predMasksHistory || [])];
  //     const newPredMask = newPredMasksHistory.pop();
  //     if (newPredMask) {
  //       newPredMasks.push(newPredMask);
  //     }
  //     setPredMasksHistory(newPredMasksHistory);
  //     setPredMasks(newPredMasks);
  //     setPredMask(newPredMasks[newPredMasks.length - 1]);
  //     const newClicks = [...(clicks || [])];
  //     const newClicksHistory = [...(clicksHistory || [])];
  //     const newClick = newClicksHistory.pop();
  //     if (newClick) {
  //       newClicks.push(newClick);
  //     }
  //     setClicksHistory(newClicksHistory);
  //     if (points) {
  //       const pointsClone = { ...points };
  //       adjustPointsToRange(pointsClone, undefined, newClicks);
  //       setAnnotations([getAnnotation(pointsClone)]);
  //       handleSegmentByBox(pointsClone, undefined, newClicks);
  //     } else {
  //       setClicks(newClicks);
  //     }
  //   }
  // };
  const handleClickAdd = () => {
    if (showRealTime)
      setUserNegClickBool(false);
    else {
      if (!isMinus) {
        setIsAdd(!isAdd)
      } else {
        setIsAdd(!isAdd);
        setIsMinus(false)
      }
    }
  };
  const handleClickMinus = () => {
    if (showRealTime)
      setUserNegClickBool(true);
    else {
      if (!isAdd) {
        setIsMinus(!isMinus)
      } else {
        setIsMinus(!isMinus)
        setIsAdd(false)
      }
    }
  };
  const handleUndo = () => {
    if (!showRealTime) {
      const newIdxs = masksIdx.slice()
      newIdxs.pop()
      setMasksIdx(newIdxs);
    } else {
      handleUndoInteraction();
    }
  };
  const handleReset = () => {
    if (!showRealTime) {
      setMasksIdx([]);
    } else {
      handleResetInteraction();
    }
  };
  const imageClasses = "";
  const maskImageClasses = `absolute opacity-40 pointer-events-none`;
  const handleMouseDownManual = async (e: any) => {
    // e.preventDefault();
    let event = (e.button == 0 && isAdd) ? "plus" : (e.button == 2 || isMinus) ? "min" : "";

    if (event == "plus" || event == "min") {
      let el = e.nativeEvent.target;
      const rect = el.getBoundingClientRect();
      let x = (e.clientX - rect.left);
      let y = (e.clientY - rect.top);
      const imageScale = urlSegmentManual ? urlSegmentManual.width / el.offsetWidth : 1;
      x *= imageScale;
      y *= imageScale;
      const idxs = checkCoordBelong(masks, masksInfo, Math.floor(x / scaleZoom), Math.floor(y / scaleZoom));
      if (idxs && idxs.length) {
        // Add the smallest area to the masks || Remove all mask in the coordinate
        const _masks = event == "plus" ? addIdxToList(masksIdx, idxs[0]) : deleteIdxToList(masksIdx, idxs);
        setMasksIdx(_masks);
        setHoverImage(null)
      }
    }
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutId);
    setHoverImage(null);
  };
  const getMaskImage = (idx: number) => {
    if (idx >= 0 && masks.length > idx && masksInfo.length > idx) {
      const mask = masks[idx]
      const size = masksInfo[idx]?.size
      if (!size) {
        console.error("getMaskImage error", idx, "masks.length", masks.length, "masksInfo.length", masksInfo.length)
        return
      }
      const maskImage = rleToImageData(mask, size[1], size[0])
      maskImages[idx] = maskImage
      return maskImage
    }
  }
  const handleMouseMoveManual = async (e: any) => {
    let el = e.nativeEvent.target;
    const rect = el.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    const imageScale = urlSegmentManual ? urlSegmentManual.width / el.offsetWidth : 1;
    x *= imageScale;
    y *= imageScale;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const idxs = checkCoordBelong(masks, masksInfo, Math.floor(x / scaleZoom), Math.floor(y / scaleZoom));
      if (idxs && idxs.length && masksIdx.indexOf(idxs[0]) < 0) {
        setHoverImage(maskImages[idxs[0]] || getMaskImage(idxs[0]));
      } else {
        setHoverImage(null)
      }
    }, 10);
  }

  useEffect(() => {
    return () => {
      handleResetState(process.env.NODE_ENV === 'development');
    }
  }, []);
  const realTimeComponent = () => {
    return (
      (
        <div className="flex justify-center align-center">
          <TransformWrapper onZoom={e => onZoom(e)} doubleClick={{
            disabled: true
          }}
            wheel={{
              step: 0.004,
              smoothStep: 0.004
            }}
            panning={{
              disabled: false,
              allowLeftClickPan: false,
              allowMiddleClickPan: true,
              allowRightClickPan: false,
            }}>
            <TransformComponent>
              <div
                className="flex justify-center align-center min-h-[75vh]"
              //Zoom disable
              >
                {(
                  <div className="overflow-hidden stage flex justify-center items-center w-full h-full">
                    <div
                      className="relative flex flex-col  aspect-auto "
                      style={{
                        width: '49vh',
                        height: '65vh',
                      }}

                    >
                      {showLoadingModal && (
                        <div className="flex justify-center items-center content-center">
                          <Spin className="loading absolute top-1/2 left-1/2" />
                          <div className="w-full absolute top-[55%] left-0 text-center">
                            {t("waiting_moment")}
                          </div>
                        </div>
                      )}

                      <div
                        className={`relative w-full h-full flex justify-center items-center ${showLoadingModal ? "hidden" : "block"
                          }`}
                        ref={containerRef}
                      >
                        <Profiler id="Canvas" onRender={() => { }}>
                          <Canvas
                            konvaRef={konvaRef}
                            annotations={annotations}
                            newAnnotation={newAnnotation}
                            scale={modelScale}
                            handleMouseUp={handleMouseUp}
                            handleMouseDown={handleMouseDown}
                            handleMouseMove={handleMouseMove}
                            handleMouseOut={handleMouseOut}
                            containerRef={containerRef}
                            hasClicked={hasClicked}
                            setCanvasScale={setCanvasScale}
                            isHoverToolTip={[isHoverToolTip, setIsHoverToolTip]}
                            allText={[allText, setAllText]}
                          />
                        </Profiler>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </TransformComponent>
          </TransformWrapper>
        </div>))
  }
  const onZoom = (e: any) => {
    setScaleZoom(e.state.scale)

  }
  const onClickChangeType = () => {
    setIsShowRealTime(!showRealTime)
  }
  const manualComponent = () => {
    return (
      <div className='flex justify-center align-center' onContextMenu={(e) => {
        e.preventDefault();
      }}>
        <div className='flex justify-center align-center ' id='img-segment'
        //Zoom disable
        >
          <TransformWrapper onZoom={e => onZoom(e)} doubleClick={{
            disabled: true
          }}
            wheel={{
              step: 0.004,
              smoothStep: 0.004
            }}

            panning={{
              disabled: false,
              allowLeftClickPan: false,
              allowMiddleClickPan: true,
              allowRightClickPan: false,
            }}>
            <TransformComponent>
              {urlSegment && (
                <img
                  onMouseDown={handleMouseDownManual}
                  onMouseMove={handleMouseMoveManual}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    width: '49vh',
                    height: 'auto',
                  }}

                  src={urlSegment.src}
                  className={`${imageClasses}` + '!pointer-events-auto  aspect-auto '}
                ></img>
              )}
              {hoverImage && <img className={`${maskImageClasses}` + ' layerHover   aspect-auto '}
                style={{
                  // objectFit: 'contain',
                  width: '49vh',
                  height: 'auto',
                }}

                src={hoverImage} alt="" />
              }
              {masksIdx && masksIdx?.map((val: any, index: number) => (
                <img
                  key={index}
                  style={{
                    width: '49vh',
                    height: 'auto',
                  }}

                  src={maskImages[val] || getMaskImage(val)}
                  className={`${maskImageClasses}` + ' aspect-auto  '}
                ></img>
              ))}
            </TransformComponent>
          </TransformWrapper>
        </div>
      </div>)
  }
  const guideComponent = () => {
    return (
      <div className="guide bg-[#F3F6FF] p-2  min-h-[50px] flex rounded my-4">
        <div className="bg-[#F3F6FF]">
          <ul>
            <span className="text-[#101828] text-[16px] font-semibold"> {t('guide')}:</span>
            {(showRealTime ? !userNegClickBool : isAdd) ? (
              <>
                <div className="ml-2 text-[16px] text-[#667085]">&#8226;  <span className="ml-2">{t("left_click")}</span></div>
                <div className="ml-2 text-[16px]  text-[#667085]">&#8226;  <span className="ml-2">{t("right_click")}</span></div>
                <div className="ml-2 text-[16px]  text-[#667085]">&#8226;  <span className="ml-2">{t("middle_click")}</span></div>
                <div className="ml-2 text-[16px]  text-[#667085]">&#8226;  <span className="ml-2">{t("hold_middle_click")}</span></div>
              </>
            ) : (
              <>
                <div className="ml-2 text-[16px] text-[#667085]">&#8226;  <span className="ml-2">{t("click_remove")}</span></div>
                <div className="ml-2  text-[16px]  text-[#667085]">&#8226;  <span className="ml-2">{t("middle_click")}</span></div>
                <div className="ml-2   text-[16px] text-[#667085]">&#8226;  <span className="ml-2">{t("hold_middle_click")}</span></div>
              </>

            )}
          </ul>
        </div>
      </div>
    )
  }
  const confirmComponent = () => {
    return (<div className="flex gap-2 w-full mt-2">
      <Button className="w-full" danger onClick={(e) => setIsOpenModalEdit(false)}>{t('cancel')}</Button>
      <Button className="w-full" onClick={onOke} disabled={isDisableOk} type="primary">
        {textOk}
      </Button>
    </div>)
  }
  const buttonComponent = () => {
    return (
      <div className="flex flex-col justify-between h-full gap-2">
        <div>
          <div className="undo flex justify-between  py-4 ">
            <div className="flex justify-between gap-2">
              <div onClick={handleUndo}><RollbackOutlined className="text-gray text-2xl hover:cursor-pointer hover:text-black " /></div>
              <div onClick={handleReset}><UndoOutlined className="text-gray text-2xl hover:cursor-pointer  hover:text-black " /></div>
            </div>
            <div>
              <Button onClick={onClickChangeType} type="default" style={{
                borderColor: '#0047FF',
                color: "#0047FF",
                backgroundColor: "#F3F6FF"
              }} >{!showRealTime ? t('advanced') : t('manual')}</Button>
            </div>
          </div>
          <div className="btn-add-remove grid grid-cols-2 gap-2 ">
            <Button
              className={"btn-plus rounded-lg"}
              onClick={handleClickAdd}
              type="default"
              style={{
                backgroundColor: (showRealTime ? !userNegClickBool : isAdd) ? "#0047FF" : "white",
                color: (showRealTime ? !userNegClickBool : isAdd) ? "white" : "",
              }}
              icon={<PlusOutlined />}
            >
              {t("add_area")}
            </Button>
            <Button
              onClick={handleClickMinus}
              className="btn-minus rounded-lg"
              type="default"
              style={{
                backgroundColor: (showRealTime ? userNegClickBool : isMinus) ? "#0047FF" : "white",
                color: (showRealTime ? userNegClickBool : isMinus) ? "white" : "",
              }}
              icon={<MinusOutlined />}
            >
              {t("remove_area")}
            </Button>
          </div>
        </div>
      </div>
    )
  }
  const onChangeTab = (key: string) => {
    setIsShowRealTime(key === 'realtime')
  }
  useEffect(() => {
    showRealTime ? setTextButtonAdvanced('back') : setTextButtonAdvanced('advanced')
  }, [showRealTime])
  useEffect(() => {
    window.addEventListener('resize', (e: any) => {
      if (e.target.outerWidth < 768) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
    if (window.screen.width < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window])

  //  ======= End realtime segment ======= //
  return (
    <>
      {!showRealTime ?
        <div className="flex justify-between gap-3  md:flex-col sm:flex-col lg:flex-col">
          <div className="flex flex-col w-1/2  md:hidden sm:hidden lg:hidden">
            <div className="text-[#0047FF] font-bold text-xl">{t('manual_segment')}</div>
            <div className="order-1 md:order-3 sm:order-3 lg:order-3">
              {buttonComponent()}
            </div>
            <div className="order-1 md:order-1 sm:order-1 lg:order-1 mt-4">
              <hr className="opacity-60" />
              {guideComponent()}</div>
            <div className="order-3 flex items-end h-full 1  ">{confirmComponent()}</div>
          </div>
          <div className="hidden md:!block sm:!block lg:!block md:!order-3 sm:!order-3 lg:!order-3">
            {buttonComponent()}
          </div>
          <div className="hidden md:!block sm:!block lg:!block  md:!order-1 sm:!order-1 lg:!order-1">
            <div className="text-[#0047FF] font-bold text-xl">{t('manual_segment')}</div>
            {guideComponent()}</div>
          <div className="order-3 md:order-2 sm:order-2 lg:order-2 ">
            {manualComponent()}
          </div>
          <div className="order-3 md:order-2 sm:order-2 lg:order-2 ">
          </div>
          <div className="hidden order-3  items-end h-full 2 md:!flex sm:!flex lg:!flex ">{confirmComponent()}</div>
        </div>
        :
        <div className="flex justify-between gap-3 md:flex-col sm:flex-col lg:flex-col">
          <div className="flex flex-col md:!hidden sm:!hidden lg:!hidden w-1/2">
            <div className="text-[#0047FF] font-bold text-xl">{t('realtime_segment')}</div>
            <div className="order-1 md:order-3 sm:order-3 lg:order-3">
              {buttonComponent()}
            </div>
            <div className="order-1 md:order-1 sm:order-1 lg:order-1 mt-4">
              <hr className="opacity-60" />
              {guideComponent()}</div>
            <div className="order-3 flex items-end h-full 1  ">{confirmComponent()}</div>
          </div>
          <div className="hidden md:block sm:block lg:block md:order-3 sm:order-3 lg:order-3">
            {buttonComponent()}
          </div>
          <div className="hidden md:block sm:block lg:block  md:order-1 sm:order-1 lg:order-1">
            <div className="text-[#0047FF] font-bold text-xl">{t('realtime_segment')}</div>
            {guideComponent()}</div>
          <div className=" order-3 md:order-2 sm:order-2 lg:order-2 w-full h-full max-w-fit">
            {realTimeComponent()}
          </div>
          <div className="order-3 md:order-2 sm:order-2 lg:order-2 ">
          </div>
          <div className="hidden order-3  items-end h-full 2 md:flex sm:flex lg:flex ">{confirmComponent()}</div>
        </div>
      }
    </>
  );
}

export default ModalEditRealtimeSegment;
