export const ROUTERS = {
  HOME: "/",
  LOGIN: "/login",
  MY_WORK: "/my-work",
  GUIDE: "/guide",
  MY_WORK_DETAIL: "/my-work/:id",
  STRIPE_CONFIRM: "/confirmation",
  VNPAY_CONFIRM: "/payment-result",
  PROFILE: "/profile",
  SUBSCRIPTION_ORDER: "/subscription-order",
  FORGOT_PASSWORD: "/forgot-password",
  CHECKOUT: "/checkout",
  PAYMENT_GUIDE: "/payment-guide",
  TOOL: "/editor",
  UPLOAD: "/editor/upload",
  TOOL_EDITOR: "/editor",
  TUTOR: "/tutor",
  TUTOR_DETAIL: "/tutor/:id",
  VTO: "/virtual-try-on",
  VTO_SETTING: "/virtual-try-on/setting",
  TRY_NOW: "/virtual-try-on/try-now",
  VTO_REPORT:"/virtual-try-on/report"
};

export const EXAMPLE_DATA_IMG2IMG = {
  watermark: "",
  templateName: "",
  templateType: "",
  platform: "",
  model: "",
  init_images: [""],
  resize_mode: 0,
  denoising_strength: 0.75,
  image_cfg_scale: 0,
  mask_blur: 0,
  mask_blur_x: 4,
  mask_blur_y: 4,
  inpainting_fill: 0,
  inpaint_full_res: 0,
  inpaint_full_res_padding: 32,
  inpainting_mask_invert: 1,
  prompt: "",
  negative_prompt: "",
  sampler_name: "Euler a",
  batch_size: 1,
  n_iter: 1,
  steps: 20,
  cfg_scale: 7,
  width: 606,
  height: 896,
  restore_faces: false,
  tiling: false,
  sampler_index: "Euler a",
  send_images: false,
  save_images: true,
  alwayson_scripts: {} as any,
  clip_skip: 2,
  mask: "",
  override_settings_restore_afterwards: false,
  seed: -1,
  subseed: -1,
  subseed_strength: 0,
  seed_resize_from_h: 0,
  seed_resize_from_w: 0,
};
