import { logEvent } from '@firebase/analytics';
import { Tabs, TabsProps } from 'antd';
import 'moment-timezone';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import analytics from '../../firebase';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import Account from './Account';
import Billing from './Billing';
import { useDispatch } from 'react-redux';
import { ModeActive } from '../../redux/app/appModeActiveProfile';


const Profile = (props: any) => {
    const userInfo = useAppSelector(store => store.user)
    const defaultActiveKey = useAppSelector(store => store.modeActive.modeActive)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const items: TabsProps['items'] = [
        {
            key: 'account',
            label: t('account'),
            children: <Account />
        },
        {
            key: 'billing',
            label: t('billing'),
            children: <Billing />,
        },
    ];

    useEffect(() => {
        logEvent(analytics, 'page_profile')
        props.setOpenPaymentModal(false);
    }, [userInfo])
    useEffect(() => {
        return () => {
            dispatch(ModeActive.setModeActive('account'))
        }
    }, [])
    return (
        <div className='w-[88vw] mx-auto overflow-scroll'>
            <Tabs defaultActiveKey={defaultActiveKey} items={items} />
        </div>
    )
}
export default Profile;