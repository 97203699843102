import { Col, Input, InputNumber, InputNumberProps, Row, Slider, Spin } from 'antd';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon_upload from '../../assets/icons/upload-cloud.png';

import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { setStateVTO } from '../../redux/app/appVTO';
import FashionService from '../../services/fashion.service';
import { convertBlobToBase64, convertHeicToJpg, resizeImageToMaxSize768x1024 } from '../../utils/function';
import NotifyController from '../../utils/toast';
import SliderUploadModel from '../VTO-UploadModel/SliderUploadModel';
import InputCategory from '../VTO-UploadModel/VTO-input-setting/InputCategory';
import ImageTrainLora from '../VTO-UploadModel/ImageTrainLora';
import InputLinkProducts from '../VTO-UploadModel/VTO-input-setting/InputLinkProducts';
import InputLimitGen from '../VTO-UploadModel/VTO-input-setting/InputLimitGen';

import AI from '../../assets/vto/icons/ai.svg';
type VTOUploadModelProps = {
    setInputSetting: Function,
    inputSetting: IInputSetting,
    setSelectedImage: Function,
    selectedImage: string,
    isOnClickReset: boolean,
    setUploadedImages: Function,
    uploadedImages: any,
    dataEdit: any,
    setEditInputSetting: Function,
    isOpenCate: boolean,
    setIsOpenCate: Function,
    saveCropperState: Function,
    cropperStates: any,
    setCropperStates: Function,
    cropperRef: any,
    slideIndex: number,
    setSlideIndex: Function,
    onClose: Function,
    setTrainLoraShopHistory: Function,

}
export interface IInputSetting {
    IdOutfit: string,
    category: string,
    prompt: string,
    weight: number,
}
function VTOEditOutfit({
    setEditInputSetting,
    setIsOpenCate,
    isOpenCate,
    setSelectedImage,
    isOnClickReset,
    setUploadedImages,
    dataEdit,
    saveCropperState,
    cropperStates,
    setCropperStates,
    cropperRef,
    slideIndex,
    setSlideIndex, onClose,
    setTrainLoraShopHistory,
}: VTOUploadModelProps) {
    const [valueId, setValueId] = useState('')
    const [categorySelected, setCategorySelected] = useState('')
    const [productLink, setProductLink] = useState('')
    const [numberLimit, setNumberLimit] = useState(null)

    const { t } = useTranslation()
    const [isLoadingText, setIsLoadingText] = useState(false)
    const dispatch = useDispatch()

    const onRemoveImage = (url: string, id: string) => {
        if (id) {
            swal({
                title: t('are_you_sure'),
                text: t('You_will_not_be_able_to_recover_this'),
                icon: 'warning',
                buttons: [t('cancel'), t('delete')],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    FashionService.getInstance().VTODeleteItemImage(id);
                    const cloneEditInputSetting = { ...dataEdit }
                    cloneEditInputSetting.imageAndSetting = cloneEditInputSetting.imageAndSetting.filter((item: any) => item._id !== id)
                    setEditInputSetting(cloneEditInputSetting)
                    dispatch(setStateVTO.setReload(true))
                    setTrainLoraShopHistory({})
                }
            });
        } else {
            const cloneEditInputSetting = { ...dataEdit }
            cloneEditInputSetting.imageAndSetting = cloneEditInputSetting.imageAndSetting.filter((item: any) => item.modelImage !== url)
            setEditInputSetting(cloneEditInputSetting)
            // make sure u can add image this again after remove
            const inputFile = document.getElementById('input-upload-image-item') as HTMLInputElement;
            if (inputFile) {
                inputFile.value = '';
            }
        }
        if (dataEdit.imageAndSetting.length > 0) {
            setSelectedImage(dataEdit.imageAndSetting[0].modelImage)
            setSlideIndex(0)
        }
    }

    const handleImageUpload = async (e: any) => {
        try {
            e.preventDefault();
            let files = e.target?.files;
            if (!files || !files.length) {
                console.warn("No file selected")
                return
            }
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                let imageUrlFinal = event.target.result;
                let isHEIC = imageUrlFinal.startsWith('data:application/octet-stream;base64')
                if (isHEIC) {
                    const response = await fetch(event.target.result);
                    const blob = await response.blob();
                    const converted = await convertHeicToJpg(blob);
                    imageUrlFinal = await convertBlobToBase64(converted.resultBlob) as string;
                }
                if (imageUrlFinal) {
                    const imageMaxSize1024 = await resizeImageToMaxSize768x1024(imageUrlFinal)
                    const cloneEditInputSetting = { ...dataEdit }
                    cloneEditInputSetting.imageAndSetting.push({
                        modelImage: imageMaxSize1024,
                        prompt: '',
                        weight: 1,
                    })
                    setEditInputSetting(cloneEditInputSetting)
                }
            }
            reader.readAsDataURL(files[0]);
            e.target.value = null
        } catch (error) {
            NotifyController.error('Something went wrong!')
        }

    }

    const onClickUploadImage = () => {
        const inputUpload = document.getElementById('input-upload-image-item') as HTMLInputElement;
        inputUpload.click();
    };

    const onCopyId = (id: string) => {
        navigator.clipboard.writeText(id);
        NotifyController.success('Copy code successfully')
    }

    const onChangeOutfitId = (e: any) => {
        setValueId(e.target.value)
        const cloneEditInputSetting = { ...dataEdit }
        cloneEditInputSetting.outfitCode = e.target.value
        setEditInputSetting(cloneEditInputSetting)
    }

    const onChange: InputNumberProps['onChange'] = (newValue) => {
        const cloneEditInputSetting = { ...dataEdit }
        cloneEditInputSetting.imageAndSetting[slideIndex].weight = newValue
        setEditInputSetting(cloneEditInputSetting)
    };

    const onChangePrompt = (e: any) => {
        const value = e.target.value;
        const cloneEditInputSetting = { ...dataEdit }
        cloneEditInputSetting.imageAndSetting[slideIndex].prompt = value
        setEditInputSetting(cloneEditInputSetting)
    }
    const genCaptionByImage = async (index: number) => {
        setIsLoadingText(true)
        const img = dataEdit?.imageAndSetting[index]?.modelImage;
        const { data } = await FashionService.getInstance().postCaptionByImage(img)
        const value = data?.caption;
        if (value) {

            const cloneUploadedImages = { ...dataEdit }
            cloneUploadedImages.imageAndSetting[slideIndex].prompt = value
            setUploadedImages(cloneUploadedImages)
            setIsLoadingText(false)
        }
    }
    useEffect(() => {
        if (isOnClickReset) {
            setUploadedImages([])
        }

    }, [isOnClickReset])

    useEffect(() => {
        if (dataEdit.imageAndSetting.length > 0)
            setSelectedImage(dataEdit.imageAndSetting[0]?.modelImage)
        else {
            onClose()
        }
    }, [dataEdit])

    useEffect(() => {
        setValueId(dataEdit.outfitCode)
        setCategorySelected(dataEdit.category)
        setProductLink(dataEdit.productLink)
        setNumberLimit(dataEdit.generationLimitPerDay)
    }, [dataEdit])

    return (
        <div className='overflow-hidden flex flex-col gap-4 mt-2 relative justify-center'>
            {/* <input id="input-upload-image-replace" type="file" accept="image/png,image/jpeg,image/jpg,image/webp" onChange={onReplaceImage} className="hidden" /> */}
            <div className='flex gap-2'>
                <div className='flex gap-2 flex-col w-full'>
                    <div className='title-input-id text-[#101828] font-semibold text-sm flex flex-row gap-1'>
                        <p> {t('input_id_outfit')}</p>
                        <p className='text-[red]'>*</p>
                    </div>
                    <Input className='w-full h-10' onChange={onChangeOutfitId} value={valueId} prefix={<CopyOutlined onClick={() => onCopyId(dataEdit.outfitCode)} className='text-xl hover:cursor-pointer opacity-50 hover:opacity-100' />} />
                </div>
                <div className='w-full '>
                    <InputCategory inputSetting={dataEdit} setInputSetting={setEditInputSetting} isOpenCate={isOpenCate} setIsOpenCate={setIsOpenCate} category={categorySelected} />
                </div>
            </div>
            <div className='flex sm:flex-col w-full gap-2'>
                <div className='w-[70%] sm:w-full'>
                    <InputLinkProducts inputSetting={dataEdit} value={productLink} setInputSetting={setEditInputSetting} />
                </div>
                <div className='w-[30%] sm:w-full'>
                    <InputLimitGen inputSetting={dataEdit} value={numberLimit} setInputSetting={setEditInputSetting} isEdit={true} />
                </div>
            </div>

            {dataEdit.imageAndSetting.length > 0 && <div className='flex gap-2  justify-start mt-2 '>
                <div className='flex gap-2 overflow-x-auto'>
                    {dataEdit.imageAndSetting.length < 5 && <>
                        <div onClick={onClickUploadImage} className='button-upload hover:cursor-pointer min-w-[120px] min-h-[120px] rounded-lg border border-solid border-[#5379FF] bg-[#F3F6FF] flex flex-col justify-center items-center'>
                            <img src={icon_upload} alt="icon upload" width={'40px'} height={'auto'} />
                            <input id="input-upload-image-item" type="file" accept="image/png,image/jpeg,image/jpg,image/webp, image/heif, image/heic" onChange={handleImageUpload} className="hidden" />
                            <div className='text-[#0047FF] font-medium text-sm'>
                                {t('upload')}
                            </div>
                        </div>
                    </>
                    }
                    {dataEdit.imageAndSetting.map((item: any, index: number) => (
                        <div className='image rounded-lg relative'>
                            <div className=''
                                onClick={() => { setSlideIndex(index); setSelectedImage(item.modelImage); saveCropperState(slideIndex) }}
                                style={{
                                    backgroundImage: `url(${item.modelImage})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    objectFit: 'revert',
                                    backgroundRepeat: 'no-repeat',
                                    width: '120px',
                                    height: '120px',
                                    borderRadius: '8px',
                                    border: index === slideIndex ? '3px #0047FF solid' : '3px #C9CED6 solid'
                                }}></div>

                            <div onClick={() => onRemoveImage(item.modelImage, item._id || undefined)} className='absolute top-2 right-2 hover:cursor-pointer flex justify-center items-center w-8 h-8 bg-white rounded-full group hover:bg-[red] '>
                                <DeleteOutlined className='group-hover:text-[white]' />
                            </div>
                        </div>
                    ))}

                </div>
            </div>}
            {dataEdit.imageAndSetting.length > 0 && <SliderUploadModel
                setTrainLoraShopHistory={setTrainLoraShopHistory}
                saveCropperState={saveCropperState}
                cropperStates={cropperStates}
                setCropperStates={setCropperStates}
                cropperRef={cropperRef}
                indexActive={slideIndex}
                setIndexActive={setSlideIndex}
                listImages={dataEdit.imageAndSetting}
                setListImages={setUploadedImages}
                isEdit={true}
                dataEdit={dataEdit}
                setEditInputSetting={setEditInputSetting}
            />}
            {dataEdit.imageAndSetting.length > 0 && <ImageTrainLora setCloseShow={onClose} isEdit={true} dataEdit={dataEdit} isEnable={dataEdit.imageAndSetting[slideIndex]?.applyTrainedLora ?? true} outfitId={dataEdit.imageAndSetting[slideIndex]._id} inputSetting={setEditInputSetting} indexActive={slideIndex} listOriginalImage={dataEdit.imageAndSetting} setListOriginalImages={setUploadedImages} setTrainLoraShopHistory={setTrainLoraShopHistory} />}
            {dataEdit.imageAndSetting.length > 0 &&
                <>
                    <div>
                        <div className='flex pb-2 flex-row justify-between items-end w-full'>
                            <div className=' text-[#101828] font-semibold leading-20'>{t('label_prompt')} ({t('image_number')}{slideIndex + 1}):</div>
                            <div onClick={() => { genCaptionByImage(slideIndex) }} className='flex flex-row text-center gap-1 justify-center items-center border-primary border-solid border  hover:cursor-pointer rounded-lg p-1 text-primary'>
                                <div>
                                    {t('create_by_ai')}
                                </div>
                                <img src={AI} width={'20px'} alt="" />
                            </div>
                        </div>
                        <Spin spinning={isLoadingText}>
                            <TextArea onChange={onChangePrompt} value={dataEdit?.imageAndSetting[slideIndex]?.prompt} showCount maxLength={300} allowClear />
                        </Spin>
                    </div>
                    <div>
                        <Row className='flex justify-between'>
                            <Col span={16} className='text-[#667085] font-medium text-sm'>
                                {t('creating')}
                            </Col>
                            <Col span={8} className='text-[#667085] font-medium text-sm'>
                                {t('by_input')}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={20}>
                                <Slider
                                    min={0.8}
                                    max={1.2}
                                    step={0.01}
                                    onChange={onChange}
                                    value={dataEdit.imageAndSetting[slideIndex].weight}
                                />
                            </Col>
                            <Col span={4}>
                                <InputNumber
                                    value={dataEdit.imageAndSetting[slideIndex].weight}
                                    min={0.8}
                                    max={1.2}
                                    step={0.01}
                                    style={{ margin: '0 16px' }}
                                    onChange={onChange}
                                />
                            </Col>
                        </Row>
                    </div>
                </>

            }
        </div >
    )
}

export default VTOEditOutfit