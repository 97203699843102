import { Result } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userSaga } from '../../redux/app/authSaga';
import AccountService from '../../services/account.service';
import analytics from '../../firebase';
import { logEvent } from '@firebase/analytics';
import { ModeActive } from '../../redux/app/appModeActiveProfile';

type Props = {
    setOpenPaymentModal: Function
};

const VNPayPaymentResult = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [timeCounter, setTimeCounter] = useState(5);
    const urlParams = new URLSearchParams(window.location.search);
    const responseCode = urlParams.get('vnp_ResponseCode') || urlParams.get('vnp_response_code');
    const orderInfo = urlParams.get('vnp_OrderInfo') || urlParams.get('vnp_order_info');

    const getUserInfo = async () => {
        const dataUser = await AccountService.getInstance().getUserInfo();
        dispatch(userSaga.setUser(dataUser));
    }

    useEffect(() => {
        props.setOpenPaymentModal(false)
        getUserInfo();
        if (responseCode === '00') {
            logEvent(analytics, 'vnpay_payment_success')
        } else {
            logEvent(analytics, 'vnpay_payment_failed')
        }
    }, []);

    setTimeout(function () {
        setTimeCounter(timeCounter - 1)
    }, 1000);

    if (timeCounter === 0) {
        dispatch(ModeActive.setModeActive('billing'))
        navigate('/profile')
    }

    return (
        <div className="payment-result">
            <Result
                status={responseCode === '00' ? 'success' : 'error'}
                title={responseCode === '00' ? t('Payment_Successfully') : t('Payment_Failed')}
                subTitle={orderInfo}
                extra={[
                    <div className="change-style-button flex justify-center gap-1" key="return">
                        {t('Return_to_profile')}<a onClick={e => {
                            dispatch(ModeActive.setModeActive('billing'))
                            navigate('/profile');
                        }}> Profile </a> {t('in')} {timeCounter} {t('seconds')}
                    </div>
                ]}
            />
        </div>
    );
};
export default VNPayPaymentResult;
