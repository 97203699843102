import { Empty, Pagination, PaginationProps } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ViewImage from '../ViewImage/ViewImage';
type ListModalByTypeProps = {
    listData: any,
    setDataEdit: Function,
    isClickEdit: () => void,
    setPage: Function,
    page: number,
    totalRecord: number,
    setListIDToRemove: Function,
    listIDToRemove: any,
    setLimit: Function,
    limit: number,
    setTrainLoraShopHistory: Function
}
function ListModelByType({ listData, setDataEdit, setPage, page, totalRecord, isClickEdit, setListIDToRemove, listIDToRemove, setLimit, limit, setTrainLoraShopHistory }: ListModalByTypeProps) {
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setLimit(pageSize)
    };
    const onChangePage = (page: number) => {
        setPage(page);
    };
    useEffect(() => {
        const listModelsElement = document.querySelectorAll('.scroll-to-top');
        listModelsElement.forEach(element => {
            element.scrollTop = 0;
        });
    }, [listData]);
    return (
        <div className={`flex flex-col gap-2 h-[92vh] min-h-fit overflow-hidden justify-evenly list-model-vto ${listData.length <= 10 && '!justify-start'}`}>
            {/* {listData && listData.length > 0 &&
                <div className='flex  items-end justify-end gap-2'>
                    <>
                        {isMultipleSelection && <div onClick={removeSelected} className='flex iptems-center gap-2 justify-center hover:cursor-pointer hover:text-[red] group'><DeleteOutlined className='text-[20px] group-hover:text-[red] text-[black]' />{t('delete_selected')} | </div>}
                        <Checkbox checked={isMultipleSelection} onChange={(e) => onChangeSelectMultiple(e)}>{t('select_more')}</Checkbox>
                    </>
                </div >
            } */}
            {
                listData && listData.length > 0 && totalRecord > 10 && <Pagination className="block pagination-top"
                    current={page}
                    total={totalRecord}
                    showSizeChanger={true}
                    onChange={onChangePage}
                    pageSize={limit}
                    onShowSizeChange={onShowSizeChange}
                    pageSizeOptions={[10, 20, 50, 100]}
                    responsive

                />
            }
            {(listData && listData.length > 0) ?
                <div
                    style={{
                        scrollBehavior: 'smooth'
                    }}
                    className='scroll-to-top grid grid-cols-5 auto-cols-min  2xl:grid-cols-5 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 max-h-fit overflow-y-auto gap-y-4 gap-x-2 my-2'>{
                        listData.map((item: any, idx: number) => (
                            <ViewImage setTrainLoraShopHistory={setTrainLoraShopHistory} isClickEdit={isClickEdit} key={`view_image_${idx}`} id={item.outfitCode} data={item} listData={listData} setDataEdit={setDataEdit} setListIDToRemove={setListIDToRemove} listIDToRemove={listIDToRemove} image={item?.imageAndSetting[0]?.modelImage} />
                        ))
                    }
                </div>
                : <div className='flex justify-center items-center'>
                    <Empty className='m-auto'></Empty>
                </div>}

            {
                listData && listData.length > 0 && totalRecord > 10 && <Pagination className="mt-3 sm:pb-2 pagination-bottom"
                    current={page}
                    total={totalRecord}
                    showSizeChanger={true}
                    onChange={onChangePage}
                    pageSize={limit}
                    onShowSizeChange={onShowSizeChange}
                    pageSizeOptions={[10, 20, 50, 100]}
                    responsive
                />
            }

        </div>
    )
}

export default ListModelByType