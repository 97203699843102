import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import BrowserRouter from "./Router";
import { ROUTERS } from "./utils/constant";
import { TermOfUse } from "./pages/TermOfUser";
import { PrivacyPolicy } from "./pages/PrivacyAndPolicy";
import ForgotPassword from "./pages/ForgotPassword";
import Checkout from "./pages/Checkout/Checkout";
import PaymentGuide from "./pages/PaymentGuide";
import { useState } from "react";
import AppFooter from "./AppFooter";
import NewModalRequiredSub from "./components/ModalRequiredSub/NewModalRequiredSub";
import { useAppSelector } from "./redux/hooks/useAppSelector";
const AppWrapper = (props: any) => {

    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const isDisplayModalSub = useAppSelector(store => store.modalSub.openModalSub)
    return (
        <div>
            {isDisplayModalSub && <NewModalRequiredSub />}
            <Routes>
                <Route path={ROUTERS.LOGIN} element={<Login />} />
                <Route path={ROUTERS.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path="*" element={<BrowserRouter openPaymentModal={openPaymentModal} setOpenPaymentModal={setOpenPaymentModal} />} />
                <Route path={ROUTERS.CHECKOUT} element={<Checkout />} />
                <Route path={ROUTERS.PAYMENT_GUIDE} element={<PaymentGuide setOpenPaymentModal={setOpenPaymentModal} />} />
                <Route path="/term-of-use" element={<TermOfUse />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            {/* <AppFooter /> */}
        </div>

    );
}

export default AppWrapper