import { Button, Collapse, CollapseProps, ColorPicker, Input, InputNumber, Radio, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from "styled-components";
import './styles.css'
import FashionService from '../../services/fashion.service';
import NotifyController from '../../utils/toast';
const CIRCLE_STYLE = 'circle' //only icon
const HORIZONTAL = 'horizontal' // all
const colors = ['#0047FF', '#E11D48', '#C026D3', '#7E22CE', '#0E7490', '#15803D', '#EAB308', '#EA580C', '#FE6250', '#171717']
const textColors = ['#0047FF', '#E11D48', '#C026D3', '#7E22CE', '#0E7490', '#15803D', '#EAB308', '#EA580C', '#FE6250', '#171717']
const listIconActive = [
    { key: 'active-1', src: 'https://media2.vision2art.ai/active-1.png' },
    { key: 'active-2', src: 'https://media2.vision2art.ai/active-2.png' },
    { key: 'active-3', src: 'https://media2.vision2art.ai/active-3.png' },
    { key: 'active-4', src: 'https://media2.vision2art.ai/active-4.png' },
]
const listIconInactive = [
    { key: 'deactive-1', src: 'https://media2.vision2art.ai/deactive-1.png' },
    { key: 'deactive-2', src: 'https://media2.vision2art.ai/deactive-2.png' },
    { key: 'deactive-3', src: 'https://media2.vision2art.ai/deactive-3.png' },
]

function CustomizeVTOButton() {
    const [margins, setMargins] = useState<any>({
        Desktop: { top: 0, right: 0, bottom: 0, left: 0 },
        Tablet: { top: 0, right: 0, bottom: 0, left: 0 },
        Mobile: { top: 0, right: 0, bottom: 0, left: 0 },
    });
    const [buttonLocation, setButtonLocation] = useState<any>({
        Desktop: 'bottom-right',
        Tablet: 'bottom-right',
        Mobile: 'bottom-right',
    });
    const [contentButton, setContentButton] = useState('Virtual Try On')
    const [buttonStyle, setButtonStyle] = useState(HORIZONTAL)
    const [urlIconDisable, setUrlIconDisable] = useState(listIconInactive[0].src)
    const [urlIcon, setUrlIcon] = useState(listIconActive[0].src)
    const [bgColor, setBgColor] = useState('#0047FF');
    const [textColor, setTextColor] = useState('white');
    const [device, setDevice] = useState('Desktop');

    const { t } = useTranslation()

    const onDeviceChange = (device: string) => {
        setDevice(device);
    };

    const onChangeLocationButton = (e: any) => {
        setButtonLocation((prev: any) => ({ ...prev, [device]: e.target.value }));
    }

    const onChangeMargin = (side: string, value: any) => {
        setMargins((prev: any) => ({
            ...prev,
            [device]: { ...prev[device], [side]: value }
        }));
    };

    const onChangeValueContentButton = (e: any) => {
        setContentButton(e.target.value)
    }

    const onChangeStyleButton = (e: any) => {
        setButtonStyle(e.target.value)
    }

    const handleColorChange = (e: any) => {
        setBgColor(e.target.value);
    };

    const handleTextColorChange = (e: any) => {
        setTextColor(e.target.value);
    };

    const inputColor = (e: any) => {
        setBgColor(e.toHexString())
    }

    const inputTextColor = (e: any) => {
        setTextColor(e.toHexString())
    }

    const onChangeIconInActive = (e: any) => {
        setUrlIconDisable(e.target.value)
    }

    const onChangeIconActive = (e: any) => {
        setUrlIcon(e.target.value)
    }

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: t('advanced'),
            children: <>
                <div className='flex flex-col gap-3'>
                    <div className='flex gap-2 mb-3'>
                        <div className='title-tab w-[125px]'>
                            {t('button_style')}
                        </div>
                        <div className='content-tab'>
                            <Radio.Group onChange={onChangeStyleButton} value={buttonStyle}>
                                <Space direction="vertical">
                                    <Radio value={CIRCLE_STYLE}>{t('circle')}</Radio>
                                    <Radio value={HORIZONTAL}>{t('horizontal')}</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className='change-color flex gap-2 mb-3'>
                        <div className='title-tab w-[125px]'>
                            {t('bg_color')}
                        </div>
                        <div className='content-tab flex flex-col gap-2 w-[230px]'>
                            <div className='mb-2'>
                                <p className='solid-text mb-3'>{t('solid_colors')}</p>
                                <Radio.Group onChange={handleColorChange} value={bgColor} className='ratio-customize flex gap-1 flex-wrap'>
                                    {colors.map(col => (
                                        <StyledRadio
                                            key={col}
                                            value={col}
                                            className={col}
                                            color={col}
                                        >
                                        </StyledRadio>
                                    ))}
                                </Radio.Group>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='solid-text'>{t('customize_color')}</p>
                                <ColorPicker style={{
                                    maxWidth: '150px',
                                    width: 'auto'
                                }} defaultValue={bgColor} value={bgColor} onChangeComplete={e => inputColor(e)} showText />
                            </div>
                        </div>
                    </div>
                    <div className='change-color flex gap-2 mb-3'>
                        <div className='title-tab w-[125px]'>
                            {t('text_color')}
                        </div>
                        <div className='content-tab flex flex-col gap-2 max-w-[230px]'>
                            <div className='mb-2'>
                                <p className='solid-text mb-3'>{t('solid_colors')}</p>
                                <Radio.Group onChange={handleTextColorChange} value={textColor} className='ratio-customize flex gap-1 flex-wrap'>
                                    {textColors.map(col => (
                                        <StyledRadio
                                            key={col}
                                            value={col}
                                            className={col}
                                            color={col}
                                        >
                                        </StyledRadio>
                                    ))}
                                </Radio.Group>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='solid-text'>{t('customize_color')}</p>
                                <ColorPicker style={{
                                    maxWidth: '150px',
                                    width: 'auto'
                                }} defaultValue={textColor} value={textColor} onChangeComplete={e => inputTextColor(e)} showText />
                            </div>
                        </div>
                    </div>
                    {
                        buttonStyle === HORIZONTAL && <div className='flex gap-2'>
                            <div className='title-tab w-[125px]'>
                                Icon
                            </div>
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-col gap-2'>
                                    <p className='text-[#667085]'>{t('icon_vto_support')}</p>
                                    <Radio.Group className="radio-custom" onChange={onChangeIconActive}>
                                        {listIconActive?.map((item) => (
                                            <Radio value={item.src}>
                                                <img
                                                    alt=" "
                                                    width={30}
                                                    height={30}
                                                    src={item.src}
                                                />
                                            </Radio>
                                        )
                                        )}
                                    </Radio.Group>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='text-[#667085]'>{t('icon_vto_unsupport')}</p>
                                    <Radio.Group className="radio-custom" onChange={onChangeIconInActive}>
                                        {listIconInactive?.map((item) => (
                                            <Radio value={item.src}>
                                                <img
                                                    alt=" "
                                                    width={30}
                                                    height={30}
                                                    src={item.src}
                                                />
                                            </Radio>
                                        )
                                        )}
                                    </Radio.Group>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='flex gap-2 mb-3 title-tab'>
                        <Button type={device === 'Desktop' ? 'primary' : 'default'} onClick={() => onDeviceChange('Desktop')}>
                            {t('Desktop')}
                        </Button>
                        <Button type={device === 'Tablet' ? 'primary' : 'default'} onClick={() => onDeviceChange('Tablet')}>
                            {t('Tablet')}
                        </Button>
                        <Button type={device === 'Mobile' ? 'primary' : 'default'} onClick={() => onDeviceChange('Mobile')}>
                            {t('Mobile')}
                        </Button>
                    </div>
                    <div className='flex gap-2 mb-3'>
                        <div className='title-tab w-[125px]'>
                            {t('Button_location')}
                        </div>
                        <div className='content-tab'>
                            <Radio.Group onChange={onChangeLocationButton} value={buttonLocation[device]}>
                                <Space direction="vertical">
                                    <Radio value="top-left">{t('Top Left')}</Radio>
                                    <Radio value="top-right">{t('Top Right')}</Radio>
                                    <Radio value="bottom-left">{t('Bottom Left')}</Radio>
                                    <Radio value="bottom-right">{t('Bottom Right')}</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className='flex gap-2 mb-3'>
                        <div className='title-tab w-[125px]'>
                            {t('Button_margin')}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='flex flex-col gap-2'>
                                {['Top', 'Right', 'Bottom', 'Left'].map((side: any) => (
                                    <div key={side} className='flex items-center justify-between'>
                                        <div className='w-[70px]'>{t(side)}:</div>
                                        <InputNumber
                                            min={0}
                                            value={margins[device][side.toLowerCase()]}
                                            onChange={value => onChangeMargin(side.toLowerCase(), value)}
                                        />
                                        <div className='ml-2'>px</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>,
        },
    ];

    const getSettingButton = async () => {
        const { data } = await FashionService.getInstance().getVTOSetting('customize_button');
        if (data.buttonContent) {
            setContentButton(data.buttonContent)
            setButtonStyle(data.buttonStyle)
            setUrlIconDisable(data.buttonNotSupportIcon)
            setUrlIcon(data.buttonSupportIcon)
            setTextColor(data.buttonTextColor)
            setBgColor(data.buttonBackgroundColor)
            setButtonLocation(data.buttonLocation)
            setMargins(data.buttonMargin)
        }
    }

    const onSave = async () => {
        const dataPayload = {
            customizeButton: {
                buttonContent: contentButton,
                buttonStyle: buttonStyle,
                buttonBackgroundColor: bgColor,
                buttonTextColor: textColor,
                buttonSupportIcon: urlIcon,
                buttonNotSupportIcon: urlIconDisable,
                buttonLocation: buttonLocation,
                buttonMargin: margins
            }
        }
        const data = await FashionService.getInstance().ConfigSettingVTO(dataPayload);
        if (data.success) {
            NotifyController.success(t('edit_out_fit_successfully'))
        } else {
            NotifyController.success(t('something_wrong'))
        }
    }

    useEffect(() => {
        getSettingButton()
    }, [])

    return (
        <div className='flex justify-between flex-wrap gap-2'>
            <div className='left-box max-w-1/2 flex flex-col text-left ml-10'>
                <div className='text-[#101828] text-[16px] font-semibold mb-3'>{t('action')}</div>
                <div>
                    <div className='flex flex-col gap-2 min-w-[350px] max-w-[350px]'>
                        <p className='text-[#667085] text-[16px]'>{t('button_content')}</p>
                        <Input type='text' value={contentButton} onChange={onChangeValueContentButton} />
                    </div>
                    <Collapse items={items} ghost defaultActiveKey={['1']}></Collapse>
                    <Button type='primary' className='w-full' onClick={onSave}>{t('save')}</Button>
                </div>
            </div>
            <div className='right-box w-1/2 min-h-[65vh] md:w-full lg:w-full sm:w-full mr-20 sm:mr-2 lg:ml-10 md:ml-10 xl:ml-10'>
                <p className='text-[#101828] font-semibold text-left'>Preview</p>
                <div className='bg-[#F3F6FF] w-full h-full rounded-md mt-2 flex flex-col gap-4 justify-center items-center '>
                    <div id="vto-button" className='flex  gap-4' style={{
                        backgroundColor: bgColor,
                        color: textColor,
                        padding: '10px',
                        height: buttonStyle === HORIZONTAL ? ' 90px' : '150px',
                        width: buttonStyle === HORIZONTAL ? ' 300px' : '150px',
                        borderRadius: buttonStyle === HORIZONTAL ? ' 50px' : '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            display: buttonStyle === HORIZONTAL ? ' block' : 'none',
                            backgroundImage: `url(${urlIcon})`,
                            width: '50px',
                            height: '50px',
                            backgroundPosition: 'center',
                            backgroundSize: '100%'
                        }}></div>
                        <div className='content-btn text-[30px]'>
                            {contentButton}
                        </div>
                    </div>
                    <div id="vto-disable-button" className='flex  gap-4' style={{
                        backgroundColor: bgColor,
                        color: textColor,
                        padding: '10px',
                        height: buttonStyle === HORIZONTAL ? ' 90px' : '150px',
                        width: buttonStyle === HORIZONTAL ? ' 300px' : '150px',
                        borderRadius: buttonStyle === HORIZONTAL ? ' 50px' : '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            display: buttonStyle === HORIZONTAL ? ' block' : 'none',
                            backgroundImage: `url(${urlIconDisable})`,
                            width: '50px',
                            height: '50px',
                            backgroundPosition: 'center',
                            backgroundSize: '100%'
                        }}></div>
                        <div className='content-btn text-[30px]'>
                            {contentButton}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CustomizeVTOButton
const StyledRadio = styled(Radio) <{ color: string }>`
.ant-radio-inner{
    width:30px;
    height:30px;
    background-color: ${(props) => props.color} !important;
}
.ant-radio-checked .ant-radio-inner{
    width:30px;
    height:30px;
    background-color: white !important;
}
.ant-radio-inner:after{
    width: 40px !important;
    height: 39px !important;
    border-radius:50% !important;
    margin-block-start:0px !important;
    margin-inline-start:0px !important;
    inset-block-start:-50% !important;
    inset-inline-start:-50% !important;
    background-color: ${(props) => props.color} !important;
}
.ant-radio-checked .ant-radio-inner{
    border-width:5px;
    border-color: ${(props) => props.color} !important;
}
`