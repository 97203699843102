import React, { useEffect, useState } from 'react'

import logoModeli from '../../../assets/icons/logo-modeli.svg';
import { FashionClothesEnum } from '../../../utils/enum';
import { useAppSelector } from '../../../redux/hooks/useAppSelector';
import NotifyController from '../../../utils/toast';
import { customizeKey, generateRandomNumberString, getImageSize } from '../../../utils/function';

import { ThunderboltOutlined } from '@ant-design/icons';
import FashionService from '../../../services/fashion.service';
import { logEvent } from '@firebase/analytics';
import swal from 'sweetalert';
import analytics from '../../../firebase';
import { Button, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toolEditorImage } from '../../../redux/app/toolEditor';
import { useDispatch } from 'react-redux';
import Heading from '../DetailsTools/Heading';
type UpscaleProps = {
    url: string,
    imageId: string,
    onClickSave: () => void,
    onClickBackToHome: () => void,
    setIsGenerating: Function,
    setIsOpenModal: Function,
    isModal: boolean
}
function Upscale({ url, imageId, onClickSave, onClickBackToHome, isModal, setIsOpenModal, setIsGenerating }: UpscaleProps) {

    const userInfo = useAppSelector((store) => store.user);
    const editorImage = useAppSelector((store) => store.toolEditor);
    const dataCost = useAppSelector((store) => store.costFeature);
    const [width, setWidth] = useState(editorImage.widthImage);
    const [height, setHeight] = useState(editorImage.heightImage);
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [upscaleValue, setUpscaleValue] = useState(1.5);
    const handleOk = async () => {
        try {
            if (userInfo.coins < dataCost.upscale) {
                NotifyController.warning(t('Not_enough_credit'))
                // setUpscaleModal(false);
                // setCropData('');
                return;
            }
            // if (props.listGenerating.length === userInfo.maxConcurrent) {
            //     swal(t('queue_full'), '', 'warning')
            //     return;
            // }
            // tempDisableButton();
            const body = {
                imageUrl: url,
                upscaleMultiplier: upscaleValue,
                id: imageId
            }
            // const generatingImage = [
            //     {
            //         specialId: generateRandomNumberString(10),
            //         negativePrompt: '',
            //         prompt: '',
            //         percent: 0,
            //         templateName: imageParams.templateName,
            //         templateType: imageParams.templateType,
            //         text: 'Generating...',
            //         numberOfImages: Array.from({ length: 1 }, (_, index) => index)
            //     },
            //     ...props.listGenerating
            // ];
            // props.setListGenerating(generatingImage);
            await FashionService.getInstance().processUpscale(body);
            if (isModal) {
                setIsOpenModal(false)
                setIsGenerating(true);
            } else {
                navigate('/')
            }
            dispatch(toolEditorImage.resetImageEditor())
            const element = document.getElementById('top-list');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            logEvent(analytics, 'upscale_success')
            // props.setIsGenerating(true);
        } catch (error: any) {
            // setDisableGenerateButton(false);
            swal(t(error.message), '', 'error')
            logEvent(analytics, `server_error_${customizeKey(error.message)}`)
        }
    };
    const handleChange = (value: number) => {
        setUpscaleValue(value);
    };
    useEffect(() => {
        (async () => {
            const { width, height } = await getImageSize(url)
            setHeight(height)
            setWidth(width)
        })()
    }, [url])
    return (
        <div className='flex flex-col gap-2'>
            <div>
                <Heading title={t('upscale')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
            </div>
            <div className='modal-section flex flex-row gap-4 justify-between  items-start sm:items-center md:items-center  min-w-[75vw]  w-full sm:!flex-col md:!flex-col lg:!flex-col'>
                <div className='flex flex-col gap-2 w-full max-w-[400px] ml-10 justify-between min-h-[75vh] sm:min-h-max md:min-h-max '>
                    <div >
                        <hr className="bg-[#F5F5F5] h-[1px] border-none " />
                        <div className='text-lg font-semibold text-left pb-4'>{t('action')}</div>
                        <div className='font-normal flex flex-col gap-2  items-start text-[14px]'>
                            <span className='font-normal text-[16px] mr-2'>{t('Upscale_to')}</span>
                            <Select
                                className='mr-2 w-full'
                                defaultValue={upscaleValue}

                                onChange={handleChange}
                                options={[
                                    { value: 1.5, label: 'x1.5' },
                                    { value: 2, label: 'x2' },
                                    { value: 3, label: 'x3' },
                                    { value: 4, label: 'x4', disabled: true },
                                ]}
                            />

                        </div>
                        <div className='sub-title text-base font-semibold text-left'>{t('info')}</div>
                        <div className='flex mb-4 flex-col gap-2 '>
                            <div className='flex gap-4'>
                                <div className='font-normal flex flex-col gap-2  items-start text-[14px]'>
                                    <p>{t('original_size_w')}:</p>
                                    <div className='flex gap-2 justify-center items-center'>
                                        <InputNumber value={width} readOnly disabled className='w-full' />
                                    </div>
                                </div>
                                <div className='font-normal flex flex-col gap-2  items-start text-[14px]'>
                                    <p>{t('original_size_h')}:</p>
                                    <div className='flex gap-2 justify-center items-center'>
                                        <InputNumber value={height} readOnly disabled className='w-full' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-4'>
                                <div className='font-normal flex flex-col gap-2  items-start text-[14px]'>
                                    <p>{t('new_size_w')}:</p>
                                    <div className='flex gap-2 justify-center items-center'>
                                        <InputNumber value={width * upscaleValue} className='w-full' readOnly disabled />
                                    </div>
                                </div>
                                <div className='font-normal flex flex-col gap-2  items-start text-[14px]'>
                                    <p>{t('new_size_h')}:</p>
                                    <div className='flex gap-2 justify-center items-center'>
                                        <InputNumber value={height * upscaleValue} className='w-full' readOnly disabled />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='mt-3 flex flex-col gap-2 flex-end'> {/* <p className='!text-sm !font-normal '>{t('New_size')} ({width * upscaleValue}x{height * upscaleValue})</p> */}
                        <Button onClick={handleOk} type='primary' className='w-full'>
                            <div><span>{t('upscale')}</span><span> (-{dataCost.upscale}</span> Credits<span>)</span></div>
                        </Button>
                    </div>
                </div>
                <div className='pre-processing-left  relative box-canvas-pre  bg-[#CCD9FF] !w-[65vw] flex justify-center items-center min-h-[75vh] '>
                    <div className='flex justify-center   '>
                        <img style={{ borderRadius: '14px' }} className='p-10' src={url} alt='upscale-img' width={370} height={'auto'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Upscale