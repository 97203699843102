import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import LookUpIcon from '../../assets/icons/look-up.svg';
import './styles.css';
type SearchInputProps = {
    setInputValue: Function
}
function SearchInput({ setInputValue }: SearchInputProps) {
    const [params] = useSearchParams()
    const valueParams = params.get('searchValue')
    const inputRef = useRef<HTMLInputElement | null | any>(null);
    const [isActive, setIsActive] = useState(!!valueParams);
    const [currentInputValue, setCurrentInputValue] = useState(valueParams ?? "");
    const { t } = useTranslation()
    const onChangeInput = (e: any) => {
        const value = e.target.value
        setInputValue(value)
        setCurrentInputValue(value)
    }
    const handleSearchClick = () => {
        setIsActive(true);
        inputRef.current && inputRef?.current?.focus();
    };

    const handleCloseClick = () => {
        setIsActive(false);
        setInputValue('');
        setCurrentInputValue('');
    };
    return (
        <div className={`searchBox !w-fit cursor-pointer  !bg-transparent ${isActive ? 'active sm:!w-full sm:!justify-start justify-center' : ''}`}>

            {isActive ? (
                <>
                    <div className="searchInput !bg-white ">
                        <Input ref={inputRef} allowClear className='input !bg-white !placeholder-slate-800 !text-slate-800' value={currentInputValue} onChange={onChangeInput} type="text" placeholder={t('search_id_model')} />
                    </div>
                    <div className="close !min-w-[22px] ml-2" onClick={handleCloseClick}>
                        <CloseOutlined className='text-[16px] text-white' />
                    </div>
                </>
            ) : (
                <div>
                    <Button className='flex justify-center items-center bg-transparent' onClick={handleSearchClick}>
                        <img src={LookUpIcon} className='w-[14px]' />
                    </Button>
                </div>
            )}
        </div>
    )
}

export default SearchInput