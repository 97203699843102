import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import './styles.css'
import FashionService from '../../../services/fashion.service';
import { useAppSelector } from '../../../redux/hooks/useAppSelector';
import setting from '../../../assets/vto/icons/setting.svg'
import { CheckOutlined } from '@ant-design/icons';
type InputCategoryProps = {
    setInputSetting: Function,
    inputSetting: any,
    isOpenCate: boolean,
    setIsOpenCate: Function,
    category?: string
}
function InputCategory({ setInputSetting, inputSetting, setIsOpenCate, category }: InputCategoryProps) {
    const [categorySelected, setCategorySelected] = useState(category || '')
    const isReloadCate = useAppSelector(store => store.VTOState.isReloadCate)
    const [listDataCate, setListDataCate] = useState<any[]>([]);
    const { t } = useTranslation();

    const getListData = async () => {
        const { data } = await FashionService.getInstance().VTOGetCategory();
        if (data) {
            setListDataCate(data)
            setCategorySelected(data.filter((item: any) => item.category_name === category)[0]?.category_name)
        }
    }

    const itemsDropDown = listDataCate ? listDataCate.map((item: any) => {
        return {
            key: item._id,
            value: item.category_name,
        };
    }) : []

    useEffect(() => {
        category && listDataCate && setCategorySelected(listDataCate.filter((item: any) => item?.category_name === category)[0]?.category_name)
    }, [listDataCate, category])

    useEffect(() => {
        getListData()
    }, [isReloadCate])

    const handleChange = (value: string, option: any) => {
        setCategorySelected(value)
        const _ = {
            ...inputSetting,
            category: value
        }
        setInputSetting(_)
    };

    return (
        <div className='flex flex-col items-start gap-2 w-full'>
            <div className='flex gap-2 items-center'>
                <div className='title-input-id text-[#101828] font-semibold text-sm flex flex-row gap-1'>
                    <p> {t('vto_category')}</p>
                    <p className='text-[red]'>*</p>
                </div>
            </div>

            <Select
                menuItemSelectedIcon={<CheckOutlined style={{ color: '#0047FF' }} />}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <div className='text-[#5379FF] hover:cursor-pointer hover:opacity-70' onClick={() => { setIsOpenCate(true) }} style={{ padding: '8px', borderTop: '1px solid #e8e8e8', textAlign: 'center' }}>
                            <div className='flex gap-2 justify-center items-center'>
                                <div className='mt-2'>
                                    <img src={setting} alt="" width={20} height={20} />
                                </div>
                                <div className='text-[14px]'>
                                    {t('management_category')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                placeholder={t('place_select_category')}
                value={categorySelected}
                size='large'
                className='vto-select-category'
                style={{ width: '100%', height: '40px !important', borderRadius: '8px' }}
                onChange={handleChange}
                options={itemsDropDown}
            />

        </div>
    )
}

export default InputCategory