import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AccountService from '../../services/account.service';
import { useTranslation } from 'react-i18next';
import NotifyController from '../../utils/toast';

function ForgotPassword() {
    const { state } = useLocation();
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [emailSendCode, setEmailSendCode] = useState(state.email);
    const [timeSendMail, setTimeSendMail] = useState(0);
    const [isSendEmail, setIsSendEmail] = useState(false);
    const [isClickSend, setIsClickSend] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [codeInput, setCodeInput] = useState('');
    const [valueInputMail, setValueInput] = useState(state.email)
    const handleSendCode = async () => {
        if (!emailSendCode) {
            return
        }
        const data = await AccountService.getInstance().forgotPassword(emailSendCode);
        if (data) {
            setIsClickSend(true)
            setTimeSendMail(data.seconds);
            data && setIsSendEmail(true)
        }
    }
    const onChangeInputEmail = (e: any) => {
        const emailValue = e.target.value;
        setValueInput(emailValue)
        setEmailSendCode(emailValue)
    }
    const onChangeInputCode = (e: any) => {
        const codeValue = e.target.value;
        setCodeInput(codeValue)
    }
    const handleVerifyCode = async () => {
        if (!codeInput) return
        const data = await AccountService.getInstance().verifyForgotPassword(emailSendCode, codeInput);
        if (data) {
            setIsReset(data.success)
        }

    }
    const onFinishChangePass = async (values: any) => {

        const newPassword = values.new_password;
        const data = await AccountService.getInstance().resetPassword(emailSendCode, newPassword);
        if (data.success) {
            NotifyController.success(t('change_pass_success'))
            setTimeout(() => {
                navigate('/login')
            }, 1000)
        } else {
            NotifyController.success(t('change_pass_failed'))
        }

    }
    useEffect(() => {
        if (timeSendMail > 0) {
            const timer = setInterval(() => {
                setTimeSendMail((prevTime) => prevTime - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [timeSendMail]);
    useEffect(() => {
        if (!state) navigate('/login')
    }, [state])
    return (
        <div className='flex justify-center items-center h-screen'>
            <Form
                name="login-form"
                initialValues={{ remember: true }}
                onFinish={isReset ? onFinishChangePass : () => { }}
            >
                {isReset ? <>
                    <Form.Item
                        name="new_password"
                        label={<span className='label-input'>{t('new_password')}</span>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={
                            [
                                { required: true, message: t("error_empty_password1") },
                                {
                                    pattern: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\$%\^&\*])(.{8,})"),
                                    message: t('pw_wrong_format')
                                },
                            ]
                        }
                    >
                        <Input className='custom-input' type='password' placeholder={t("enter_password")} />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" className="login-form-button">
                            <span>{t('update')}</span>
                        </Button>
                    </Form.Item>
                </> : <>


                    <Form.Item
                        name="email"
                        label={<span className='label-input'>Email</span>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        required
                        rules={[
                            { required: !valueInputMail, message: t('error_empty_email') },
                            { type: 'email', message: t('error_valid_email') }
                        ]}
                    >
                        <Input type='email' className='custom-input' disabled={isSendEmail} value={valueInputMail} defaultValue={valueInputMail} onChange={onChangeInputEmail} />
                    </Form.Item>
                    {isSendEmail && <Form.Item
                        name="code"
                        label={<span className='label-input'>Code</span>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        required
                        rules={
                            [
                                { required: true, message: t("error_empty_code") },
                            ]
                        }
                    >
                        <Input type='number' className='custom-input' onChange={onChangeInputCode} />
                    </Form.Item>}
                    <Form.Item>
                        {isSendEmail ?
                            <Button onClick={handleVerifyCode} htmlType="submit" className="login-form-button">
                                <span>{t('verify_code')}</span>
                            </Button>

                            :
                            <Button onClick={handleSendCode} htmlType="submit" className="login-form-button">
                                <span>{t('send_code')}</span>
                            </Button>}
                        {isClickSend && <div>
                            {timeSendMail <= 1 ? (
                                <div>
                                    <div className='verify-link text-sky-500 hover:cursor-pointer hover:underline' onClick={() => handleSendCode()}>
                                        {t("resent_mail")}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className='verify-link text-sky-500'>
                                        {t('Wait for')} {Math.round(timeSendMail)} {t('seconds to send next mail')}
                                    </div>
                                </div>
                            )}
                        </div>}
                    </Form.Item>
                </>}
                <Button onClick={e => navigate('/login')}>{t('back_to_login')}</Button>
            </Form>
        </div>
    )
}

export default ForgotPassword