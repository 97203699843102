import { DeleteOutlined } from "@ant-design/icons";
import { Checkbox, Select, Spin, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import { setStateVTO } from "../../redux/app/appVTO";
import { useAppSelector } from "../../redux/hooks/useAppSelector";
import FashionService from "../../services/fashion.service";
import NotifyController from "../../utils/toast";
import ListModelByType from "./ListModel/ListModelByType";
type ListModelVTOProps = {
    setDataEdit: Function;
    dataEdit: any;
    valueSearch: string;
    isClickEdit: () => void;
    setTrainLoraShopHistory: Function;
};
function ListModelVTO({
    setDataEdit,
    dataEdit,
    valueSearch,
    isClickEdit,
    setTrainLoraShopHistory,
}: ListModelVTOProps) {
    const [searchParams] = useSearchParams();
    const [listData, setListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [valueSort, setValueSort] = useState({
        order: searchParams.get("order") ?? "created_ts",
        orderType: +(searchParams.get("orderType") ?? -1),
        key: searchParams.get("orderKey") ?? "date_desc",
    });
    const [dataCate, setDataCate] = useState([]);
    const [limit, setLimit] = useState(+(searchParams.get("limit") ?? "10"));
    const triggerReload = useAppSelector((store) => store.VTOState.isReload);
    const isReloadCate = useAppSelector((store) => store.VTOState.isReloadCate);
    const [listIDToRemove, setListIDToRemove] = useState<string[]>([]);
    const [keyActive, setKeyActive] = useState(
        searchParams.get("category") ?? "all"
    );
    const [page, setPage] = useState(+(searchParams.get("page") ?? "1"));
    const [totalRecord, setTotalRecord] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMultipleSelection = useAppSelector(
        (store) => store.VTOState.isMultipleSelection
    );
    const onChange = (key: string) => {
        setIsLoading(true);
        setKeyActive(key);
        setLimit(10);
        setPage(1);
    };
    const getApiListOutfits = async (
        page: number,
        limit: number,
        category: string,
        search: string,
        order: string,
        orderType: number
    ) => {
        setIsLoading(true);
        try {
            const data = await FashionService.getInstance().VTOGetOutFits(
                page,
                limit,
                category,
                search,
                order,
                orderType
            );
            if (data.success) {
                setTotalRecord(data?.data?.totalRecord || 0);
                setListData(data.data.outfitResult);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            setTotalRecord(0);
            setListData([]);
        }
    };

    useEffect(() => {
        dispatch(setStateVTO.setReload(false));
    }, [triggerReload]);

    useEffect(() => {
        getApiListOutfits(
            page,
            limit,
            keyActive,
            valueSearch,
            valueSort.order,
            valueSort.orderType
        );
    }, [
        triggerReload,
        keyActive,
        page,
        valueSearch,
        isReloadCate,
        limit,
        valueSort,
    ]);

    const getDataCate = async () => {
        const cate = await FashionService.getInstance().VTOGetCategory();
        if (cate && cate.success) {
            setDataCate(cate.data);
        }
    };
    let items: TabsProps["items"] = [
        {
            key: "all",
            label: t("all"),
            children: (
                <>
                    {!isLoading ? (
                        <ListModelByType
                            setTrainLoraShopHistory={setTrainLoraShopHistory}
                            setListIDToRemove={setListIDToRemove}
                            setLimit={setLimit}
                            limit={limit}
                            listIDToRemove={listIDToRemove}
                            isClickEdit={isClickEdit}
                            setPage={setPage}
                            page={page}
                            totalRecord={totalRecord}
                            setDataEdit={setDataEdit}
                            listData={listData}
                        />
                    ) : (
                        <Spin spinning></Spin>
                    )}
                </>
            ),
        },
    ];
    dataCate.map((item: any) => {
        if (items)
            return items.push({
                key: item._id,
                label: item.category_name,
                children: (
                    <>
                        {!isLoading ? (
                            <ListModelByType
                                setTrainLoraShopHistory={setTrainLoraShopHistory}
                                setListIDToRemove={setListIDToRemove}
                                setLimit={setLimit}
                                limit={limit}
                                listIDToRemove={listIDToRemove}
                                isClickEdit={isClickEdit}
                                setPage={setPage}
                                page={page}
                                totalRecord={totalRecord}
                                setDataEdit={setDataEdit}
                                listData={listData}
                            />
                        ) : (
                            <Spin spinning></Spin>
                        )}
                    </>
                ),
            });
    });
    const onChangeSelectMultiple = (e: any) => {
        dispatch(setStateVTO.setIsMultipleSelection(e.target.checked));
    };

    const removeSelected = () => {
        swal({
            title: t("be_careful"),
            text: t("want_to_delete"),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: t("cancel"),
                    value: null,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: t("delete"),
                    value: true,
                    visible: true,
                    closeModal: true,
                },
            },
        }).then(async (result) => {
            if (result) {
                const dataDelete = await FashionService.getInstance().VTODeleteOutfit(
                    listIDToRemove
                );

                if (dataDelete.success) {
                    NotifyController.success(t("delete_successfully"));
                    dispatch(setStateVTO.setReload(true));
                    setListIDToRemove([]);
                    dispatch(setStateVTO.setIsMultipleSelection(false));
                    setTrainLoraShopHistory({});
                }
                //call api remove
            }
        });
    };
    const onChangeTypeSort = (_: any, object: any) => {
        let initialValue = {
            order: "created_ts",
            orderType: -1,
            key: "date_desc",
        };
        switch (object.key) {
            case "date_desc":
                initialValue = {
                    order: "created_ts",
                    orderType: -1,
                    key: "date_desc",
                };
                break;
            case "date_asc":
                initialValue = {
                    order: "created_ts",
                    orderType: 1,
                    key: "date_asc",
                };
                break;
            case "count_desc":
                initialValue = {
                    order: "count",
                    orderType: -1,
                    key: "count_desc",
                };
                break;
            case "count_asc":
                initialValue = {
                    order: "count",
                    orderType: 1,
                    key: "count_asc",
                };
                break;

            default:
                initialValue = {
                    order: "created_ts",
                    orderType: -1,
                    key: "date_desc",
                };
                break;
        }
        setValueSort(initialValue);
    };
    const data = [
        {
            key: "date_desc",
            value: t("date_desc"),
        },
        {
            key: "date_asc",
            value: t("date_asc"),
        },
        {
            key: "count_desc",
            value: t("count_desc"),
        },
        {
            key: "count_asc",
            value: t("count_asc"),
        },
    ];
    const Operations = ({ className }: any) => (
        <div className={`flex flex-wrap gap-2 justify-center items-center ${className} sm:!justify-between sm:w-full`}>
            <Select
                onChange={onChangeTypeSort}
                className={`w-[178px] ${isMultipleSelection && 'sm:w-full'}`}
                value={data.filter((item) => item.key === valueSort.key)}
                placeholder={t("sort_by")}
                options={data}
            />
            {isMultipleSelection && (
                <div
                    onClick={removeSelected}
                    className="flex items-center gap-2 justify-center hover:cursor-pointer hover:text-[red] group"
                >
                    <DeleteOutlined className="text-[20px] group-hover:text-[red] text-[black]" />
                    {t("delete_selected")} {" "}
                </div>
            )}
            <Checkbox
                checked={isMultipleSelection}
                onChange={(e) => onChangeSelectMultiple(e)}
            >
                {t("select_more")}
            </Checkbox>
        </div>
    );
    useEffect(() => {
        //handle user using button back in browser
        const currentPage = +(searchParams.get("page") || 1);
        const limitItem = +(searchParams.get("limit") || "10");
        const orderQuery = {
            order: searchParams.get("order") ?? "created_ts",
            orderType: +(searchParams.get("orderType") ?? -1),
            key: searchParams.get("orderKey") ?? "date_desc"
        }
        const category = (searchParams.get("category") || "all");
        if (page !== currentPage || limit !== limitItem || keyActive !== category || valueSort.order !== orderQuery.order || valueSort.orderType !== orderQuery.orderType) {
            setPage(currentPage)
            setLimit(limitItem)
            setKeyActive(category)
            setValueSort(orderQuery)
        }

    }, [searchParams])
    useEffect(() => {
        navigate(
            `?category=${keyActive}&page=${page}&limit=${limit}&order=${valueSort.order}&orderType=${valueSort.orderType}&orderKey=${valueSort.key}&searchValue=${valueSearch}`
        );
    }, [page, limit, keyActive, valueSort, valueSearch]);

    useEffect(() => {
        getDataCate();
    }, [isReloadCate]);

    return (
        <div className="flex gap-2 justify-between items-center sm:flex-col sm:overflow-y-auto">
            <Operations className={"hidden sm:!flex sm:gap-2 "} />
            <Tabs
                items={items}
                onChange={onChange}
                activeKey={keyActive}
                defaultActiveKey="all"
                className="w-full h-[100vh]"
                tabBarExtraContent={<Operations className={"sm:hidden"} />}
            />
        </div>
    );
}

export default ListModelVTO;
