import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IInputSetting } from '../VTOUploadModel'
import { Input, InputNumber, InputNumberProps } from 'antd'
import { useTranslation } from 'react-i18next'
import FashionService from '../../../services/fashion.service'
type InputLimitGenProps = {
    setInputSetting: Function,
    inputSetting: IInputSetting,
    isEdit: Boolean,
    value?: number | undefined | null
}
function InputLimitGen({ setInputSetting, inputSetting, value, isEdit }: InputLimitGenProps) {
    const [limitNumber, setLimitNumber] = useState<number | undefined | null>(value)
    const { t } = useTranslation()
    const onChangeLimit: any = (value: number) => {
        setLimitNumber(value)
    }
    useEffect(() => {
        value && setLimitNumber(value)
    }, [value])
    useEffect(() => {
        !isEdit && getDailyGen()
    }, [isEdit])

    useEffect(() => {

        if (limitNumber) {
            const _ = {
                ...inputSetting,
                generationLimitPerDay: limitNumber
            }
            setInputSetting(_)
        } else {
            const _ = {
                ...inputSetting,
                generationLimitPerDay: null
            }
            setInputSetting(_)
        }
    }, [limitNumber])
    const getDailyGen = async () => {
        const { data } = await FashionService.getInstance().getVTOSetting('generation_limit');
        setLimitNumber(data.generationLimitPerProduct)
    }

    return (
        <div>
            <div className='title-input-id text-[#101828] font-semibold text-sm flex flex-row gap-1'>
                <div className='text-[#101828]'>{t('limit_gen')}</div>
            </div>
            <InputNumber controls={false} suffix={t('/day')} className='h-10 mt-2 text-center flex justify-center items-center' style={{ width: '100%' }} id="limit_gen" placeholder={t('enter_your_number')} min={1} value={limitNumber} onChange={onChangeLimit} />
        </div>
    )
}

export default InputLimitGen