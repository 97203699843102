import { ColorPicker, CollapseProps, InputNumber, Select, Slider, Button, Input, Collapse, Tooltip } from 'antd'
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { fabric } from 'fabric';
import { base64ToFile, convertUrlToBase64, getImageSize, scaleImg, urlToBase64 } from '../../utils/function'
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'

import { CaretRightOutlined } from '@ant-design/icons';
import iconUpload from '../../assets/icons/upload-cloud.png';
import logo from '../../assets/logo/logo-modeli.png';
import FashionService from '../../services/fashion.service'
import NotifyController from '../../utils/toast'
import { UploadFileToS3 } from '../../utils/uploadS3';
const URL_LOGO = 'https://media2.vision2art.ai/logo-modeli.png'
const MAX_SIZE = 1000000;
// const logo ='https://media2.vision2art.ai/logo-modeli.png'
type ManagementWaterMarkProps = {
    isOpen: boolean,
    setIsOpen: Function,
    url: string,
}

function ModalManagementWaterMark({ isOpen, url }: ManagementWaterMarkProps) {
    const { t } = useTranslation()
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const watermarkRef = useRef(null);
    // const canvasRef2 = useRef<HTMLCanvasElement | null>(null);
    const [fontSize, setFontSize] = useState(12)
    const [opacity, setOpacity] = useState(100)
    const [textColor, setTextColor] = useState('white')
    const [fontStyleInput, setFontStyleInput] = useState("normal")
    const [fontWeightInput, setFontWeightInput] = useState(400)
    const [underline, setUnderline] = useState(false)
    const [fontFamilySelected, setFontFamilySelected] = useState('arial')
    const [bgText, setBGText] = useState('transparent')
    const [inputText, setInputText] = useState('Created by Modeli.ai')
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const [loadWatermark, setLoadWatermark] = useState(false)
    const [blobImage, setBlobImage] = useState()
    const [canvasSizeData, setCanvasSizeData] = useState<
        | {
            scaleX: number;
            scaleY: number;
            widthCanvas: number;
            heightCanvas: number;
        }
        | undefined
    >();
    const [disableButton, setDisableButton] = useState(false);

    const imageToBase64 = (url: string, callback: any) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    };

    const inputLogo = async (e: any) => {
        e.preventDefault();

        if (e.target.files[0].size <= MAX_SIZE) {
            onChangeImg(URL.createObjectURL(e.target.files[0]))
            setBlobImage(e.target.files[0])
        } else {
            NotifyController.warning(t('error_max_size'))
        }
    }
    const onChangeImg = (url: string) => {
        if (fabricRef.current) {
            let addedImage = false;
            fabricRef.current.getObjects().forEach(function (o: any) {
                if (o.type === 'image') {
                    addedImage = true;
                    o.setSrc(url, () => {
                        fabricRef.current && fabricRef.current.renderAll()
                    })
                }
            })
            if (!addedImage) addImage(url)
        }
    }

    const addImage = async (urlUpload: string) => {
        imageToBase64(urlUpload, (base64: string) => {
            fabric.Image.fromURL(base64, (img: any) => {
                // Set properties for the new image
                img.scaleToWidth(80);
                img.scaleToHeight(80);
                img.set({
                    selectable: true,
                    opacity: 1,
                    id: 'img_upload'
                });

                // Get the current fabric canvas
                const canvas = fabricRef.current;
                if (canvas) {
                    canvas.add(img);
                    canvas.renderAll();
                }
            }, { scaleX: 1, scaleY: 1 });
        });
    };

    const addTextDefault = async () => {
        const initalText = new fabric.IText(inputText, {
            fontFamily: fontFamilySelected,
            fill: textColor,
            fontSize: fontSize,
            padding: 0,
            backgroundColor: bgText,
            opacity: opacity / 100,
            fontStyle: fontStyleInput as any,
            fontWeight: fontWeightInput,
            underline: underline,
            selectable: true,
            editable: false,
            hasBorders: true,
            hasControls: true
        }) as any;
        if (fabricRef.current) {
            fabricRef.current.getObjects().forEach(function (o: any) {
                if (o.type === 'i-text') {
                    initalText.set('top', o.top);
                    initalText.set('left', o.left);
                    fabricRef?.current?.remove(o); // Remove the old object
                }
            });
            fabricRef.current.add(initalText); // Add the new object
            fabricRef.current.setActiveObject(initalText); // Set the new object as active
            fabricRef.current.renderAll(); // Render the canvas
        }
    }


    const limitArea = () => {
        fabricRef.current && fabricRef.current.on('object:moving', (e) => {
            var obj = e.target as any;
            if (obj) {
                let maxT: number = obj.canvas.height - 10;
                let maxL: number = obj.canvas.width - 10;
                obj.set({
                    top: clamp(obj.top, 0, maxT),
                    left: clamp(obj.left, 0, maxL),
                })
            }
            obj && obj.setCoords();
        });
    }

    const clamp = (num: number, min: number, max: number) => {
        const v = Math.min(Math.max(num, min), max);
        return v
    };

    const changeWatermark = async () => {
        const canvas = fabricRef.current
        if (canvas) {
            let addedText = false;
            canvas.getObjects().forEach(function (o: any) {
                if (o.type === 'i-text') {
                    addedText = true;
                    o.set({
                        fontFamily: fontFamilySelected,
                        fill: textColor,
                        fontSize: fontSize,
                        padding: 0,
                        backgroundColor: bgText,
                        opacity: opacity / 100,
                        fontStyle: fontStyleInput as any,
                        fontWeight: fontWeightInput,
                        underline: underline,
                        selectable: true,
                        editable: false,
                        hasBorders: true,
                        hasControls: true,
                        text: inputText,
                    })
                    canvas && canvas.renderAll(); // Render the canvas
                }
            });
            if (!addedText) addTextDefault()
        }
    }


    const inputFontSize = (e: any) => {
        setFontSize(e)
        changeWatermark()
    }

    const inputColor = (e: any) => {
        setTextColor(e.toHexString())
        changeWatermark()
    }

    const inputBgColor = (e: any) => {
        setBGText(e.toHexString())
        changeWatermark()
    }

    const inputOpcity = (e: any) => {
        setOpacity(e)
    }

    const deleteObject = () => {
        const canvas = fabricRef.current;
        canvas && canvas.getActiveObjects().forEach((object: any) => {
            if (object.type === 'i-text' || object.type === 'image') {
                canvas && canvas.remove(object);
            }
        });
        canvas && canvas.discardActiveObject().renderAll();
    };

    const onChangeText = (e: any) => {
        setInputText(e.target.value);
    }

    const handleChange = (value: string) => {
        setFontFamilySelected(value)
    };

    const onChangeWeight = (e: CheckboxChangeEvent) => {
        setFontWeightInput(e.target.checked == true ? 700 : 400);
    };

    const onChangeStyle = (e: CheckboxChangeEvent) => {
        setFontStyleInput(e.target.checked == true ? 'italic' : 'normal');
    };

    const onChangeUnderline = (e: CheckboxChangeEvent) => {
        setUnderline(e.target.checked);
    };

    const getItems: () => CollapseProps['items'] = () => [
        {
            key: 'advanced_waterMask',
            label: t('advanced'),
            children:
                <div className='flex flex-col  gap-3'>
                    <div className='flex gap-2 items-center'><b>{t('color_text')}</b>
                        <ColorPicker defaultValue={'black'} value={textColor} onChangeComplete={e => inputColor(e)} showText />
                    </div>
                    <div className='flex gap-2 items-center'><b>{t('bg_text')}</b>
                        <ColorPicker defaultValue={'black'} value={bgText} onChangeComplete={e => inputBgColor(e)} showText />
                    </div>
                    <div className='flex gap-2 items-center'> <b>{t('font_size')}</b>
                        <InputNumber value={fontSize} defaultValue={29} pattern="[0-9]*"
                            inputMode='numeric' onChange={e => inputFontSize(e)} />
                    </div>
                    <div className='flex flex-col gap-1'>
                        <div className='flex'>
                            <Checkbox onChange={onChangeWeight} value={'bold'}>{t('Bold')}</Checkbox>
                            <Checkbox onChange={onChangeStyle} value={'italic'}>{t('Italic')}</Checkbox>
                        </div>
                        <div className='flex'>
                            <Checkbox onChange={onChangeUnderline} value={'underline'}>{t('Underline')}</Checkbox>
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'> <b>{t('font_family')}</b>
                        <Select
                            defaultValue="Time News Roman"
                            style={{ maxWidth: 100 }}
                            onChange={handleChange}
                            options={[
                                { value: 'Time News Roman', label: 'Time News Roman' },
                                { value: 'Arial', label: 'Arial' },
                                { value: 'Monospace', label: 'Monospace' },
                            ]}
                        />
                    </div>
                    <div> <b>{t('opacity')}</b>
                        <Slider className='w-[80%]' min={0} max={100} defaultValue={opacity} onChange={e => inputOpcity(e)} />
                    </div>
                </div>,
            style: {
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '142.857%'
            } as CSSProperties
        },
    ];

    const onClickUploadImage = () => {
        const inputUpload = document.getElementById('input-logo') as HTMLInputElement;
        inputUpload.click();
    };

    const initialSetStateBG = async () => {
        if (url && !canvasSizeData) {
            const imageSize = await getImageSize(url)
            const w = imageSize.width
            const h = imageSize.height
            const imgScale = scaleImg(w && w, h && h);
            if (imgScale) {
                setCanvasSizeData({
                    widthCanvas: imgScale.w,
                    heightCanvas: imgScale.h,
                    scaleX: imgScale.sX,
                    scaleY: imgScale.sY,
                });
            }
        }
    }

    const onSave = async () => {
        try {
            let urlLogo = URL_LOGO
            let upload = false;
            if (blobImage) {
                urlLogo = await UploadFileToS3(blobImage, 'logo_watermark.png', 'png')
                upload = true;
            }
            const canvas = fabricRef.current;
            if (canvas) {
                setDisableButton(true);
                const originJson = JSON.stringify(canvas?.toJSON());
                let jsonString: string = JSON.stringify(canvas?.toJSON());
                let json: any = canvas?.toJSON()

                if (json && typeof json.backgroundImage === 'object' && json.backgroundImage !== null && url) {
                    json.backgroundImage.src = url + '?not-from-cache-please';
                }
                if (json && typeof json.objects === 'object' && json.objects !== null && urlLogo && upload) {
                    json.objects.forEach((obj: any) => {
                        if (obj.type === 'image' && obj.src) {
                            obj.src = urlLogo + '?not-from-cache-please';
                        }
                    });
                }
                jsonString = JSON.stringify(json)
                const watermarkObjects = canvas.getObjects().filter(obj => {
                    const objWithId = obj as unknown as { id: string, type: string };
                    return objWithId.type === 'i-text' || objWithId.type === 'image';
                });
                // Ensure that there are watermark objects before proceeding
                let dataPayload: any = {
                    watermark: {
                        watermarkUrl: null,
                        watermarkLeft: null,
                        watermarkTop: null,
                        watermarkOriginConfig: jsonString
                    },
                };
                if (watermarkObjects.length > 0) {
                    // Calculate the bounding box for the watermark objects
                    const boundingRect = new fabric.Group(watermarkObjects).getBoundingRect();
                    // Convert the group to a data URL
                    const multiplier = 2
                    const groupBase64 = new fabric.Group(watermarkObjects).toDataURL({ format: 'png', multiplier });

                    if (groupBase64) {
                        const file2 = base64ToFile(groupBase64, 'watermark');
                        if (file2) {
                            const urlSaved = await UploadFileToS3(file2, 'watermark.png', 'png')
                            if (urlSaved) {
                                dataPayload = {
                                    watermark: {
                                        watermarkUrl: urlSaved,
                                        watermarkLeft: boundingRect.left * multiplier,
                                        watermarkTop: boundingRect.top * multiplier,
                                        watermarkOriginConfig: jsonString
                                    },
                                }
                            }
                        }
                    }

                    // Reload the canvas from JSON
                    canvas?.loadFromJSON(originJson, () => {
                        // Re-render the canvas
                        canvas?.renderAll();
                    });
                }
                const data = await FashionService.getInstance().ConfigSettingVTO(dataPayload);
                if (data.success) {
                    NotifyController.success(t('edit_out_fit_successfully'));
                    setLoadWatermark(!loadWatermark);
                } else {
                    NotifyController.error(t('something_wrong'));
                }
                setDisableButton(false);
            } else {
                console.log('Fabric canvas reference is not available.');
            }
        } catch (error) {
            console.log('error', error)
            setDisableButton(false);
        }
    }

    const getWatermarkSetting = async () => {
        const { data } = await FashionService.getInstance().getVTOSetting('watermark');
        if (data.watermarkOriginConfig) {
            fabricRef.current?.loadFromJSON(data.watermarkOriginConfig, () => {
                fabricRef.current?.getObjects().forEach(async (obj: any) => {
                    if (obj.type === 'i-text') {
                        setOpacity(obj.opacity * 100);
                        setInputText(obj.text);
                        setFontFamilySelected(obj.fontFamily);
                        setFontSize(obj.fontSize);
                        setFontWeightInput(obj.fontWeight);
                        setFontStyleInput(obj.fontStyle);
                        setBGText(obj.backgroundColor);
                        setTextColor(obj.fill);
                        setUnderline(obj.underline);
                    }
                });
                // Re-render the canvas
                fabricRef.current?.renderAll();
            });
        }
    }

    const UploadLogoComponent = (
        <div onClick={onClickUploadImage} className='flex flex-col gap-2 items-center justify-center bg-[#F3F6FF] border border-solid border-[#0047FF] p-8 rounded-lg hover:cursor-pointer'>
            <input className='hidden' type="file" accept="image/*" onChange={e => inputLogo(e)} name="input-log" id="input-logo" />
            <img src={iconUpload} alt="" />
            <p>{t('upload_your_logo')}</p>
            <p className='text-[12px] text-gray'>{t('max_size_upload')}: 1MB</p>
            <Button disabled={false} type='primary'>
                {t('upload')}
            </Button>
        </div>
    )

    useEffect(() => {
        if (canvasSizeData && fabricRef) {
            fabricRef && fabricRef.current?.setHeight(canvasSizeData.heightCanvas)
            fabricRef && fabricRef.current?.setWidth(canvasSizeData.widthCanvas)
        }
    }, [canvasSizeData])

    const initFabric = useCallback(() => {
        if (canvasRef.current) {
            fabricRef.current = new fabric.Canvas(canvasRef.current);
        }
    }, [canvasRef, fabricRef, canvasSizeData]);

    useEffect(() => {
        if (!fabricRef.current) {
            initFabric();
            limitArea()
        }
    }, [])

    useEffect(() => {
        initialSetStateBG()
    }, [])

    useEffect(() => {
        changeWatermark()
    }, [opacity, inputText, fontFamilySelected, fontSize, fontWeightInput, fontStyleInput, bgText, textColor, underline])

    useEffect(() => {
        getWatermarkSetting();
    }, [])

    useEffect(() => {
        const watermark: any = watermarkRef.current;

        if (watermark) {
            const handleKeyDown = (event: any) => {
                if (event.key === 'Delete') {
                    deleteObject();
                }
            };

            // Ensure the watermark div can receive focus
            watermark.setAttribute('tabindex', '0');
            watermark.focus();

            // Add event listener to the watermark element
            watermark.addEventListener('keyup', handleKeyDown);

            // Cleanup function to remove the event listener when the component unmounts
            return () => {
                watermark.removeEventListener('keyup', handleKeyDown);
            };
        }
    }, []);

    return (
        <div className='gap-2'>
            <div className='grid grid-cols-3 xl:grid-cols-2 gap-4 lg:grid-cols-1'>
                <div className='col-span-1 flex flex-col ml-10 text-left'>
                    <div className='sub-title text-base  font-semibold text-left'>{t('action')}</div>
                    {UploadLogoComponent}
                    <div className=' flex flex-col justify-space gap-3 mt-2'>
                        <div className='flex flex-col gap-2'>
                            <p className='text-[#667085]'>{t('your_branch')}:</p>
                            <Input value={inputText} onChange={e => onChangeText(e)} placeholder={t('placeholder_input_name')} />
                        </div>
                    </div>
                    <Collapse
                        className={'collapse-advanced-1'}
                        bordered={false}
                        ghost
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        // style={{ backgroundColor: 'transparent' }}
                        items={getItems()}
                    />
                    <div className='flex gap-2 justify-center items-center w-full mt-2'>
                        <div className=''>
                            <Button disabled={disableButton} type='primary' className='w-full' onClick={onSave} >{t('Save_Watermark')}</Button>
                        </div>
                    </div>
                </div>
                <div className='col-span-2 xl:col-span-1 ml-10 pre-processing-left relative bg-[#CCD9FF] min-h-3/4 flex justify-center items-center min-h-[75vh] '>
                    <div id='watermark-left' className='add-watermark-left' ref={watermarkRef}>
                        <canvas id="canvas" ref={canvasRef} ></canvas>
                    </div>
                </div>
            </div>
        </div>
        // </Modal>
    )
}

export default ModalManagementWaterMark