import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { userSaga } from '../../redux/app/authSaga';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import AccountService from '../../services/account.service';
import FashionService from '../../services/fashion.service';
import { FeatureTypeEnum } from '../../utils/enum';

const Billing = () => {
  const userInfo = useAppSelector(store => store.user)
  const timezone = moment.tz.guess();
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [openCancelSubModalData, setOpenCancelSubModalData] = useState<{ show: boolean, data: any }>({
    show: false,
    data: null
  })
  const [dataOrders, setDataOrders] = useState([])
  const [totalOrders, setTotalOrders] = useState(1)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 1
  });
  const [textOK, setTextOK] = useState(<>{t('confirm')}</>)

  const listOrderColumns: ColumnsType<any> = [
    {
      title: t('namePackage'),
      dataIndex: 'name',
    },
    {
      title: t('Feature_type'),
      dataIndex: 'feature_type',
      render: (_record) => {
        switch (_record) {
          case FeatureTypeEnum.VIRTUAL_TRY_ON:
            return 'VTO'
          case FeatureTypeEnum.MODEL_AI:
            return 'Model AI'
          default:
            return '';
        }
      }
    },
    {
      title: t('gateWay'),
      dataIndex: 'gateway',
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      render: (_, record) => {
        return (
          <>
            <span>{record.amount}</span>
            <span>{" "}{record.currency}</span>
          </>
        )
      }
    },
    {
      title: t('coins'),
      dataIndex: 'coins',
    },
    {
      title: t('status'),
      dataIndex: 'status',
    },
    {
      title: t('createAt'),
      dataIndex: 'createAt',
      render: (_record) => {
        return (
          <div>{moment.tz(_record, timezone).format("YYYY-MM-DD HH:mm:ss")}</div>
        )
      }
    },
  ];

  const onCancel = () => {
    setOpenCancelSubModalData({
      show: false, data: null
    })
  }

  const confirmCancelSub = async () => {
    if (!openCancelSubModalData.data) return;
    try {
      setTextOK(<LoadingOutlined />)
      await FashionService.getInstance().cancelSub(openCancelSubModalData.data);
      getOrdersData(pagination.pageSize, pagination.current);
      onCancel()
      swal(t('cancel_successfully'), '', 'success').then((res) => {
        AccountService.getInstance().getUserInfo().then((result) => {
          dispatch(userSaga.setUser(result));
        });
      })

    } catch (error) {
      swal(t('something_wrong'), '', 'error')
    }
  }

  const getOrdersData = async (pageSize: number, current: number) => {
    const data = await FashionService.getInstance().getOrders(pageSize, current);
    setDataOrders(data?.orders)
    setTotalOrders(data?.totalOrders)
  }

  const handleTableChange = (
    pagination: any) => {
    setPagination(pagination);
    getOrdersData(pagination.pageSize, pagination.current);
  };

  useEffect(() => {
    getOrdersData(pagination.pageSize, pagination.current);
  }, [userInfo])

  useEffect(() => {
    setPagination({ ...pagination, total: totalOrders });
  }, [totalOrders])

  return (
    <div className='text-left flex flex-col gap-2 py-4'>
      {
        userInfo.subscription && Object.keys(userInfo.subscription).length > 0 && (
          <>
            <div className=' text-left font-semibold text-lg'>
              {t('currentSubscription')}
            </div>
            {userInfo.subscription.name && (
              <div className='flex w-fit justify-start'>
                <div className='w-[148px]'>{t('namePackage')}:</div>
                <div className='ml-4'><b>{userInfo.subscription.name?.toUpperCase()}</b></div>
              </div>
            )}
            {userInfo.subscription.gateway && (
              <div className='flex w-fit justify-start'>
                <div className='w-[148px]'>{t('gateWay')}:</div>
                <div className='ml-4'><b>{userInfo.subscription.gateway?.toUpperCase()}</b></div>
              </div>
            )}
            {
              userInfo.subscription.expires && (
                <div className='flex w-fit justify-start'>
                  <div className='w-[148px]'>{t('expiresAt')}:</div>
                  <div className='ml-4'>{dayjs(userInfo.subscription.expires).format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>
              )
            }
          </>
        )
      }

      {
        userInfo.subscriptionCancelable?.stripe && userInfo.subscriptionCancelable.stripe?.orderId && (
          <div className='mr-2 mt-2 mb-4'>
            <Button danger onClick={() => setOpenCancelSubModalData({
              show: true,
              data: userInfo.subscriptionCancelable.stripe?.orderId
            })}>{t('cancel_sub')}
            </Button>
          </div>
        )}

      <Modal open={openCancelSubModalData.show} title={t('warning')} onOk={confirmCancelSub} onCancel={onCancel} cancelText={t('cancel')} okText={textOK}>{t("confirm_cancel")}</Modal>
      <div className='overflow-x-auto mt-4'>
        <div className=' text-left font-semibold text-lg'>
          {t('list_txs_history')}
        </div>
        <Table columns={listOrderColumns} dataSource={dataOrders} className='mt-2' pagination={pagination} onChange={handleTableChange} />
      </div>
    </div>
  )
}
export default Billing;