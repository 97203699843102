import { CheckCircleFilled, DeleteOutlined, LoadingOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Modal, Spin, Switch, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import tip from '../../assets/icons/idea.svg';
import crop from '../../assets/vto/icons/cropOrigin.svg';
import upload from '../../assets/vto/icons/uploadIcon2.svg';
import { setStateVTO } from '../../redux/app/appVTO';
import { userSaga } from '../../redux/app/authSaga';
import { setLoadingApp } from '../../redux/app/loadingApp';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import AccountService from '../../services/account.service';
import FashionService from '../../services/fashion.service';
import { base64ToBlob, isUrl } from '../../utils/function';
import NotifyController from '../../utils/toast';
import { UploadFileToS3 } from '../../utils/uploadS3';
import NewModalRequiredSub from '../ModalRequiredSub/NewModalRequiredSub';
import CropFromOriginal from './CropFromOriginal';
import GuideTrainLora from './GuideTrainLora';
import { ModalSub } from '../../redux/app/appModalSub';
import { cost } from '../../redux/app/appCost';
import { stat } from 'fs';
type ModalOptionsProps = {
    isOpenModalOptionInput: boolean,
    setIsOpenModalOptionInput: Function
}
type ButtonTrainProps = {
    status: string
}
type ImageTrainLoraProps = {
    listOriginalImage: any,
    indexActive: number,
    setListOriginalImages: Function,
    setCloseShow?: Function,
    inputSetting: any,
    outfitId?: string,
    isEdit: boolean,
    dataEdit?: any,
    setTrainLoraShopHistory: Function,
    isEnable?: boolean
}
export enum EStatusTrain {
    NO_START = 'no_start',
    QUEUE = 'in_queue',
    TRAINING = 'training',
    FINISHED = 'finished',
    RETRAIN = "retrain",
    FAILED = "failed",
    OPEN = 'open',
    CANCELLED = "cancelled"
}
function ImageTrainLora({ listOriginalImage, indexActive, setListOriginalImages, inputSetting, outfitId, setCloseShow, isEdit, dataEdit, setTrainLoraShopHistory, isEnable }: ImageTrainLoraProps) {
    const [isEnableLora, setEnableLora] = useState(isEnable)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const currentTime = Date.now();
    const currentCoins = useAppSelector(store => store.user.coins)
    const trainLoraCost = useAppSelector(store => store.costFeature.trainLora)
    const [status, setStatus] = useState(EStatusTrain.NO_START)
    const [filesSize, setFilesSize] = useState({
        min: 4,
        max: 10
    })
    const [isOpenModalOptionInput, setIsOpenModalOptionInput] = useState(false)
    const [sizeListUpload, setSizeListUpload] = useState(10)
    const [jobId, setJobId] = useState(null)
    const [isOpenModalCrop, setIsOpenModalCrop] = useState(false)
    const [isOpenModalGuide, setIsOpenModalGuide] = useState(false)
    const [listImages, setListImages] = useState<string[]>([])
    const [isLimitUpload, setLimitUpload] = useState(false)
    const onUpload = () => {
        setIsOpenModalOptionInput(true)
    }
    const onChangeFile = (e: any) => {
        const listFiles = e.target.files;
        if (listFiles.length > sizeListUpload) {
            NotifyController.warning(t('max_file_size_upload', { max: filesSize.max }));
            return
        }
        for (let i = 0; i < listFiles.length; i++) {
            const file = listFiles[i];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result as string;
                    setListImages((prevList: string[]) => [...prevList, base64String]);
                    if (status === EStatusTrain.FINISHED) {
                        setStatus(EStatusTrain.RETRAIN)
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };
    const clickUpload = () => {
        const inputUpload = document.getElementById('upload-train')
        inputUpload?.click()
        setTimeout(() => {
            setIsOpenModalOptionInput(false)
        }, 200)
    }
    const onCrop = () => {
        setIsOpenModalCrop(true)
        setTimeout(() => {
            setIsOpenModalOptionInput(false)
        }, 200)
    }
    const getDataTrain = async (outfitId: string) => {
        try {
            const dataTrain = await FashionService.getInstance().getImageTrainingByOutfitId(outfitId, i18n.language);
            if (dataTrain) {
                setListImages(dataTrain.data.images)
                setTrainLoraShopHistory(dataTrain.data)
                if (dataTrain.data.status === EStatusTrain.OPEN || dataTrain.data.status === EStatusTrain.FAILED) {
                    return;
                }
                if (dataTrain.data.status === EStatusTrain.CANCELLED) {
                    //Keep listening for the job to run, to avoid cases where the user changes outfits quickly, under 2000ms
                    setJobId(dataTrain.data._id)
                    setStatus(EStatusTrain.NO_START)
                    return
                }
                if (dataTrain.data.status !== EStatusTrain.FINISHED) {
                    setJobId(dataTrain.data._id)
                    setStatus(EStatusTrain.TRAINING)
                } else {
                    setJobId(null)
                    // setListImages(dataTrain.data.images)
                    setStatus(EStatusTrain.FINISHED)
                }
            } else {
                setJobId(null)
                setListImages([])
                setStatus(EStatusTrain.NO_START)
                setTrainLoraShopHistory({});
            }

        } catch (error) {
            setJobId(null)
            setListImages([])
            setStatus(EStatusTrain.NO_START)
            setTrainLoraShopHistory({});
        }
    }
    const getMinMaxImage = async () => {
        const dataMinMax = await FashionService.getInstance().getConfigGuide();
        const min = dataMinMax.data.find((item: any) => item.config_key === "vto.lora.required_number_of_images").config_value;
        const max = dataMinMax.data.find((item: any) => item.config_key === "vto.lora.images.limit").config_value;
        const costTrain = dataMinMax.data.find((item: any) => item.config_key === "app.vto.credit.fee.train_lora_shop_user").config_value;
        dispatch(cost.setLoraCost({ trainLora: costTrain }))
        setSizeListUpload(max)
        setFilesSize({
            min,
            max
        })
    }

    useEffect(() => {
        getMinMaxImage()
    }, [])

    useEffect(() => {
        if (outfitId) {
            setJobId(null) //set null when change outfit
            getDataTrain(outfitId)
        }
        setStatus(EStatusTrain.NO_START)
    }, [outfitId])
    useEffect(() => {
        //only apply for edit outfit
        if (outfitId)
            dataEdit.imageAndSetting[indexActive].applyTrainedLora = isEnableLora
    }, [isEnableLora])
    useEffect(() => {
        setListImages([])
        setEnableLora(isEnable)
    }, [indexActive])

    useEffect(() => {
        setLimitUpload(listImages.length >= filesSize.max)
        setSizeListUpload(filesSize.max - listImages.length)
        if (setTrainLoraShopHistory) {
            setTrainLoraShopHistory((preV: any) => ({
                ...preV,
                images: listImages
            }))
        }
    }, [listImages])
    const ButtonUpload = () => {
        return (
            <div onClick={onUpload} className='border border-dashed border-[#C9CED6] w-[120px] h-[120px] bg-[#F5F5F5] rounded-md flex flex-col gap-2 text-[#667085] justify-center items-center hover:cursor-pointer'>
                <PlusCircleFilled className='text-[30px] text-[#667085]' />
                {t('add')}
            </div>
        )
    }
    const ModalOptionInput = ({ isOpenModalOptionInput, setIsOpenModalOptionInput }: ModalOptionsProps) => {
        return (
            <Modal open={isOpenModalOptionInput} onCancel={() => setIsOpenModalOptionInput(false)} title={t('add_image')} width={'fit-content'} centered footer={<></>}>
                <div className='flex flex-row flex-wrap justify-center items-center gap-4'>
                    <div onClick={clickUpload} className='flex flex-col justify-center items-center border border-primary border-solid w-[250px] h-[130px] rounded-lg hover:cursor-pointer'>
                        <img src={upload} alt="" />
                        {t('upload_your_image')}
                    </div>
                    <div onClick={onCrop} className='flex flex-col justify-center items-center border border-primary border-solid w-[250px] h-[130px] rounded-lg hover:cursor-pointer'>
                        <img src={crop} alt="" />
                        {t('crop_form_original')}
                    </div>
                </div>
            </Modal>

        )
    }
    const onFinalEdit = async () => {
        if (!dataEdit) return
        const payload = {
            outfitCode: dataEdit.outfitCode,
            imageAndSetting: dataEdit.imageAndSetting,
            prompt: dataEdit.prompt,
            productLink: dataEdit.productLink,
            category: dataEdit.category,
            weight: dataEdit.weight,
            lang: i18n.language,
            generationLimitPerDay: dataEdit.generationLimitPerDay
        }
        try {
            const dataReturn = await FashionService.getInstance().VTOEditOutfit(payload)
            if (dataReturn && dataReturn.success) {
                const outfitIdEdited = dataReturn?.data?.imageAndSetting[indexActive]?._id;
                const listURLs = []
                for (let i = 0; i < listImages.length; i++) {
                    if (isUrl(listImages[i])) {
                        listURLs.push(listImages[i])
                    } else {
                        const blobFile = base64ToBlob(listImages[i], "image/jpg");
                        const url = await UploadFileToS3(blobFile, currentTime + `_${i}.jpg`, 'jpg')
                        listURLs.push(url)
                    }
                }
                if (outfitIdEdited && listURLs.length > 0) {
                    dispatch(setStateVTO.setReload(true))
                    const stringParams = {
                        prompt: listOriginalImage[indexActive].prompt,
                        weight: listOriginalImage[indexActive].weight,
                    }
                    const dataLora = await FashionService.getInstance().postTrainLora(outfitIdEdited, listURLs,
                        JSON.stringify(stringParams), i18n.language);
                    if (dataLora.success) {
                        setTrainLoraShopHistory({})
                        setJobId(dataLora.data.id)
                        const dataUser = await AccountService.getInstance().getUserInfo();
                        dispatch(userSaga.setUser(dataUser));
                    }
                    setCloseShow && setCloseShow(false)

                } else {
                    //check case upload image fail and not have outfitIdEdited
                    NotifyController.error(t('something_wrong'))
                    setStatus(EStatusTrain.NO_START)
                    dispatch(setLoadingApp.setLoading(false))
                }
            }
        } catch (error) {
            dispatch(setStateVTO.setReload(true))
            dispatch(setLoadingApp.setLoading(false))
            setStatus(EStatusTrain.NO_START)
        }
    }
    const onFinalUpload = async () => {
        if (currentCoins < trainLoraCost) {
            dispatch(ModalSub.setIsOpenModalSub(true))
            NotifyController.warning(t('Not_enough_credit'))
            return
        }

        const payload = {
            lang: i18n.language,
            outfitCode: inputSetting.IdOutfit,
            category: inputSetting.category,
            prompt: inputSetting.prompt,
            weight: inputSetting.weight,
            imageAndSetting: listOriginalImage,
            generationLimitPerDay: inputSetting.generationLimitPerDay
        }
        dispatch(setLoadingApp.setLoading(true))

        if (listImages.length >= filesSize.min) {
            let _outfitId = outfitId;
            setStatus(EStatusTrain.QUEUE)
            if (!isEdit) {
                try {
                    const dataReturn = await FashionService.getInstance().VTOSaveOutfit(payload)
                    const idsArray = dataReturn.data.map((object: any) => object._id);
                    _outfitId = idsArray[indexActive]
                    NotifyController.success(t('upload_out_fit_successfully'))

                } catch (error) {
                    dispatch(setLoadingApp.setLoading(false))
                    setStatus(EStatusTrain.NO_START)
                    return
                }

            } else {
                onFinalEdit()
                return
            }
            //function upload image to s3
            const listURLs = []
            for (let i = 0; i < listImages.length; i++) {
                if (isUrl(listImages[i])) {
                    listURLs.push(listImages[i])
                } else {
                    const blobFile = base64ToBlob(listImages[i], "image/jpg");
                    const url = await UploadFileToS3(blobFile, currentTime + `_${i}.jpg`, 'jpg')
                    listURLs.push(url)
                }
            }
            //end function upload image to s3

            if (_outfitId && listURLs.length > 0) {
                dispatch(setStateVTO.setReload(true))
                const stringParams = {
                    prompt: listOriginalImage[indexActive].prompt,
                    weight: listOriginalImage[indexActive].weight,
                }
                const dataLora = await FashionService.getInstance().postTrainLora(_outfitId, listURLs,
                    JSON.stringify(stringParams), i18n.language);
                if (dataLora.success) {
                    setJobId(dataLora.data.id)
                    setCloseShow && setCloseShow(false)
                    const dataUser = await AccountService.getInstance().getUserInfo();
                    dispatch(userSaga.setUser(dataUser));
                }
                dispatch(setLoadingApp.setLoading(false))

            } else {
                //check case upload image fail and not have outfitId
                NotifyController.error(t('something_wrong'))
                setStatus(EStatusTrain.NO_START)
                dispatch(setLoadingApp.setLoading(false))
            }
        }
        else {
            NotifyController.error(t('list_image_not_enough'))
            dispatch(setLoadingApp.setLoading(false))
        }
    }
    const ButtonTrain = ({ status }: ButtonTrainProps) => {
        switch (status) {
            case EStatusTrain.NO_START:
                return (
                    <Button onClick={onFinalUpload} size="large" type="primary" prefix=''>
                        {t('AI_Training')} {`(-${trainLoraCost} Credits)`}
                    </Button>
                )
            case EStatusTrain.QUEUE:
                return (
                    <Button size="large" type="primary" loading icon={<LoadingOutlined />} prefix=''>
                        {t('train_queue')}
                    </Button>
                )
            case EStatusTrain.TRAINING:
                return (
                    <Button size="large" type="primary" loading icon={<LoadingOutlined />} prefix=''>
                        {t('progress_train')}
                    </Button>
                )
            case EStatusTrain.FINISHED:
                return (
                    <Button size="large" className='border-[#00CA2D] text-[#00CA2D] bg-[#9effb3a1] hover:!text-[#00CA2D] hover:!border-[#00CA2D]' type="default" icon={<CheckCircleFilled />} prefix=''>
                        {t('train_complete')}
                    </Button>
                )
            case EStatusTrain.RETRAIN:
                return (
                    <Button size="large" onClick={onFinalUpload} className='border-[#00CA2D] text-[#00CA2D] bg-[#9effb3a1] hover:!text-[#00CA2D] hover:!border-[#00CA2D]' type="default" icon={<CheckCircleFilled />} prefix=''>
                        {t('RE_AI_Training')} {`(-${trainLoraCost} Credits)`}
                    </Button>
                )

            default:
                return (
                    <Button type="primary" disabled icon={<LoadingOutlined />} size="large" prefix=''>
                        {t('progress_train')}
                    </Button>
                )
        }


    }
    const toRemoveImage = (url: string) => {
        const newA = listImages.filter((item: any) => item !== url);
        setListImages(newA)
        if (status === EStatusTrain.FINISHED) {
            setStatus(EStatusTrain.RETRAIN)
        }
    }
    const onCancelTrain = () => {
        swal({
            title: t('be_careful'),
            text: t('want_to_cancel'),
            dangerMode: true,
            icon: 'warning',
            buttons: {
                cancel: {
                    text: t('cancel'),
                    value: null,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: t('confirm'),
                    value: true,
                    visible: true,
                    closeModal: true,
                },
            },
        }).then(async (result) => {
            if (result && jobId) {
                const data = await FashionService.getInstance().cancelTrainLora(jobId, i18n.language);
                if (data?.success) {

                    swal(t('cancel_train_successfully'), '', 'success');
                    const dataUser = await AccountService.getInstance().getUserInfo();
                    dispatch(userSaga.setUser(dataUser));
                }
                else {
                    swal(t('something_wrong'), '', 'error');
                }
            }
        });
    }
    let interval: any;
    const onChangeSwitchEnable = (e: boolean) => {
        setEnableLora(e)
    }
    useEffect(() => {
        if (jobId) {
            dispatch(setLoadingApp.setLoading(false))
            interval = setInterval(async () => {
                const listeningImage = await FashionService.getInstance().listenTrain(jobId, i18n.language);
                if (listeningImage?.success) {
                    if (listeningImage.data.status === EStatusTrain.FAILED) {
                        const dataUser = await AccountService.getInstance().getUserInfo();
                        dispatch(userSaga.setUser(dataUser));
                        setStatus(EStatusTrain.NO_START)
                        setJobId(null)
                        swal(t('something_wrong'), '', 'error');
                        if (interval) {
                            dispatch(setLoadingApp.setLoading(false))
                            setCloseShow && setCloseShow(false)
                            clearInterval(interval);
                        }
                    }
                    if (listeningImage.data.status === EStatusTrain.QUEUE) {
                        setStatus(EStatusTrain.QUEUE)
                        // dispatch(setLoadingApp.setLoading(!outfitId))
                    }
                    if (listeningImage.data.status === EStatusTrain.TRAINING) {
                        setStatus(EStatusTrain.TRAINING)
                        // dispatch(setLoadingApp.setLoading(!outfitId))
                    }
                    if (listeningImage.data.status === EStatusTrain.CANCELLED) {
                        clearInterval(interval);
                        setStatus(EStatusTrain.NO_START)
                        setJobId(null)
                        if (interval) {
                            dispatch(setLoadingApp.setLoading(false))
                            clearInterval(interval);
                            return
                        }
                    }
                    if (listeningImage.data.status === EStatusTrain.FINISHED) {
                        setStatus(EStatusTrain.FINISHED)
                        swal(t('train_successfully'), '', 'success');
                        if (interval) {
                            dispatch(setLoadingApp.setLoading(false))
                            setCloseShow && setCloseShow(false)
                            clearInterval(interval);
                        }
                    }
                } else {
                    NotifyController.error(t('something_wrong'))
                    if (interval) {
                        clearInterval(interval);
                        setJobId(null)
                    }
                }
            }, 2000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [jobId])

    return (
        <div>
            <input type='file' accept='image/*' className='hidden' id="upload-train" onChange={onChangeFile} multiple />
            <div className='flex justify-between items-center font-semibold text-[14px]'>
                <div className='flex flex-row gap-1'>
                    <div>
                        {t('add_train_lora_image')}
                    </div>
                </div>
                <div className='flex gap-1 justify-center items-center'>
                    {(status === EStatusTrain.FINISHED || status === EStatusTrain.RETRAIN) && <Switch onChange={e => onChangeSwitchEnable(e)} checked={isEnableLora} checkedChildren={t('enable')} unCheckedChildren={t('disable')}></Switch>}
                    <Tooltip title={t('guide')}>
                        <div onClick={() => { setIsOpenModalGuide(true) }} className='bg-[#FEFCE8] flex flex-row gap-2  font-normal justify-center items-center rounded-md h-[24px] px-3 py-1 hover:cursor-pointer'>
                            <div>
                                <img src={tip} alt="" />
                            </div>
                            {/* <div>{t('guide')}</div> */}
                        </div>
                    </Tooltip>
                </div>
            </div>
            {isOpenModalGuide && <GuideTrainLora isOpen={isOpenModalGuide} setIsOpen={setIsOpenModalGuide} />}
            {isOpenModalCrop && <CropFromOriginal setListImagesLora={setListImages} openModal={isOpenModalCrop} setOpenModal={setIsOpenModalCrop} setListImages={setListOriginalImages} status={status} setStatus={setStatus} listImages={listOriginalImage} indexActive={indexActive} imageUrl={listOriginalImage[indexActive].modelImage} />}
            {isOpenModalOptionInput && <ModalOptionInput isOpenModalOptionInput={isOpenModalOptionInput} setIsOpenModalOptionInput={setIsOpenModalOptionInput} />}
            <Spin spinning={status === EStatusTrain.TRAINING || status === EStatusTrain.QUEUE}>
                <div className='mt-2 flex flex-row gap-2 flex-wrap'>
                    {!isLimitUpload && <ButtonUpload />}
                    {[...listImages].reverse().map((image: any, index: number) => (
                        image && <div key={index} className='w-[120px] h-[120px] rounded-md'
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize: 'cover',
                                objectFit: 'contain',
                                position: 'relative',
                                backgroundPosition: 'center'
                            }}
                        >
                            <div onClick={() => toRemoveImage(image)} className='absolute top-2 right-2 hover:cursor-pointer flex justify-center items-center w-8 h-8 bg-white rounded-full group hover:bg-[red] '>
                                <DeleteOutlined className='group-hover:text-[white] text-[16px]' />
                            </div>
                        </div>
                    ))}

                </div>
            </Spin>
            <div className='flex gap-3 justify-center items-center mt-4'>
                <ButtonTrain status={status} />
                {(status === EStatusTrain.QUEUE || status === EStatusTrain.TRAINING || status === EStatusTrain.FAILED) && <Button onClick={onCancelTrain} size="large" danger prefix=''>
                    {t('cancel')}
                </Button>}
            </div>
        </div>
    )
}

export default ImageTrainLora