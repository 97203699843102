import { useEffect, useState } from 'react';
import BtnSlider from './BtnSlider';
import './styles.css';
import CropImageComponent from '../CropComponent';
import { Button, Image, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import CropImage from './CropImage';
import FashionService from '../../services/fashion.service';
import { resizeImageToMaxSize768x1024 } from '../../utils/function';

type SliderUploadModelProps = {
    listImages: any,
    setListImages: Function,
    setIndexActive: Function,
    indexActive: number,
    cropperRef: any,
    cropperStates: any,
    setCropperStates: Function,
    saveCropperState: Function,
    setEditInputSetting?: Function,
    isEdit: boolean,
    dataEdit?: any,
    setTrainLoraShopHistory: Function
}

function SliderUploadModel({
    listImages,
    setListImages,
    indexActive,
    setIndexActive,
    cropperRef,
    cropperStates,
    setCropperStates,
    saveCropperState,
    isEdit,
    dataEdit,
    setEditInputSetting,
    setTrainLoraShopHistory
}: SliderUploadModelProps) {
    const { t } = useTranslation()
    const [openModelCrop, setOpenModalCrop] = useState(false)
    const [loading, setLoading] = useState(false)
    const [arrayImages, setArrayImages] = useState(listImages);

    const onClickUploadImage = () => {
        const inputUpload = document.getElementById('replace-input-upload') as HTMLInputElement;
        inputUpload.click();
    };

    const handleImageUpload = (indexActive: number, e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64 = reader.result;
                const updatedImages = [...arrayImages];
                const imageAfterReSize1024 = await resizeImageToMaxSize768x1024(base64 as string)
                updatedImages[indexActive].modelImage = imageAfterReSize1024;
                setArrayImages(updatedImages);
                setListImages(updatedImages)
            };
            reader.readAsDataURL(file);
        }
    }
    const onRemoveBg = async () => {
        setLoading(true)
        const { data } = await FashionService.getInstance().vtoRemoveBg(arrayImages[indexActive].modelImage);
        const updatedImages = [...arrayImages];
        updatedImages[indexActive].modelImage = data.image;
        setArrayImages(updatedImages);
        setListImages(updatedImages)
        setLoading(false)
        if (isEdit && setEditInputSetting && dataEdit) {
            const cloneEditInputSetting = { ...dataEdit }
            cloneEditInputSetting.imageAndSetting[indexActive].modelImage = data.image;
            setEditInputSetting(cloneEditInputSetting)
        }
    }
    const onCropImage = () => {
        setOpenModalCrop(true)
    }

    useEffect(() => {
        if (listImages.length === 1) {
            setIndexActive(0);
        }
        setArrayImages(listImages);
        // Initialize cropper states if not already done
        if (cropperStates.length === 0 && listImages.length > 0) {
            setCropperStates(new Array(listImages.length).fill({}));
        }
    }, [listImages, setIndexActive]);
    useEffect(() => {
        setTrainLoraShopHistory({})
    }, [indexActive])
    // Trigger restoration of cropper state once CropImageComponent is rendered
    // useEffect(() => {
    //     setTimeout(() => restoreCropperState(indexActive), isEdit ? 500 : 100);
    // }, [arrayImages[indexActive]?.modelImage]);

    return (
        <div className='relative max-h-auto pb-2'>
            {openModelCrop && <CropImage arrayImages={arrayImages} setArrayImages={setArrayImages} setListImages={setListImages} indexActive={indexActive} openModal={openModelCrop} setOpenModal={setOpenModalCrop} imageUrl={arrayImages[indexActive].modelImage} />}
            <div className='font-semibold text-[18px]'> {t('image_number')} {indexActive + 1}</div>
            <input id="replace-input-upload" type="file" accept="image/png,image/jpeg,image/jpg,image/webp" onChange={(e) => handleImageUpload(indexActive, e)}
                className="hidden"
            />

            {arrayImages.map((obj: any, index: number) => {
                return (
                    <div key={index}
                        className={indexActive === index ? "slide active-anim m-auto mt-2" : "slide m-auto mt-2"}
                    >
                        <Spin spinning={loading} className='w-fit'  >
                            <div className="hover:cursor-pointer border border-solid border-black rounded-sm" style={{
                                position: 'relative',
                                borderRadius: '8px',
                                backgroundImage: `url('${obj.modelImage}')`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center top',
                                backgroundRepeat: 'no-repeat',
                                objectFit: 'fill',
                                width: '350px',
                                height: '450px',
                                minHeight: 'fit-content',
                            }}>
                                <div className='absolute left-0 bottom-0  w-auto p-2 flex gap-2 justify-center items-center'>
                                    <div className=' w-auto p-2 flex gap-2 justify-center items-center'>
                                        <Button onClick={e => onCropImage()}>{t('crop_image')}</Button>
                                    </div>
                                    <div className='  w-auto p-2 flex gap-2 justify-center items-center'>
                                        <Button onClick={e => onRemoveBg()}>{t('remove_bg')}</Button>
                                    </div>
                                    <div className='  w-auto p-2 flex gap-2 justify-center items-center'>
                                        <Button onClick={e => onClickUploadImage()}>{t('replace_image')}</Button>
                                    </div>
                                </div>
                            </div>
                        </Spin>

                    </div>
                )
            })}

            {/* {arrayImages.length > 0 && (
                <CropImageComponent
                    imageUpload={arrayImages[indexActive].modelImage}
                    cropperRef={cropperRef}
                />
            )} */}

            {/* <div>
                <div className='absolute top-1/2 -right-12 rounded-full'>
                    <BtnSlider moveSlide={nextSlide} direction={"prev"} />
                </div>
                <div className='absolute top-1/2 -left-12 rounded-full'>
                    <BtnSlider moveSlide={prevSlide} direction={"next"} />
                </div>
            </div> */}
        </div >
    );
}

export default SliderUploadModel;
