import {
  Button, Checkbox, Collapse, CollapseProps, ColorPicker, Input, InputNumber, Modal, Select,
  Slider, Tooltip
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { fabric } from 'fabric';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CaretRightOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import iconUpload from '../../assets/icons/upload-cloud.png';
import logo from '../../assets/images/logo-modeli.svg';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { getImageSize, resizeCanvasFitScreen, scaleImg } from '../../utils/function';
import ModalRequiredSub from '../ModalRequiredSub/ModalRequiredSub';
import Heading from '../../pages/Editor/DetailsTools/Heading';
import { useDispatch } from 'react-redux';
import { editorImage } from '../../redux/app/appImagePreprocessing';
import { toolEditorImage } from '../../redux/app/toolEditor';
import { ModalSub } from '../../redux/app/appModalSub';

type ModalAddWaterMarkProps = {
  url: string;
  onClickSave: () => void;
  onClickBackToHome: () => void;

}
const ModalAddWaterMark: React.FC<ModalAddWaterMarkProps> = ({ url, onClickSave, onClickBackToHome }) => {
  const { t } = useTranslation()

  const userInfo = useAppSelector((store) => store.user);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const expired = userInfo?.subscription?.expires
  const currentTime = new Date();
  const expiredTime = new Date(expired && expired || currentTime);
  const isExpired = expired ? (currentTime <= expiredTime) : true;

  const [fontSize, setFontSize] = useState(12)
  const [opacity, setOpacity] = useState(100)
  const [modalSub, setModalSub] = useState(false)
  const [multiplier, setMultiplier] = useState<any>(1)
  const [textColor, setTextColor] = useState('white')
  const [imgLogoCanvas, setImgLogoCanvas] = useState<any>()
  const [fontStyleInput, setFontStyleInput] = useState("normal")
  const [fontWeightInput, setFontWeightInput] = useState(400)
  const [underline, setUnderline] = useState(false)
  const [ready, setReady] = useState(false);
  const [fontFamilySelected, setFontFamilySelected] = useState('arial')
  const [bgText, setBGText] = useState('transparent')
  const [inputText, setInputText] = useState('Created by Modeli.ai')
  const fabricRef = useRef<fabric.Canvas | null>(null);
  const [ratio, setRatio] = useState<number>(1)
  const [canvasSizeData, setCanvasSizeData] = useState<
    | {
      scaleX: number;
      scaleY: number;
      widthCanvas: number;
      heightCanvas: number;
    }
    | undefined
  >();

  const setBg = async (canvasSizeData: any) => {
    await initialSetStateBG()
    fabric.Image.fromURL(url, function (img: any) {
      fabricRef.current && fabricRef.current.setBackgroundImage(img, fabricRef.current.renderAll.bind(fabricRef.current), {
        scaleX: canvasSizeData.scaleX,
        scaleY: canvasSizeData.scaleY,
      });
    },
      { crossOrigin: '*' }
    );
    ratio > 0 && fabricRef && fabricRef.current?.setHeight(canvasSizeData.widthCanvas / ratio)
    fabricRef && fabricRef.current?.setWidth(canvasSizeData.widthCanvas)
  };

  function urlToBlob(url: string, callback: any) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (xhr.status === 200) {
        callback(xhr.response);
      }
    };
    xhr.open('GET', url);
    xhr.send();
  }
  const dispatch = useDispatch()
  const download = () => {
    const img = fabricRef.current && fabricRef.current.toDataURL(
      {
        format: 'png',
        multiplier: multiplier
      }
    ) as any;

    dispatch(editorImage.setURLImageEditor(img));
    dispatch(toolEditorImage.setImageInput(img))
    // const link = document.createElement('a');
    // link.href = img;
    // link.download = 'modeli-image.png';
    // link.click();
  }
  const openModalSub = () => {
    dispatch(ModalSub.setIsOpenModalSub(true))
  }
  const inputLogo = (e: any) => {
    if (!isExpired) {
      openModalSub()
    }
    else {
      e.preventDefault();
      onChangeImg(URL.createObjectURL(e.target.files[0]))
    }
  }

  const onChangeImg = (url: string) => {
    imgLogoCanvas.setSrc(url, function () {
      fabricRef.current && fabricRef.current.moveTo(imgLogoCanvas, 3)
      if (fabricRef.current) {
        fabricRef.current.getObjects().forEach(function (o: any) {
          if (o.id === 'img_upload') {
            const active = fabricRef.current && fabricRef.current.setActiveObject(o);
            imgLogoCanvas.scaleToWidth(50);
            imgLogoCanvas.scaleToHeight(50);
            o.set({
              opacity: 1
            })
            active && active.add(imgLogoCanvas);
          }
        })
      }
    }, { crossOrigin: 'annonymous', scaleX: 1, scaleY: 1 })
  }

  const addImage = async (urlUpload: string) => {
    fabric.Image.fromURL(
      urlUpload,
      (img: any) => {
        setImgLogoCanvas(img)
        img.scaleToWidth(30);
        img.scaleToHeight(30);
        fabricRef.current && fabricRef.current.add(img.set({
          top: 10,
          left: 10,
          selectable: isExpired,
          opacity: isExpired ? 1 : 0,
          id: 'img_upload'
        }));
        img.on("mousedown", () => {
          if (!isExpired) {
            openModalSub()
            fabricRef.current && fabricRef.current.renderAll();
          }
        });
      },
      { scaleX: 1, scaleY: 1 }
    );
  }

  const defaultWatermark = async () => {
    const imageSize = await getImageSize(url)
    const imgScale = scaleImg(imageSize.width && imageSize.width, imageSize.height && imageSize.height);
    const bottom = imgScale && imgScale?.h - 30
    const initalText = new fabric.IText('Created by Modeli.ai', {
      fontFamily: "arial",
      fill: 'white',
      fontSize: 12,
      padding: 0,
      top: bottom,
      left: 30,
      backgroundColor: 'transparent',
      opacity: isExpired ? 1 : 0,
      selectable: isExpired,
      editable: false
    }) as any
    initalText.set('id', 'water_mask')
    initalText.set('strokeWidth', 0)
    fabricRef.current && fabricRef.current.add(initalText)
    fabricRef.current && fabricRef.current.moveTo(initalText, 1)
    initalText.on("mousedown", () => {
      if (!isExpired) {
        openModalSub()
        fabricRef.current && fabricRef.current.renderAll();
      }
    });
    setReady(false)
  }

  const limitArea = () => {
    fabricRef.current && fabricRef.current.on('object:moving', (e) => {
      var obj = e.target as any;
      if (obj) {
        let maxT: number = obj.canvas.height - 10;
        let maxL: number = obj.canvas.width - 10;
        obj.set({
          top: clamp(obj.top, 0, maxT),
          left: clamp(obj.left, 0, maxL),
        })
      }
      obj && obj.setCoords();
    });
  }
  limitArea()

  const clamp = (num: number, min: number, max: number) => {
    const v = Math.min(Math.max(num, min), max);
    return v
  };

  const changeWaterMask = async () => {
    const initalText = new fabric.IText(inputText, {
      fontFamily: fontFamilySelected,
      fill: textColor,
      fontSize: fontSize,
      padding: 0,
      backgroundColor: bgText,
      opacity: opacity / 100,
      fontStyle: fontStyleInput as any,
      fontWeight: fontWeightInput,
      underline: underline,
      selectable: isExpired, // is sub set true to remove watermark
      editable: false
    }) as any
    initalText.set('id', 'water_mask')
    initalText.hasBorders = initalText.hasControls = false;
    if (fabricRef.current) {
      fabricRef.current.getObjects().forEach(function (o: any) {
        if (o.id === 'water_mask') {
          initalText.set('top', o.top)
          initalText.set('left', o.left)
          const active = fabricRef.current && fabricRef.current.setActiveObject(o);
          active && active.insertAt(initalText, 0, true);
        }
      })
    }
  }

  const inputFontSize = (e: any) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setFontSize(e)
      changeWaterMask()
    }
  }

  const inputColor = (e: any) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setTextColor(e.toHexString())
      changeWaterMask()
    }
  }

  const inputBgColor = (e: any) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setBGText(e.toHexString())
      changeWaterMask()
    }
  }

  const resetSetting = () => {
    setBGText('transparent')
    setFontSize(12);
    setFontStyleInput('normal');
    setFontWeightInput(400);
    setUnderline(false)
    setInputText('Created by Modeli.ai')
    setTextColor('white');
    setOpacity(100);
    fabricRef.current && fabricRef.current.renderAll();
  }

  const inputOpcity = (e: any) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setOpacity(e)
    }
  }

  const deleteObject = (e: any) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      fabricRef.current && fabricRef.current.getActiveObjects().forEach((object: any) => {
        if (object.id === 'img_upload') {
          object && object.set({
            opacity: 0
          })
          fabricRef.current && fabricRef.current.renderAll();
        } else if (object.id === 'water_mask') {
          setInputText('')
          fabricRef.current && fabricRef.current.renderAll();
        }
      });
    }
  };

  const onChangeText = (e: any) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setInputText(e.target.value);
    }
  }

  const handleChange = (value: string) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setFontFamilySelected(value)
    }
  };

  const onChangeWeight = (e: CheckboxChangeEvent) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setFontWeightInput(e.target.checked == true ? 700 : 400);
    }
  };

  const onChangeStyle = (e: CheckboxChangeEvent) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setFontStyleInput(e.target.checked == true ? 'italic' : 'normal');
    }
  };

  const onChangeUnderline = (e: CheckboxChangeEvent) => {
    if (!isExpired) {
      openModalSub()
      fabricRef.current && fabricRef.current.renderAll();
    } else {
      setUnderline(e.target.checked);
    }
  };

  const getItems: () => CollapseProps['items'] = () => [
    {
      key: 'advanced_waterMask',
      label: t('advanced'),
      children:
        <div className='flex flex-col  gap-3'>
          <div className='flex gap-2 items-center'><b>{t('color_text')}</b>
            <ColorPicker defaultValue={'black'} value={textColor} onChangeComplete={e => inputColor(e)} showText />
          </div>
          <div className='flex gap-2 items-center'><b>{t('bg_text')}</b>
            <ColorPicker defaultValue={'black'} value={bgText} onChangeComplete={e => inputBgColor(e)} showText />
          </div>
          <div className='flex gap-2 items-center'> <b>{t('font_size')}</b>
            <InputNumber value={fontSize} defaultValue={29} pattern="[0-9]*"
              inputMode='numeric' onChange={e => inputFontSize(e)} />
          </div>
          <div className='flex flex-col gap-1'>
            <div className='flex'>
              <Checkbox onChange={onChangeWeight} value={'bold'}>{t('Bold')}</Checkbox>
              <Checkbox onChange={onChangeStyle} value={'italic'}>{t('Italic')}</Checkbox>
            </div>
            <div className='flex'>
              <Checkbox onChange={onChangeUnderline} value={'underline'}>{t('Underline')}</Checkbox>
            </div>
          </div>
          <div className='flex gap-2 items-center'> <b>{t('font_family')}</b>
            <Select
              defaultValue="Time News Roman"
              style={{ maxWidth: 100 }}
              onChange={handleChange}
              options={[
                { value: 'Time News Roman', label: 'Time News Roman' },
                { value: 'Arial', label: 'Arial' },
                { value: 'Monospace', label: 'Monospace' },
              ]}
            />
          </div>
          <div> <b>{t('opacity')}</b>
            <Slider className='w-[80%]' min={0} max={100} defaultValue={50} onChange={e => inputOpcity(e)} />
          </div>
        </div>,
      style: {
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '142.857%'
      } as CSSProperties
    },
  ];

  const onClickUploadImage = () => {
    if (!isExpired) {
      openModalSub()
    } else {
      const inputUpload = document.getElementById('input-logo') as HTMLInputElement;
      inputUpload.click();
    }
  };

  const resizeHandler = useDebouncedCallback(async () => {
    await handleResize();
  }, 50);

  const initialSetStateBG = useCallback(async () => {
    if (url) {
      const imageSize = await getImageSize(url)
      const w = imageSize.width
      const h = imageSize.height
      w && h && setRatio(w / h)
      const imgScale = scaleImg(w && w, h && h);
      if (imgScale) {
        setCanvasSizeData({
          widthCanvas: imgScale.w,
          heightCanvas: imgScale.h,
          scaleX: imgScale.sX,
          scaleY: imgScale.sY,
        });
        setMultiplier(imgScale && imgScale.multiplier)
      }
    }
  }, [url])

  const handleResize = useCallback(
    async () => {
      if (url) {
        const imageSize = await getImageSize(url)
        const w = imageSize.width
        const h = imageSize.height
        const elementCanvas = document.getElementsByClassName('add-watermark-left');
        if (w && h) {
          const imgScale2 = resizeCanvasFitScreen(elementCanvas[0]?.clientWidth, elementCanvas[0]?.clientHeight, w, h)
          if (imgScale2) {
            setCanvasSizeData({
              widthCanvas: elementCanvas[0]?.clientWidth,
              heightCanvas: elementCanvas[0]?.clientHeight,
              scaleX: imgScale2.sX,
              scaleY: imgScale2.sY,
            });
            setMultiplier(imgScale2.multiplier && imgScale2.multiplier)
          }
        }
      }
    }, [url])

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
  }, [url])

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);

  const initFabric = useCallback(() => {
    if (canvasRef.current) {
      fabricRef.current = new fabric.Canvas(canvasRef.current);
    }
  }, [canvasRef, fabricRef, canvasSizeData]);

  useEffect(() => {
    if (!fabricRef.current) {
      initFabric();
    }
    if (canvasSizeData) {
      setBg(canvasSizeData);
    }
    if (ready) {
      defaultWatermark()
      urlToBlob(logo, function (blob: any) {
        const objectURL = URL.createObjectURL(blob);
        const img = new Image();
        img.src = objectURL;
        addImage(img.src)
      });
    }

  }, [canvasSizeData, ready])

  useEffect(() => {
    setReady(true)
  }, [])

  useEffect(() => {
    changeWaterMask()
  }, [opacity, inputText, fontFamilySelected, fontSize, fontWeightInput, fontStyleInput, bgText, textColor, underline])
  const UploadLogoComponent = (
    <div onClick={onClickUploadImage} className='flex flex-col gap-2 items-center justify-center bg-[#F3F6FF] border border-solid border-[#0047FF] p-8 rounded-lg hover:cursor-pointer'>
      <input className='hidden' type="file" accept="image/*" onChange={e => inputLogo(e)} name="input-log" id="input-logo" />
      <img src={iconUpload} alt="" />
      <p>{t('upload_your_logo')}</p>
      <Button disabled={false} type='primary'>
        {t('upload')}
      </Button>
    </div>
  )

  return (
    <div className='flex flex-col gap-2'>
      {/* <Modal open={visibleModal} centered={true} title={t('add_watermark')} width={'fit-content'}
        onCancel={e => setVisible(false)}
        footer={
          <div
            style={{
              justifyContent: 'flex-end'
            }}
            className='flex gap-3 '>
            <Button onClick={e => { setVisible(false); resetSetting() }}>{t('close')}</Button>
         
          </div>
        }
      > */}
      <Heading title={t('create_wtm')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
      <div className='modal-section min-w-[80vw] flex !flex-row gap-4 justify-between  items-start md:items-center lg:items-center  w-full sm:!flex-col md:!flex-col lg:!flex-col '>
        <div className='flex flex-col min-w-[400px]  ml-10  text-left'>
          <hr className="bg-[#F5F5F5] h-[1px] border-none mt-2" />
          <div className='sub-title text-base  font-semibold text-left'>{t('action')}</div>
          {UploadLogoComponent}
          {/* <div className='flex gap-2 items-center'><p>{t('upload_your_logo')}</p>
            <Button disabled={false} onClick={onClickUploadImage}>
              <UploadOutlined /> {t('upload')}
              <input className='hidden' type="file" accept="image/*" onChange={e => inputLogo(e)} name="input-log" id="input-logo" />
            </Button>
          </div> */}
          <div className=' flex flex-col justify-space gap-3 mt-2'>
            <div className='flex flex-col gap-2'>
              <p className='text-[#667085]'>{t('your_branch')}:</p>
              <Input value={inputText} onChange={e => onChangeText(e)} placeholder={t('placeholder_input_name')} />
            </div>
          </div>
          <Collapse
            className={'collapse-advanced-1'}
            bordered={false}
            ghost
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            // style={{ backgroundColor: 'transparent' }}
            items={getItems()}
          />

          <div className='flex gap-2 justify-center items-center w-full mt-2'>
            <div className='w-full'>
              <Tooltip placement="topLeft" title={t('select_object_and_delete')} >
                <Button danger className='w-full' onClick={deleteObject}>{t('delete')}</Button>
              </Tooltip>
            </div>
            <div className='w-full'>
              <Button type='primary' className='w-full' onClick={download} >{t('apply_and_continues')}</Button>
            </div>
          </div>
        </div>
        <div className='pre-processing-left  relative box-canvas-pre  bg-[#CCD9FF] !w-[65vw]  min-h-3/4 flex justify-center items-center min-h-[75vh] '>
          <div className='add-watermark-left '>
            <canvas id="canvas" ref={canvasRef} ></canvas>
          </div>
        </div>
      </div>

      {/* </Modal> */}
    </div>
  );
};

export default ModalAddWaterMark