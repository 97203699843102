import { Button, Col, Input, InputNumber, InputNumberProps, Row, Slider, Spin } from 'antd';
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import icon_upload from '../../assets/icons/upload-cloud.png'
import SliderUploadModel from './SliderUploadModel';
import { DeleteOutlined } from '@ant-design/icons';
import { convertBlobToBase64, convertHeicToJpg, resizeImageToMaxSize768x1024 } from '../../utils/function';
import NotifyController from '../../utils/toast';
import swal from 'sweetalert';
import InputIdOutfit from './VTO-input-setting/InputIdOutfit';
import InputCategory from './VTO-input-setting/InputCategory';
import TextArea from 'antd/es/input/TextArea';
import ReactImageGallery from 'react-image-gallery';
import tip from '../../assets/icons/idea.svg';
import AI from '../../assets/vto/icons/ai.svg';
import ImageTrainLora from './ImageTrainLora';
import InputLinkProducts from './VTO-input-setting/InputLinkProducts';
import InputLimitGen from './VTO-input-setting/InputLimitGen';
import FashionService from '../../services/fashion.service';

type VTOUploadModelProps = {
    setInputSetting: Function,
    inputSetting: IInputSetting,
    setSelectedImage: Function,
    isOnClickReset: boolean,
    setUploadedImages: Function,
    uploadedImages: any,
    isOpenCate: boolean,
    setIsOpenCate: Function,
    saveCropperState: Function,
    cropperStates: any,
    setCropperStates: Function,
    cropperRef: any,
    slideIndex: number,
    setSlideIndex: Function,
    removeCropperState: Function,
    onClose: Function,
    setTrainLoraShopHistory: Function,

}
export interface IInputSetting {
    IdOutfit: string,
    category: string,
    prompt: string,
    weight: number,
    productLink: string,
    generationLimitPerDay: number | null
}

const imagesEN = [
    {
        original: "https://media2.vision2art.ai/vto-en-1.png",
        thumbnail: "https://media2.vision2art.ai/vto-en-1.png",
    },
    {
        original: "https://media2.vision2art.ai/vto-en-2.png",
        thumbnail: "https://media2.vision2art.ai/vto-en-2.png",
    },
    {
        original: "https://media2.vision2art.ai/vto-en-3.png",
        thumbnail: "https://media2.vision2art.ai/vto-en-3.png",
    },
    {
        original: "https://media2.vision2art.ai/vto-en-4.png",
        thumbnail: "https://media2.vision2art.ai/vto-en-4.png",
    }
];

const images = [
    {
        original: "https://media2.vision2art.ai/vto-vi-1.png",
        thumbnail: "https://media2.vision2art.ai/vto-vi-1.png",
    },
    {
        original: "https://media2.vision2art.ai/vto-vi-2.png",
        thumbnail: "https://media2.vision2art.ai/vto-vi-2.png",
    },
    {
        original: "https://media2.vision2art.ai/vto-vi-3.png",
        thumbnail: "https://media2.vision2art.ai/vto-vi-3.png",
    },
    {
        original: "https://media2.vision2art.ai/vto-vi-4.png",
        thumbnail: "https://media2.vision2art.ai/vto-vi-4.png",
    },
];
function VTOUploadModel({
    isOpenCate,
    setIsOpenCate,
    inputSetting,
    setInputSetting,
    setSelectedImage,
    isOnClickReset,
    uploadedImages,
    setUploadedImages,
    saveCropperState,
    cropperStates,
    setCropperStates,
    cropperRef,
    slideIndex,
    setSlideIndex,
    removeCropperState,
    onClose,
    setTrainLoraShopHistory,

}: VTOUploadModelProps) {
    const { i18n } = useTranslation()
    const isVNes = i18n.language === 'vi';
    const [isLoadingText, setIsLoadingText] = useState(false)
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/png': ['.png', '.jpg', '.webp', '.jpeg', '.heic', '.heif'] },
        maxFiles: 5,
        onDrop: (acceptedFiles: any, fileRejections: any) => {

            if (fileRejections.length > 0) {
                swal(t('error_upload'), '', 'error')
            } else {
                acceptedFiles
                    .filter((file: any, index: number) => file !== '')
                    .map((file: any, index: number) => {

                        let isHEIC = file.type === 'image/heic' || file.type === 'image/heif'
                        const reader = new FileReader();
                        reader.onload = async (event: any) => {
                            let imageUrlFinal = event.target.result;
                            if (isHEIC) {
                                const response = await fetch(event.target.result);
                                const blob = await response.blob();
                                const coverted = await convertHeicToJpg(blob);
                                imageUrlFinal = await convertBlobToBase64(coverted.resultBlob) as string;
                            }
                            const imageMaxSize1024 = await resizeImageToMaxSize768x1024(imageUrlFinal)
                            setUploadedImages((prevFiles: any) => [...prevFiles, { modelImage: imageMaxSize1024, prompt: '', weight: 1 }]);
                        }
                        reader.readAsDataURL(file);
                    });
            }

        }
    });
    const { t } = useTranslation()

    const toRemoveImage = (url: string) => {
        const newA = uploadedImages.filter((item: any) => item.modelImage !== url);
        const index = uploadedImages.findIndex((item: any) => item.modelImage === url)
        setUploadedImages(newA)
        if (newA.length > 0) {
            setSelectedImage(newA[newA.length - 1].modelImage)
            setSlideIndex(newA.length - 1)
            removeCropperState(index)
        }
    }

    const handleImageUpload = async (e: any) => {
        try {
            e.preventDefault();
            let files = e.target?.files;
            if (!files || !files.length) {
                console.warn("No file selected")
                return
            }
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                let imageUrlFinal = event.target.result;
                let isHEIC = imageUrlFinal.startsWith('data:application/octet-stream;base64')
                if (isHEIC) {
                    const response = await fetch(event.target.result);
                    const blob = await response.blob();
                    const coverted = await convertHeicToJpg(blob);
                    imageUrlFinal = await convertBlobToBase64(coverted.resultBlob) as string;
                }
                if (imageUrlFinal) {
                    const imageMaxSize1024 = await resizeImageToMaxSize768x1024(imageUrlFinal)
                    setUploadedImages([...uploadedImages, { modelImage: imageMaxSize1024, prompt: '', weight: 1 }]);
                }
            }
            reader.readAsDataURL(files[0]);
            e.target.value = null
        } catch (error) {
            NotifyController.error('Something went wrong!')
        }


    }

    const onClickUploadImage = () => {
        const inputUpload = document.getElementById('input-upload-image-item') as HTMLInputElement;
        inputUpload.click();
    };

    const onChangePrompt = (e: any) => {
        const value = e.target.value;
        const cloneUploadedImages = [...uploadedImages]
        cloneUploadedImages[slideIndex].prompt = value
        setUploadedImages(cloneUploadedImages)
    }

    const onChange: InputNumberProps['onChange'] = (newValue) => {
        const cloneUploadedImages = [...uploadedImages]
        cloneUploadedImages[slideIndex].weight = newValue
        setUploadedImages(cloneUploadedImages)
    };
    const genCaptionByImage = async (index: number) => {
        setIsLoadingText(true)
        const img = uploadedImages?.[index]?.modelImage;
        const { data } = await FashionService.getInstance().postCaptionByImage(img)
        const value = data?.caption;
        if (value) {
            const cloneUploadedImages = [...uploadedImages]
            cloneUploadedImages[slideIndex].prompt = value
            setUploadedImages(cloneUploadedImages)
            setIsLoadingText(false)
        }
    }


    useEffect(() => {
        if (isOnClickReset) {
            setUploadedImages([])
        }
    }, [isOnClickReset])


    useEffect(() => {
        setSelectedImage(uploadedImages[0])
    }, [uploadedImages])

    return (
        <div className='overflow-hidden flex flex-col gap-4 mt-1 relative'>
            <div className='flex gap-2 w-full'>
                <InputIdOutfit setInputSetting={setInputSetting} inputSetting={inputSetting} />
                <InputCategory setInputSetting={setInputSetting} inputSetting={inputSetting} isOpenCate={isOpenCate} setIsOpenCate={setIsOpenCate} />
            </div>
            <div className='flex sm:flex-col  w-full gap-2'>
                <div className='w-[70%] sm:w-full'>
                    <InputLinkProducts setInputSetting={setInputSetting} inputSetting={inputSetting} />
                </div>
                <div className='w-[30%] sm:w-full'>
                    <InputLimitGen setInputSetting={setInputSetting} inputSetting={inputSetting} isEdit={false} />
                </div>
            </div>
            <div className='flex flex-col justify-around max-h-screen h-auto'>
                {!uploadedImages[0] && <div style={{
                    width: '250px',
                    height: '340px',
                    borderRadius: '8px',
                    margin: '0 auto'
                }} {...getRootProps({ className: 'dropzone border border-[#5379FF] bg-[#F3F6FF] border-solid flex justify-center items-center' })}>
                    <div className='flex flex-col gap-1'>
                        <div className='flex justify-center items-center'>
                            <img src={icon_upload} alt="icon upload" width={'50px'} height={'auto'} />
                        </div>
                        <Button type='primary'>{t('upload_your_outfit')}</Button>
                    </div>
                    <input {...getInputProps()} />
                </div>}
                {!uploadedImages[0] && <div>
                    <div className='text-left flex items-center  py-2'>
                        <img src={tip} alt="" />
                        <div className='text-[#101828] font-semibold text-[16px]'>
                            {t('vto_guide_input')}
                        </div>
                    </div>
                    <div className='max-w-[80%] w-auto m-0 m-auto mt-1 '>
                        <ReactImageGallery items={isVNes ? images : imagesEN} lazyLoad={true} autoPlay slideInterval={3000} showThumbnails={false} showIndex showNav showBullets showPlayButton />
                    </div>
                </div>}
            </div>
            <div className='flex gap-2 overflow-x-auto'>
                {(uploadedImages.length > 0 && uploadedImages.length < 5) && <div onClick={onClickUploadImage} className='button-upload hover:cursor-pointer min-w-[120px] text-center min-h-[120px] rounded-lg border border-solid border-[#5379FF] bg-[#F3F6FF] flex flex-col justify-center items-center'>
                    <img src={icon_upload} alt="icon upload" width={'40px'} height={'auto'} />
                    <input id="input-upload-image-item" type="file" accept="image/png,image/jpeg,image/jpg,image/webp, image/heif, image/heic" onChange={handleImageUpload} className="hidden" />
                    <div className='text-[#0047FF] font-medium text-sm'>
                        {t('upload')}
                    </div>
                </div>}
                {uploadedImages.map((item: any, index: number) => (
                    <div className='image gap-2 rounded-lg relative' >
                        <div
                            onClick={(e) => { setSlideIndex(index); setSelectedImage(item.modelImage); saveCropperState(slideIndex) }}
                            className={"hover:cursor-pointer"}
                            style={{
                                backgroundImage: `url(${item.modelImage})`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                objectFit: 'revert',
                                backgroundRepeat: 'no-repeat',
                                width: '120px',
                                height: '120px',
                                borderRadius: '8px',
                                border: index === slideIndex ? '3px #0047FF solid' : '3px #C9CED6 solid'
                            }}></div>
                        {/* <img src={item} alt="" width={120} height={120} /> */}
                        <div onClick={() => toRemoveImage(item.modelImage)} className='absolute top-2 right-2 hover:cursor-pointer flex justify-center items-center w-8 h-8 bg-white rounded-full group hover:bg-[red] '>
                            <DeleteOutlined className='group-hover:text-[white]' />
                        </div>
                    </div>
                ))}
            </div>
            {uploadedImages.length > 0 && <SliderUploadModel
                setTrainLoraShopHistory={setTrainLoraShopHistory}
                saveCropperState={saveCropperState}
                cropperStates={cropperStates}
                setCropperStates={setCropperStates}
                cropperRef={cropperRef}
                indexActive={slideIndex}
                setIndexActive={setSlideIndex}
                listImages={uploadedImages}
                setListImages={setUploadedImages}
                isEdit={false}
            />
            }
            {uploadedImages.length > 0 && <ImageTrainLora isEdit={false} setCloseShow={onClose} inputSetting={inputSetting} indexActive={slideIndex} listOriginalImage={uploadedImages} setListOriginalImages={setUploadedImages}
                setTrainLoraShopHistory={setTrainLoraShopHistory} />}
            {uploadedImages.length > 0 &&
                <>
                    <div>
                        <div>
                            <div className='flex pb-2 flex-row justify-between items-center w-full'>
                                <div className='text-[#101828] font-semibold leading-20'>{t('label_prompt')} ({t('image_number')}{slideIndex + 1}): </div>
                                <div onClick={() => { genCaptionByImage(slideIndex) }} className='flex flex-row gap-1 justify-center items-center border-primary border-solid border hover:cursor-pointer rounded-lg p-1 text-primary'>
                                    <div>
                                        {t('create_by_ai')}
                                    </div>
                                    <img src={AI} width={'20px'} alt="" />
                                </div>
                            </div>
                            <Spin spinning={isLoadingText}>
                                <TextArea onChange={onChangePrompt} value={uploadedImages?.[slideIndex]?.prompt} showCount maxLength={300} allowClear className='mt-2' />
                            </Spin>
                        </div>
                    </div>
                    <div>
                        <Row className='flex justify-between'>
                            <Col span={16} className='text-[#667085] font-medium text-sm'>
                                {t('creating')}
                            </Col>
                            <Col span={8} className='text-[#667085] font-medium text-sm'>
                                {t('by_input')}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={20}>
                                <Slider
                                    min={0.8}
                                    max={1.2}
                                    step={0.01}
                                    onChange={onChange}
                                    value={uploadedImages?.[slideIndex]?.weight}
                                />
                            </Col>
                            <Col span={4}>
                                <InputNumber
                                    value={uploadedImages?.[slideIndex]?.weight}
                                    min={0.8}
                                    max={1.2}
                                    step={0.01}
                                    style={{ margin: '0 16px' }}
                                    onChange={onChange}
                                />
                            </Col>
                        </Row>
                    </div>
                </>
            }
        </div >
    )
}

export default VTOUploadModel